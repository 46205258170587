$break-medium-height: 850px;


.title-cont-main{
  height:100%;
  width:100%;
.title-treemap{
  color:#d94426;
  text-align:center;
  font-size:24px;
}

}
.treemap-main-cont{
  display:flex;
  height:calc(100% - 30px);
  width:100%;
  font-family: 'wigrum-light';
  min-height:800px;


  .left-controller-container{
    margin-top:10px;
    flex:3;
    display:flex;
    flex-direction: column;
    height:95%;


    .top-filler-cont{
      flex:2;

    }
    .filters-controller-containers{
      flex:1
    }

    .donut-chart-container{
      width:90%;
      height:90%;
      mmin-width:400px;
      min-height: 400px;
      flex:4;

      @media screen and (max-height: $break-medium-height) {
         flex:5;
       }

      .main-donut-chart{
        text-align: center;
        margin-top:5%;
      }

    }
  }

  .treemap-cont{
    flex:7;
    text-align: right;
    padding-right: 1%;
    font-size: 10px;
    .treemap-element{
      width:100%;
      height:95%;
      background-color: rgb(69, 69, 69);
      box-shadow: -3px 3px 12px 0px #656262;
      margin-top: 10px;
    }
    .top-node-button{
      cursor: pointer;
      display:inline-block;
    }
    .back-node-arrow{
      display:inline-block;
      color:black;
      background-color: white;
      border-radius:2px;
      font-size: 10px;
      margin-top:1px;
      margin-left:25px;
      border: 1px solid white;
      padding:2px;
      cursor: pointer;
      &:active{
        background-color:#3636ff;
      }
    }
  }

.toggle-container{
  margin-top:10px;

  width:80%;
  margin-left: 5%;
  padding:0px 5px 5px 5px;

  .unkown-toggle-title{

  }

  .react-toggle{
    margin-left: 0px !important;
  }

  .deeper-toggle-cont{
    margin-top:15px;
    @media screen and (max-height: $break-medium-height) {
       margin-top:0px;

     }
  }

  .toggle-title{
    padding-bottom:5px;
  }
}

.treemap-slider-cont{
  margin-top:50px;
  width: 90%;
  margin-left:5%;
  padding:5px 5px 5px 5px;
  padding-bottom:15px;
  border-bottom: 1px solid white;

  @media screen and (max-height: $break-medium-height) {
     margin-top:0px;
     padding-top:0px;
   }

  .slider-title--cont{


    padding-top:10px;
    margin-bottom:5px;
  }
}

.unknown-toggle-cont{
  display: inline-block;
  width:45%;
 }
 .deeper-toggle-cont{
   display: inline-block;
   width:45%;
 }

 .treemap-display{
   margin-left:5%;
   padding:10px;
   text-align:center;
   margin-right:5%;
   text-transform: capitalize;

   @media screen and (max-height: $break-medium-height) {
      font-size:14px;

    }

   .display-level{
     padding:5px;
     border: 1px solid white;
     margin-top:5px;
     margin-bottom:5px;
     background-color: #292828;
   }

   .display-amount-cont{
     margin-top:25px;
     border:1px solid #292828;;
     padding: 10px;
     text-align:center;
     background-color: white;
     color:black;
     @media screen and (max-height: $break-medium-height) {
        margin-top:0px;
        padding: 5px;
      }
   }

 }

 .donut-chart-text{
   pointer-events:none;
 }

}

  .donut-tooltip{
    pointer-events:none;
    text-align:center;
    background-color: white;
    color:black;
    z-index: 10001;
    opacity:0.8;
    padding:10px;
    border-radius: 15px;
    .tool-tip-title{
      margin-bottom:10px;
    }
  }
.slider-container{

  input[type=range]::-moz-range-track {

  height: 3px;
  cursor: pointer;


  background: #aec7e8;


}
}
