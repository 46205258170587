.KPI-filter-deployments{

  .KPI-filter-deployments-title{

  }

  .KPI-filter-deployment-list-cont{
    display: flex;
    width:100%;
    .KPI-filter-depl-list{
      flex:1;
    }
  }
}
