@import "../../containers/variables";

#map-channel {
  .stage {
    // position: absolute;
    /* height: 100vh !important;
    width: 100vw !important; */
    /* overflow: hidden; */
  }

  .overlay {
    fill: none;
    pointer-events: all;
  }

  path {
    fill: $grey_22;
    /* fill: rgb(0,0,128); */
    stroke: $grey_44;
    /* stroke: rgb(0,0,50); */
    stroke-width: 0.5px;
  }

  /* .land-boundary {
    stroke-width: 1px;
  } */

  /* .county-boundary {
    stroke: #ddd;
  } */

  .location {
    /* stroke-width: .5px; */
    /* stroke: rgba(255,255,255,.3); */
    /* fill: none; */
    fill: rgba(255, 255, 255, 0.3);
  }

  .badOpt {
    stroke-width: 0.5px;
    /* stroke: #333; */
    fill: rgba(255, 100, 100, 0.2);
    stroke: rgba(255, 100, 100, 0.7);
    /* animation: pulse-me 3s linear infinite; */
  }

  .goodOpt {
    stroke-width: 0.5px;
    /* stroke: #333; */
    fill: rgba(100, 255, 100, 0.2);
    stroke: rgba(100, 255, 100, 0.7);
    /* animation: pulse-me 3s linear infinite; */
  }

  .onlineLocation {
    /* stroke-width: .5px; */
    /* stroke: rgba(255,255,255,.3); */
    /* fill: none; */
    fill: rgba(255, 255, 255, 0.1);
    stroke: rgba(255, 255, 255, 0.5);
  }

  .offlineLocation {
    /* stroke-width: .5px; */
    /* stroke: #333; */
    fill: rgb(255, 0, 1);
    stroke: rgba(255, 0, 0, 0.5);
  }

  .fastLoopCC {
    stroke-width: 0.5px;
    /* stroke: #333; */
    fill: rgba(100, 100, 255, 0.1);
    stroke: rgba(100, 100, 255, 0.5);
    /* animation: pulse-me 3s linear infinite; */
  }

  .scheduledCC {
    stroke-width: 0.5px;
    /* stroke: #333; */
    fill: rgba(100, 255, 100, 0.1);
    stroke: rgba(100, 255, 100, 0.5);
    /* animation: pulse-me 3s linear infinite; */
  }

  #slider3 {
    position: absolute;
    top: 28px;
    left: 50%;
    transform: translateX(-50%);
    /* margin: 0px auto 0px auto; */
    // width: 900px;
    background: transparent;
    border-radius: 10px;
    border: none;
    color: $white;
  }
  #slider3 svg {
    width: 900px;
  }

  @keyframes pulse-me {
    0% {
      transform: scale(0.5);
      opacity: 0;
    }
    50% {
      opacity: 0.1;
    }
    70% {
      opacity: 0.09;
    }
    100% {
      transform: scale(5);
      opacity: 0;
    }
  }

  #togglePlayButton {
    position: absolute;
    // display: none;
    top: 30px;
    left: -50px;
    padding: 0 20px;
    line-height: 20px;
    height: 20px;
    border-radius: 30px;
    background: $grey_99;
    box-sizing: border-box;
    border: none;
    color: $white;
    outline: none;
    z-index: 2;
    &:hover, &:active {
      cursor: pointer;
      background: $grey_cc;
    }
  }
  .pauseGap {
    display: inline-block;
    width: 1px;
  }

  #legend {
    position: absolute;
    top: 100%;
    left: 0px;
    padding: 20px;
    transform: translateY(-100%);
    color: $grey_66;
    font-size: 14px;
    line-height: 32px;
  }
  .legendRow svg {
    display: inline-block;
    height: 16px;
    width: 16px;
    position: relative;
    top: 3px;
  }
  #timestamp {
    color: $grey_99;
  }
}
