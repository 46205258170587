.KPI-filters-filters-button{
  margin:10px;
  height:25px;

  color:rgb(130,130,130);

  border: 1px solid rgb(110,110,110);
  border-radius: 2px;
  display:flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  &:hover{
    color:white;
    border: 1px solid white;
  }
}
