.tool-tip-attr{
  text-transform: capitalize;
}

  .info-chart-div{
    cursor: pointer;
    position: relative;
    left:120px;
    top: 15px;
    text-align: center;
    overflow: visible;
    color: white;
    font-size: 16px;
    font-weight: 400;
    vertical-align: middle;
    text-decoration: none;
    transition: all 0.5s;
    height: 20px;
    width: 20px;
    border-radius: 12px;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 1009;
    background-position: center center;
    background-size: contain;
    background-color: rgb(98, 105, 255);
    background-image: url("/img/icon_info.png");
  }

  .tooltip-info{
     background-color:rgb(98, 105, 255);
     z-index: 1010;
     padding: 1rem;
     opacity:0.8;
     font-size: 14px;
     text-align: left;
     border-radius: 10px;
     font-weight: 400;
     transition: all 0.5s;
     color:white;
  }


.text-info-text{
  font-family:"Lucida Console", Monaco, monospace;
  font-weight: lighter;
}

.title-churn-cont{
  height: calc(100% - 40px);
  width:100%;
  .churn-title{
    color:rgb(51, 68, 255);
    width:100%;
    text-align: center;
    font-size: 24px;
  }

}



.full-churn-container{
  display:flex;
  height:100%;
  width:100%;

  .churn-title{
    font-size: 24px;
    text-align:center;
    width:100%;
  }
  .churn-charts-container{
    flex:10;
  }
  .churn-legend-cont{
    margin-left:-3%;
    flex:2;
  }
}


.y-axis-group{
   color:white;
   line{
     stroke-width:1px;
     stroke:white;
     fill:white;
     opacity:1;
   }
   .domain{
     opacity:0;
   }

}

.upward-line-chart-line{
  pointer-events: none;
  .line-path-upward{
    pointer-events: none;
  }
}

.line-chart-line-g{
  pointer-events: none;
  .line-chart-line{
    pointer-events:none;
  }
}


.bubble-tool-div{
  z-index:5000;
  pointer-events: none;
.tooltip{
  text-align: center;
  background-color:rgb(89, 87, 87);
  border: 1px solid white;
  padding: 10px;
  color:white;

  border-radius:15px;
  opacity:0.65;

  .tooltip-title{
    text-transform:capitalize;
  }
  .tooltip-churn{
    padding-top: 5px;
    padding-bottom:5px;
    div{
      padding-bottom:1px
    }
  }
  .tooltip-house{
    padding-bottom:5px;
  }
}

}
.x-axis-group {
   color:white;
   fill: white;
   line{
     stroke-width:1px;
     stroke:white;
     fill:white;
     opacity:1;
   }
   .domain{
     opacity:0;
     fill:none;
   }
}

.x-axis-group .tick text {
  fill: white !important;
}
.x-axis-group .tick line {
  stroke:white !important;
  opacity:1;
}

.churn-line-bubble-cont{
  width:100%;
  height: 100%;
}

.circle-data-select{
max-height: 30px;
margin-left: 5px;
max-width: calc(100% - 25px) !important;
margin-top: 10px;
background-color: #333333;
color: white;
border-radius: 15px;
border: 1px solid #ffffff;
}

.churn-dash-main-cont{
  height:95%;
  width:95%;

   .area-chart-row-cont{
     background-color: rgb(30, 30, 30);
     padding-top: 12px;
     padding-left: 1%;
     padding-right: 1%;
     padding-bottom: 12px;

     margin:12px 1% 12px 1%;

     border-radius: 10px;
    border: 2px solid white;
     height:100%;
     width:100%;
     .churn-line-bubble-cont{
       width:100%;
       height:100%;
     }

   }

}


.container-select-households{

  margin-right:10px;
  color:white;
  cursor:pointer;
  .select-households-item{
    margin:5px;
    border: 1px solid white;
    border-radius:15px;
    padding:5px;
  }
}
