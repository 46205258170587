$break-medium:1130px;

.ratings-radial-main{
  display:flex;

  .radial-chart-container{
    text-align: center;
    display:flex;
    flex:5;
  }
  .right-legend-bullet-container{
    flex:2;
    display:flex;
    flex-flow: column;
    @media screen and (max-width: $break-medium){
    display:none;
    }

    .radial-legend-container{
      flex:3;
    }
    .right-legend-filler{
      flex:5;
      margin-top:20px;

      #word-cloud-title{
        color:white;
        margin-left:15px;
        text-decoration: underline;
      }
  }
  .bullet-chart-container{
    flex:3;
    #bullet-chart-title{
      color:white;
      text-decoration: underline;
    }
  }
  .flex-filler-right-radial{
    flex:1;
  }
}
}

.amazon-class{
  fill:#f08804;
}

.IOs-class{
  fill:rgb(0,122,255)
}

.android-class{
  fill:#A4C639;
}

.ANDROID-class{
  stroke:#A4C639;
}
.IOS-class{
  stroke:rgb(0,122,255);
}
.AMAZON-class{
  stroke:#f08804;
}


.axis-label-text{
  font-weight: 300;
}

.year-select-ratings{
  width:10%;
  height:30px;
  display:inline-block;

  select{
    background-color:#333333;
    color:white;


  }
}

.select-component-div{
  flex:2;
  flex-flow: column;
  display:flex;
  .select-component{
  width:50%;
  min-height:20px;
  flex:1;
  margin-left:35px;
  margin-top:2px;


  select{
    max-height:30px;

    width:calc(100%) !important;

    background-color:#333333;
    color:white;
    border-radius:15px;
    border:1px solid #ffffff;
  }
  }
  .left-flex-filler{
    flex:1
  }
  .left-legend-visual{
    margin-top: 15px;
    margin-left:25px;
    flex:50
  }
}

.radial-ordinal-g{
  fill:white
}

.radial-ordinal-lines{
  fill:white;
}

.show-radial-point{


  -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
     -moz-animation: fadein 0.5s; /* Firefox < 16 */
      -ms-animation: fadein 0.5s; /* Internet Explorer */
       -o-animation: fadein 0.5s; /* Opera < 12.1 */
          animation: fadein 0.5s;

  @keyframes fadein {
  from { opacity: .1; }
  to   { opacity: 0.55; }
  }

  /* Firefox < 16 */
  @-moz-keyframes fadein {
  from { opacity: .1; }
  to   { opacity: 0.55; }
  }

  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes fadein {
  from { opacity: .1; }
  to   { opacity: 0.55; }
  }

  /* Internet Explorer */
  @-ms-keyframes fadein {
  from { opacity: .1; }
  to   { opacity: 0.55; }
  }

  /* Opera < 12.1 */
  @-o-keyframes fadein {
  from { opacity: .1; }
  to   { opacity: 0.55; }
  }
  opacity:1;

}

.hide-radial-point{
   opacity: 0.1;
   pointer-events:none;
}

.radial-ordinal-g{
  .cell{
    cursor:pointer;
  }
}

.radial-ordinal-lines{
  .cell{
    cursor:pointer;
  }
}

.radial-point{
  cursor: pointer;
}

.radial-tooltip-on{
  position:absolute;
}

.radial-tooltip-off{
  display:none;
}

.radial-plot-tooltip{
   background-color:white;

   min-width:250px;
   opacity:0.75;
   text-align:center;
   z-index:1005;
   padding:5px;
   border-radius: 7px;
   pointer-events:none;

}

.radial-dashboard-title{
  color:rgba(51, 64, 204, 0.9);
  text-align:center;
  font-size: 24px;
  margin-top:5px;
  max-height:24px;
  width:100%;
  display:block;
}

.legend-rect-shadow{
  opacity:0.65;
  &:hover{
    opacity:1.0;
  }
}

.x-axis-group{
  .domain{
    opacity:0.0;
  }
  .tick{
  text{
    fill:grey;
  }
  line{
    stroke:grey;
  }
}
}

.legendCells{
  .cell{
    text{
      pointer-events:none;
    }
    circle{
      pointer-events: none;
    }
    rect.swatch{
      pointer-events:none;
    }
  }
}

.info-circle{
  background-image: url("../../../../static/images/info.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-color: lightgrey;
}

.center-hover-arrow{
  fill:white;

  &:hover{
    cursor: pointer;
    fill:blue;
    svg{
    fill:blue;
    }
  }
}
