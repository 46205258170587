.filter-tool-container{
    opacity:0.92;
    position: absolute;
    top: 70px;
    right: 130px;
    z-index:6000;
    background-color:#c1bfbf;
    border: 1px solid white;
    border-radius: 10px;
    text-transform:uppercase;
    color:#4c4c4c;
    padding:5px;
    padding-left:15px;

    .filter-title-environments{
      border-bottom: 1px solid black;
      color:black;
      padding-top:10px;
      padding-bottom:0px;
      margin-bottom:2px;
    }

  .filter-selection-container {
    display:flex;
    max-width:250px;
    min-width:190px;

    .filter-selection-column {
      flex:1;
      display:flex;
      flex-direction:column;
   }
  }

  .filter-show-none{
    margin-top:10px;
    border-radius: 15px;
    padding:5px;
    font-weight: bold;
    cursor: pointer;
    &:focus {outline:0;}
  }

}
.tool-hidden{
  display:none;
}

.filter-selection{
    line-height: 28px;
    cursor: pointer;
    &:hover {
      color:white;
      .filter-selection-input{
        border: 2px solid white;
        margin-right:8px !important;
        vertical-align: middle;
        margin-left: -2px;

      }
    }
}

.filter-selection-input{
  vertical-align: middle;
  margin-right:10px;

}

.hidden{
  display:none;
}

.filter-exit-icon{
  float: right;
  cursor:pointer;
  margin-top:0px !important;
  margin-left:0px !important;
  width: 25px;
  height: 25px;

}

.filter-icon-main{
  height: 35px;
 width: 35px;
}

.move-filter-icon{
  cursor: move;
  font-size:10px;
}
