@import "../containers/variables";

.composite-module {
  font-family: "Raleway", sans-serif;

  #titleText {

    font-size: 24px;
  }

  #legend {
    position: absolute;
    top: 100vh;
    left: 0px;
    padding: 20px;
    transform: translateY(-100%);
    font-size: 14px;
    line-height: 32px;
  }
  .legend {
    text-anchor: end;
    text {
      transition: all 0.25s;
      opacity: 0.7;
      text-transform: capitalize;
      fill: rgba(255, 255, 255, 1);
      &.activated {
        opacity: 1;
        font-size: 120%;
        transform: translateX(-10px);
      }
    }
  }
  .legendRow {
    cursor: pointer;
  }
  .legend-rect {
    transform: opacity 0.25s;
    &.dimmed {
      opacity: 0.25;
    }
  }

  #timestamp {
    color: $grey_99;
  }

  .tickLeft {
    color: $white;
    font-weight: 300;
    font-size: 16px;
    opacity: 0.7;
    line {
      stroke-dasharray: 1, 4;
      stroke-width: 1;
      stroke-linecap: "round";
      stroke: rgba(255, 255, 255, 0.7);
    }
  }

  .tickHighlight {
    font-weight: 600;
    opacity: 1 !important;
  }

  .tickBottom {
    color: $white;
    font-weight: 300;
    font-size: 14px;

    text {
      color: white;
      opacity: 0.7;
    }

    .tick:nth-child(even) {
      line {
        transform: scaleY(4);
      }
      text {
        transform: translateY(20px) !important;
      }
    }
  }

  svg {
    shape-rendering: crispEdges;
    margin-top: 10px;
    margin-left: 25px;
  }

  .axis path,
  .axis line {
    fill: none;
    stroke: lightgray;
  }

  path.domain {
    stroke: none;
  }

  #chartTypeSelector {
    visibility: hidden;
  }

  .navButton {
    padding: 0 10px;
    margin: 5px;
    line-height: 20px;
    height: 20px;
    border-radius: 30px;
    background: $grey_99;
    box-sizing: border-box;
    border: none;
    color: $white;
    z-index: 100;
    outline: none;
    display: inline-block;
    &:hover,
    &:active {
      cursor: pointer;
      background: $grey_cc;
    }
    &:disabled {
      cursor: default;
      background: $grey_33;
    }
  }
  .displayRange {
    color: rgba(255, 255, 255, 0.7);
    font-size: 80%;
  }

  #titleHeader {
    padding-top: 2px;
    padding-left: 20px;
  }

  #title {
    position: absolute;
    top: 0px;
    left:0px;
    text-align: center;
    width:100vw;
    pointer-events: none;
  }

  .bar {
    transition: all 0.25s;
    stroke-width: 1;
    opacity: 1 !important;
    &.active {
      stroke-width:1px;
      stroke:lightgrey;
    }
    &.inactive {
      opacity: 0.3 !important;
    }
  }

  div.tooltip {
    position: absolute;
    text-align: center;
    padding: 20px 30px;
    background: hsla($globalHue, 100%, 90%, 0.8);
    border: 0px;
    pointer-events: none;
    transform: translate(-50%, -150%);
    z-index: 1;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.4);
    .tooltipEnv {
      font-size: 21px;
      font-weight: 500;
      text-transform: capitalize;
    }
    .tooltipLabel {
      font-size: 16px;
    }
    .tooltipVal {
      font-size: 28px;
    }
  }
}

.radioBg {
  width: 120px;
  height: 20px;
  background-color: $grey_33;
  display: inline-block;
  margin: 5px;
  border-radius: 30px;
  &:hover {
    background-color: $grey_66;
    cursor: pointer;
  }
}

.axisSelector {
  display: flex;
  height: 20px;

  .input {
    border: none;
    border-bottom: 1.5px solid #6665ad;
    font-size: 14px;
    display: block;
    width: 89%;
    float: left;
    height: 20px;
    outline: 0;
    background-color: transparent;
    color: rgba(255, 255, 255, 0.7);
  }

  #yMaxAxisSelector {
    display: flex;
    flex-direction: row;
    width: 20%;

    .input-div {
      width: 13%;
      margin-top: 6px;
      margin-left: 20px;
    }

    label {
      color: white;
      font-size: 14px;
      margin-top: 15px;
    }
  }

  .resetButton {
    top: 10px;
    left: 20px;
    pointer-events: all;
    &:hover {
      background-color: $grey_66;
      cursor: pointer;
    }
  }

  #xRangeAxisSelector {
    display: flex;
    label {
      color: white;
      font-size: 14px;
      margin-top: 15px;
    }

    #toDate {
      position: relative;
      left: 14px;
    }

    .input-div {
      top: 10px;
      position: relative;
      left: 10px;
    }

    #resetButton {
      top: 10px;
      position: relative;
      left: 45px;
    }
  }

  #xRangeAxisSelector div:nth-child(4) {
    left: 30px !important;
  }
}

.radioBt {
  width: 60px;
  height: 20px;
  background: $grey_99;
  color: $white;
  border: none;
  padding: 0px;
  margin: 0px;
  border-radius: 30px;
  pointer-events: none;
  position: relative;
  top: -1px;
}

.radioDisable {
  padding: 0px;
  width: 60px;
  height: 20px;
  background: inherit;
  color: $grey_99;
  border: none;
  margin: 0px;
  border-radius: 30px;
  pointer-events: none;
  position: relative;
  top: -1px;
}
