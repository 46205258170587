@import "../../containers/variables";

#balloon-map {
  path.feature {
    fill: none;
    stroke: hsla($globalHue, 80%, 20%, 1) !important;
    stroke-linejoin: round;
  }

  .cartogram {
    z-index: 0 !important;
    .feature {
      stroke: darkgrey;
      transition: fill-opacity 0.7s;
      cursor: pointer;
      &:hover {
        fill-opacity: 0.6;
        transition: fill-opacity 0.1s;
      }
    }
  }
  
  .cartogram-tooltip {
    display: none;
    position: absolute;
    max-width: 320px;
    margin-top: 20px;
    margin-left: 8px;
    padding: 5px;
    border-radius: 3px;
    font: 11px sans-serif;
    color: $grey_ee;
    background: rgba(0, 0, 0, 0.65);
    pointer-events: none;
  }
}
