@import "../containers/variables";

#titleText {
  color: $white;
}

#scrollPane {
  margin: 20px;
  border-radius: 10px;
  padding: 10px;
  overflow-y: auto;
}

.itemTitle {
  margin: 0px 16px 0px;

  font-size: 32px;
  font-weight: 100;
  color: rgb(51, 201, 255);
}

.itemSubtitle {
  margin: 0px 16px 0px;
  font-weight: 300;
  color: $grey_dd;
  font-size: 24px;
}

.itemDescription {
  margin: 0px 16px 32px;
  color: $grey_99;
}
