@import "../../containers/variables";

.login {
  &__page {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: stretch;
    align-items: center;
    background: $grey_22;
    height: 100vh;
    position: relative;
    z-index: 10;
    overflow: hidden;

    &::after {
      content: "";
      height: 100vh;
      width: calc(50vw + 40px);
      background-color: rgba(0, 0, 0, 0.8);
      pointer-events: none;
      position: absolute;
      top: 0;
      left: calc(50% - 40px);
      display: block;
      z-index: 10;
    }
    #background {
      position: relative;
      z-index: -1;
    }
    canvas {
      width: 100%;
      height: 100vh;
    }
    canvas,
    svg {
      // z-index: -1;
      position: relative;
    }

    .form {
      &__login {
        position: absolute;
        left: 50%;
        z-index: 12;
        pointer-events: none;
        img {
          width: 30%;
        }

        h1 {
          font-size: 60px;
          color: $white;
          line-height: 1.18;
          margin: 4% 0;
        }

        .login {
          &__input {
            pointer-events: all;
            width: 50%;
            margin: 2% 0;
            color: rgba(255, 255, 255, 0.6);

    input:disabled,
    button:disabled {
      cursor: no-drop;
    }

            label {
              font-size: 12px;
              margin-bottom: 10px;
            }
            input {
              display: block;
              border: none;
              border-bottom: 1.5px solid #6665ad;
              font-size: 14px;
              display: block;
              width: 125%;
              height: 40px;
              outline: 0;
              background-color: transparent;
              color: rgba(255, 255, 255, 0.7);
              z-index: 1;
            }
          }
        }

        input,
        button {
          pointer-events: all;
        }

        .forgotPassword {
          pointer-events: all;
          display: block;
          line-height: 1.2;
          background: transparent;
          outline: 0;
          border: none;
          color: $white;
          text-decoration: underline;
          font-size: 14px;
          padding: 0;
          margin: 10px 0px;
          cursor: pointer;
          z-index: 9999;

          &:hover {
            color: #6665ad;
          }
        }

        button[type="submit"] {
          pointer-events: all;
          font-size: 14px;
          color: $white;
          line-height: 1.2;
          text-transform: uppercase;
          justify-content: center;
          align-items: center;
          margin-top: 15px;
          width: 120px;
          height: 50px;
          background-color: #1fadbf;
          cursor: pointer;
          border: none;
          border-radius: 25px;
          outline: 0;
          transition: all 0.4s;

          background-image: url("../static/images/arrowRight.svg");
          background-repeat: no-repeat;
          background-position: 80% 45%;
          background-size: 13px;
          text-align: left;
          padding-left: 30px;

          &:hover {
            // font-size: larger;
            // font-weight: bold;
            letter-spacing: 0.7px;
            background-color: #1eedf7;
          }
        }

        .alert {
          &--danger {
            margin: 15px 0px;
            color: rgb(238, 59, 59);
          }
        }
      }
    }
  }
}
