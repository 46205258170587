@import "../../containers/variables";

#multiple-charts {
  #vis {
    padding-top: 15px;
  }

  svg {
    display: block;
    margin: auto;
  }

  .line {
    fill: none;
    stroke: $black;
    stroke-width: 2px;
  }

  .axis {
    shape-rendering: crispEdges;
    text {
      font: 10px sans-serif;
      color: $white;
    }
  } 

  .x.axis {
    line {
      stroke: $grey_dd;
      stroke-opacity: 0.6;
    }
    path {
      display: none;
    }
  }

  footer p {
    text-align: center;
  }

  // #legend {
  //   position: fixed;
  //   top: 100px;
  //   right: 0px;
  //   width: 200px;
  //   height: 280px;
  //   z-index: 100000;
  //   overflow: visible;
  // }

  /* LEGEND */
  .legend {
    position: absolute;
    top: 0;
    right: 15px;
  }
  .legend-entry {
    display: flex;
    align-items: center;

    div {
      height: 20px;
      width: 20px;
      display: inline-block;
      margin-right: 5px;
    }
    p {
      color: $white;
      display: inline;
      font-size: smaller;
    }
  }

  /* VERTICAL LINE */
  .vertical {
    position: absolute;
    z-index: 19;
    width: 1px;
    height: 95%;
    top: 10px;
    bottom: 30px;
    left: 0px;
    background: $white;
  }
}
