@import "../../containers/variables";

#choropleth-zoom {
  .countryPath {
    /* fill: none; */
    stroke-linejoin: round;
    opacity: 0.7;
  }
  .countryPathOutline {
    fill: none;
    stroke: hsla($globalHue, 50%, 20%, 1);
  }
  /* .countryPath:hover ~ .countryMetadata{
    opacity: 1;
  } */
  .countryMetadataLabel {
    transition: all 0.3s;
  }
  .countryMetadata {
    pointer-events: none;
    text-anchor: middle;
    alignment-baseline: middle;
    fill: hsla($globalHue, 90%, 90%, 1);
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 24px;
    &.showAnyway {
      opacity: 1;
      .countryMetadataFields {
        opacity: 0;
      }
      .countryMetadataLabel {
        transform: translateY(-10);
        font-size: 18px;
      }
    }
    text {
      text-anchor: middle;
    }
    .countryMetadataFields {
      transition: all 0.3s;
      opacity: 0.7;
      font-size: 14px;
    }
    &.activated {
      opacity: 1;
      .countryMetadataFields {
        opacity: 0.7;
      }
      .countryMetadataLabel {
        transform: translateY(0);
        font-size: inherit;
      }
    }
  }
  .countryFlag {
    opacity: 0.5;
    &.activated {
      transform: translate(5px, -10px);
      opacity: 1;
    }
  }
  .countryBar {
    &.activated {
      transform: translateY(-20px);
    }
  }
  .legendThreshold {
    font-size: 12px;
    font-family: sans-serif;
    text-transform: capitalize;
  }
  .caption {
    fill: $white;
    text-anchor: start;
    font-weight: bold;
  }
  .label {
    fill: $white;
    min-width: 150px;
  }
  .legendText {
    fill: $white;
    color: $white;
  }

  svg {
    z-index: 1;
    &.spinner {
      z-index: 1000;
    }
  }

  .toggleButton {
    transition: all 0.25s;
    opacity: 0.3;
    cursor: pointer;
    &:hover {
      opacity: 0.5;
    }
    &.active {
      opacity: 1;
      cursor: default;
    }
  }
  .toggleButtonImg {
    transition: all 0.25s;
    opacity: 0.3;
    pointer-events: none;
    // &:hover{
    //   opacity: .7;
    // }
    &.active {
      opacity: 1;
      cursor: default;
    }
  }

  .dimensionButton {
    transition: all 0.25s;
    opacity: 0.3;
    cursor: pointer;
    &:hover {
      opacity: 0.5;
    }
    &.active {
      opacity: 1;
      cursor: default;
    }
  }
  .dimensionButtonImg {
    transition: all 0.25s;
    opacity: 0.3;
    pointer-events: none;
    // &:hover{
    //   opacity: .7;
    // }
    &.active {
      opacity: 1;
      cursor: default;
    }
  }

  .deploymentButtonWrap {
    opacity: 0.5;
    transition: all 0.3s ease-out;
    &:hover {
      opacity: 0.8;
    }
    &.collapsed {
      pointer-events: none;
      transform: translate(0, 0) !important;
      opacity: 0;
    }
    &.active {
      pointer-events: all;
      opacity: 1;
    }
    text {
      alignment-baseline: middle;
      text-anchor: middle;
      pointer-events: none;
      font-size: 10px;
    }
  }

  #title-text {
    font-size: 24px;
    font-weight: 100;
    text-anchor: middle;
  }

  div.tooltip {
    position: absolute;
    text-align: center;
    padding: 20px 30px;
    background: hsla($globalHue, 100%, 90%, 0.8);
    border: 0px;
    pointer-events: none;
    z-index: 1;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.4);
    width: 300px;
    .tooltipEnv {
      font-size: 24px;
      font-weight: 500;
      text-transform: capitalize;
    }
    .tooltipLabel {
      font-size: 16px;
      margin: 10px 0px;
    }
    .tooltipVal {
      // display: inline-flex;
      // width: 70%;
      font-size: 18px;
      font-weight: 500;
      text-align: left;
    }
    img {
      position: absolute;
      width: 60px;
      height: 30px;
      bottom: 25px;
      right: 30px;
    }
  }
}
