.people-chord-conatainer{
  height:calc(100vh - 40px);
  width:100%;
  max-height:1350px;

}

.people-chord-div{

.people-chord-svg{

  .people-arc{
    cursor:pointer;
  }
  .ribbon-hidden{
    opacity:0.01;
  }
  .ribbon-visible{
    -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 0.5s; /* Firefox < 16 */
        -ms-animation: fadein 0.5s; /* Internet Explorer */
         -o-animation: fadein 0.5s; /* Opera < 12.1 */
            animation: fadein 0.5s;

    @keyframes fadein {
    from { opacity: .01; }
    to   { opacity: 0.55; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: .01; }
    to   { opacity: 0.55; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: .01; }
    to   { opacity: 0.55; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: .01; }
    to   { opacity: 0.55; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: .01; }
    to   { opacity: 0.55; }
}
 opacity:0.55;
  }
  .ribbon-hide{

      -webkit-animation: fadeout 0.5s; /* Safari, Chrome and Opera > 12.1 */
         -moz-animation: fadeout 0.5s; /* Firefox < 16 */
          -ms-animation: fadeout 0.5s; /* Internet Explorer */
           -o-animation: fadeout 0.5s; /* Opera < 12.1 */
              animation: fadeout 0.5s;

      @keyframes fadeout {
      from { opacity: 0.55; }
      to   { opacity: 0.01; }
  }

  /* Firefox < 16 */
  @-moz-keyframes fadeout {
      from { opacity: 0.55; }
      to   { opacity: 0.01; }
  }

  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes fadeout {
      from { opacity: 0.55; }
      to   { opacity: 0.01; }
  }

  /* Internet Explorer */
  @-ms-keyframes fadeout {
      from { opacity: 0.55; }
      to   { opacity: 0.01; }
  }

  /* Opera < 12.1 */
  @-o-keyframes fadeout {
      from { opacity: 0.55; }
      to   { opacity: 0.01; }
  }
  opacity:.01
  }

.chord-title{
  font-size:40px;
  color :white;
}

.label-group{

}
.ribbon-line-paths{
  pointer-events:none;
}

.label-group{

  text{
    cursor: pointer;
  }
  circle{
    pointer-events:none;
  }
  path{
    pointer-events:none;
  }
}
 }

 #first-toggle-label{
   margin-left:20%;
   font-weight: lighter;
 }
 text{
   font-weight: lighter;
 }
 #second-toggle-label{
   margin-left:1%;
 }
 text{
   font-weight: lighter;
 }
}
