@import "../../containers/variables";


#animated-switch-div {
  position: absolute;
  //left: 0;
  right: 0;
  top: 40px;
  bottom: 0;
  width:100%

}
.login__wrapper {
  position: absolute;
  top: 0px;
}

/* RESPONSIVE SVG */
.svg-container {
  display: inline-block;
  position: relative;
  width: 100%;
  padding-bottom: 100%; /* aspect ratio */
  vertical-align: top;
  overflow: hidden;
}
.svg-content-responsive {
  display: inline-block;
  position: absolute;
  top: 10px;
  left: 0;
}

/* SLIDER START ========================================= */
#slider,
#slider3,
#rangeSlider {
  z-index: 2;
  color: $white;
  .domain {
    stroke-width: 0;
  }
}

#rangeSlider {
  flex-grow: 3;
  flex-basis: 75%;
}

#slider svg {
  line {
    &.track {
      stroke-opacity: 0.4;
      stroke: $black;
      stroke-width: 10px;
    }
    &.track-inset {
      stroke: rgba(255, 255, 255, 0.5);
      stroke-width: 8px;
    }
    &.track-overlay {
      stroke: $grey_cc;
      stroke-width: 40px;
      stroke-opacity: 0;
      cursor: pointer;
    }
  }
  .handle {
    fill: $white;
    stroke: $black;
    opacity: 1;
  }
  line {
    &.track-overlay,
    &.track,
    &.track-inset {
      stroke-linecap: round;
    }
  }
}

#slider3 svg,
#rangeSlider svg {
  line {
    &.track {
      stroke-opacity: 0.4;
      // stroke: $black;
      stroke-width: 10px;
    }
    &.track-inset {
      stroke: rgba(255, 255, 255, 0.1);
      stroke-width: 9px;
    }
    &.track-overlay {
      stroke: $grey_33;
      stroke-width: 40px;
      stroke-opacity: 0;
      cursor: pointer;
    }
  }
  .handle {
    fill: $white;
    // stroke: $black;
    opacity: 1;
  }
  line {
    &.track-overlay,
    &.track,
    &.track-inset {
      stroke-linecap: round;
    }
  }
}

.slider {
  position: relative;
  display: inline-block;
  top: 10px;
  // right: 10px;
  z-index: 9999;
  &.test {
    right: 150px;
  }
  &.count {
    right: 300px;
  }
  input {
    cursor: pointer;
  }
  & > g.tick {
    display: none;
  }
  .ticks {
    color: #fff;
  }
}
/* ========================================= SLIDER END */

/* INFORMATION POPUP START ========================================= */
#info-popup {
  position: absolute;
  z-index: 2;
  width: 15%;
  min-height: 25px;
  color: $white;
  text-align: right;
  // background-image: url("../static/images/info.svg");
  background-repeat: no-repeat;
  background-position: 50% 0px;
  // background-size: 25px;

  &::after {
    height: 25px;
    width: 25px;
    background-image: url("../static/images/info.svg");
    display: inline-block;
    content: " ";
    background-size: contain;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    opacity: 0.7;
    transition: all 0.25s;
  }
  &:hover::after {
    opacity: 1;
    height: 30px;
    width: 30px;
  }

  .info-content {
    z-index: 1;
    position: relative;
    font-size: 16px;
    text-align: left;
    max-height: 0;
    line-height: 24px;
    transition: all 0.5s ease-in-out;
    opacity: 0;
    overflow: hidden;
    border-radius: 10px;
    background-image: linear-gradient(
      hsla($globalHue, 0%, 0%, 0.7),
      hsla($globalHue, 0%, 0%, 0.3)
    );

    h4 {
      font-size: 24px;
      font-weight: 200;
      text-align: center;
      margin: 5px 0 0 0;
      padding: 10px;
      border-bottom: 1px solid hsla($globalHue, 0%, 70%, 0.5);
    }
    .infoContent {
      padding: 10px;
      margin: 0;
    }
    .updateInfo {
      border-top: 1px solid hsla($globalHue, 0%, 70%, 0.5);
      padding: 10px;
      opacity: 0.5;
    }
    &:hover {
      display: block;
    }
  }
}
#info-popup:hover,
.info-content:hover {
  .info-content {
    opacity: 1;
    max-height: 1000px;
  }
}
/* ========================================= INFO POPUP END */

/* NAVIGATION TAB START ========================================= */
#navigation-tab {
  background-color: hsla($globalHue, 0%, 10%, 1);
  background-image: url("../static/images/plume-logo-white.svg");
  background-repeat: no-repeat;
  background-position: 50px 7.5px;
  background-size: 30px;
  box-shadow: 0px 0px 10px hsla($globalHue, 0%, 0%, 0.3);
  width:100%;
  padding: 0;
  margin: 0;
  align-items: center;
  top: 0;
  height: 40px;
  display: flex;
  position: fixed;
  right:0px;
  left:0px;
  z-index: 9999;
  > div {
    &:first-child {
      display: flex;
      color: $white;
      flex: 1;
      padding-left: 100px;
      align-items: baseline;
    }
    &:last-child {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      padding-right: 10px;
      position: relative;
    }
  }
}
.navSpan {
  cursor: pointer;
  color: #888da1;
  line-height: 2.67;
  transition: color 0.25s;
  z-index: 5000;

  @media screen and (max-width: 992px) {
    display: none;
  }


  &.active {
    color: #f0bc5e;
    cursor: default;
    margin-top: 4.5px;
  }

  &:hover {
    &:not(.active) {
      color: hsla($globalHue, 0%, 80%, 0.9);
    }
  }
}
.chevron {
  margin: 0 10px;
  color: #888da1;

  @media screen and (max-width: 992px) {
    display: none;
  }
}

#userActions-popup {
  position: absolute;
  z-index: 999;
  min-height: 25px;
  top: -12px;
  color: white;
  text-align: left;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: flex-end;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  width: 100%;

  #userActions-hover {
    width: 40%;
    position: relative;
  }

  #toggle-menu-div {
    cursor: pointer;
  }

  div {
    justify-content: flex-end;
    align-items: center;
    display: flex;
    white-space: nowrap;
  }

  #toggle-menu-picture {

    display: inline-block;

    @media screen and (max-width: 992px) {
      display: none;
    }

    width: 28px;
    height: 28px;
    border-radius: 14px;
    overflow: hidden;
    background-size: cover;
    background-position: center center;
    margin-left: 8px;
  }

  .userActions-content {
    position: absolute;
    top: 0px;
    margin-top: 35px;
    text-align: left;

    ul {
      list-style-type: none;
      padding: 5px;
      border-radius: 4px;
      background-color: #0c233a;

      li {
        display: flex;
        margin: 2.5px 0px;
        padding: 0 5px;
        text-align: center;
        border-radius: 4px;
        background-color: #0b2641;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        height: 40px;

        button {
          background: transparent;
          border: none;
          outline: none;
          color: #d8e2eb;
          font-weight: 500;
          line-height: 2.46;
          cursor: pointer;
        }
      }
      li:first-child,
      li:last-child {
        margin: 0;
      }
      li:last-child > button {
        color: #be5555;
      }

      li:hover button {
        // letter-spacing: 0.5px;
        font-size: 14px;
      }
    }
  }
}

#switch-client-select {
  border: none;
  outline: none;
  background: transparent;
  color: #fff;
  margin: 8px 0;
  cursor: pointer;

  option {
    padding: 8px;
    margin: 10px;
    background-color: #0b2641;
  }
}

.offline__sign {
  color: #fff;
  position: absolute;
  top: 40px;
  height: 40px;
  background: rgba($error, 0.6);
  width: 100%;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  display: flex;
}

// #userActions-hover:hover,
// .userActions-content:hover {
//   .userActions-content {
//     display: flex;
//   }
// }
/* ========================================= NAVIGATION TAB END */

/* NOTIFICATION-POPUP START ========================================= */
.notification-popup {
  position: absolute;
  top: 5%;
  right: 5%;
  min-width: 200px;
  cursor: pointer;
  border-radius: 2px;
  font-size: 13px;
  margin: 10px 0px 0px;
  padding: 10px 20px 10px 10px;
  display: block;
  box-sizing: border-box;
  opacity: 1;
  transition: all 0.5s ease-in-out 0s;
  transform: translate3d(0px, 0px, 0px);
  will-change: transform, opacity;
  z-index: 9999;

  h4 {
    font-size: 14px;
    margin: 0px 0px 5px;
    padding: 0px;
    font-weight: bold;
  }

  div {
    margin: 0px;
    padding: 0px;
  }

  span {
    font-family: Arial;
    font-size: 17px;
    position: absolute;
    top: 3px;
    right: 5px;
    line-height: 15px;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    font-weight: bold;
    text-align: center;
  }
}

.success {
  border-top: 2px solid rgb(94, 164, 0);
  background-color: rgb(240, 245, 234);
  color: rgb(75, 88, 58);
  box-shadow: rgba(107, 107, 107, 0.9) 0px 0px 1px;

  h4 {
    color: rgb(94, 164, 0);
  }

  span {
    background-color: rgb(176, 202, 146);
    color: rgb(240, 245, 234);
  }
}

.error {
  border-top: 2px solid rgb(236, 61, 61);
  background-color: rgb(244, 233, 233);
  color: rgb(65, 47, 47);
  box-shadow: rgba(236, 61, 61, 0.9) 0px 0px 1px;

  h4 {
    color: rgb(236, 61, 61);
  }

  span {
    background-color: rgb(228, 190, 190);
    color: rgb(244, 233, 233);
  }
}
/* ========================================= NOTIFICATION-POPUP END */

/* LOADING SPINNER START ========================================= */
#spinner {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  transition: background-color 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);
  z-index: 10;
  background-color: rgba(0, 0, 0, 0);
  &.visible {
    background-color: rgba(0, 0, 0, 0.5);
  }
  g {
    transition: opacity 0.75s cubic-bezier(0.39, 0.575, 0.565, 1);
    &.finished {
      // opacity: 0;
    }
  }
  circle {
    stroke: $red;
    overflow: visible;
    fill: none;
  }
  path {
    fill: $white;
  }
}
/* ========================================= LOADING SPINNER END */

/* MISCELANOUS */
.main-container {
  position: fixed;
  width: 100vw;
  min-height: calc(100vh - 40px);
  overflow: hidden;
  margin-top: 0px;
  background: linear-gradient(
    hsla($globalHue, 0%, 0%, 1),
    hsla($globalHue, 0%, 20%, 1)
  );
  position: relative;
}
.flex-parent {
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
}
.flex-column,
.flex-parent {
  display: flex;
  flex-direction: column;
}
.flex {
  display: flex;
  flex: 1 1;
}

@-webkit-keyframes autofill {
  to {
    color: rgba(255, 255, 255, 0.7);
    background: transparent;
  }
}
input:-webkit-autofill {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}

.timeseriesSwitch {
  position: absolute;
  top: 10px;
  right: 30px;
  width: 150px !important;
  z-index: 10;

  input {
    width: 75px !important;
  }
}

#errorWrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: $red;
  font-size: larger;
}

/* HEADER TIME MENU */

#caretDrop {
  //display: none;
  visibility: hidden;

  width: 33%;
  height: 40px;
  position: fixed;
  top: 0px;
  z-index: 10000;
  left: 50%;
  transform: translateX(-50%);
  color: rgba(255, 255, 255, 0.7);
  &.hidden {
  //  display: none;
    visibility: hidden;
  }
  cursor: default;
  display: inline-flex;
}

#caretDrop:hover {
  color: white;
  cursor: pointer;
}

#menuTitle {
  position: absolute;
  width: 100%;
  top: 0px;
  text-align: center;
}

#menuGrain {
  top: 10px;
  text-align: right;
  position: absolute;
  width: 45%;
  font-size: 80%;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.5);
}

#menuRange {
  top: 10px;
  text-align: left;
  position: absolute;
  left: 55%;
  width: 45%;
  font-size: 80%;
  color: rgba(255, 255, 255, 0.5);
}

#menuCaret {
  top: 10px;
  position: absolute;
  text-align: center;
  display: inline-block;
  left: 45%;
  width: 10%;
  color: rgba(255, 255, 255, 0.7);
  height: 15px;
  background: url("../static/images/menu_chevron.png");
  background-repeat: no-repeat;
  background-size: 40%;
  background-position: center center;
  opacity: 0.5;
  transition: all 0.25s;
  #caretDrop:hover & {
    opacity: 1;
    background-size: 60%;
  }
}

#headerMenu {
  height: 70px;
  overflow: visible;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  user-select: none;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  text-align: left;
  color: $white;
  padding: 10px 20px 10px 20px;
  background-image: linear-gradient(
    180deg,
    hsla($globalHue, 5%, 20%, 0.8),
    hsla($globalHue, 0%, 20%, 0.8)
  );
  border-radius: 0 0 10px 10px;
  z-index: 10;
  transition: all 0.3s;
  box-shadow: 0 2px 5px hsla($globalHue, 0%, 10%, 0.3);
  opacity: 1;
  &.hidden {
    //display: none;
    visibility: hidden;
    transform: translate(-50%, -100%);
  }
  &.open {
    // background-color: hsla($globalHue, 0%, 20%, 1);
    background-image: linear-gradient(
      180deg,
      hsla($globalHue, 5%, 20%, 1),
      hsla($globalHue, 0%, 10%, 1)
    );
    box-shadow: 0 2px 10px hsla($globalHue, 0%, 10%, 0.7);
    .range {
      max-height: 1000px;
      opacity: 1;
      margin: 5px 5px;
      &:first-of-type {
        margin-top: 0;
      }
    }
  }

  .range {
    &.active {
      min-height: 20px;
      max-height: 1000px;
      opacity: 1;
      color: $white;
    }

    &:hover .label {
      transform: scale(1.1);
    }

    &.disabled {
      color: gray;

      .label {
        cursor: default !important;
      }
    }

    .label {
      transition: transform 0.25s;
      cursor: pointer;
      white-space: nowrap;
    }

    .nav {
      display: none;
      position: absolute;
      top: 0;
      height: 20px;
      width: 20px;
      border-radius: 10px;
      background-color: hsla($globalHue, 0%, 70%, 0);
      cursor: default;
      transition: background-color 0.25s ease;
      overflow: hidden;
      &.prev {
        left: 0px;
      }
      &.next {
        right: 30px;
      }
      &.reset {
        right: 0px;
        text-align: center;
      }
      &::before {
        position: absolute;
        color: hsla($globalHue, 0%, 90%, 1);
        top: 10px;
        transform: translate(-50%, -50%);
        opacity: 0.3;
        transition: opacity 0.25s ease;
        font-size: 66%;
      }
      &.active::before {
        opacity: 1;
      }
      &.prev::before {
        content: "\25C0";
        left: 9px;
      }
      &.next::before {
        content: "\25B6";
        left: 11px;
      }
      &.reset::before {
        content: "\21E5";
        font-size: 130%;
        top: 10px;
        left: 6px;
      }
    }

    &.active .nav {
      background-color: hsla($globalHue, 0%, 50%, 0.2);
      display: inline-block;
    }
    .nav.active {
      background-color: hsla($globalHue, 0%, 50%, 1);
      pointer-events: all;
      cursor: pointer;
    }
  }
}

#rangeArea {
  display: none;
  flex-basis: 25%;
  flex-grow: 1;
  background-color: #202020;
  border-radius: 1rem;
  padding: 0.25rem 0;
  &.hidden {
    display: none;
  }
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
}

#headerMenu .range .grain {
  width: 33%;
  padding: 0 0.5rem;
  font-weight: 500;
  text-align: right;
  overflow: hidden;
  margin-left: 16.5%;
  margin-right: 2rem;
}

#headerMenu .range .timeframe {
  width: 33%;
  padding: 0 0;
  font-weight: 100;
  text-align: left;
  white-space: nowrap;
}

#headerMenu .range {
  width: auto;
  display: flex;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  margin: 0px 5px;
  padding: 0 75px 0 25px;
  position: relative;
  text-align: right;
}

#headerMenu:hover,
#rangeSlider:hover {
  #rangeSlider {
    display: block;
  }
}

.button-off {
  pointer-events: none;
}

#okta-sign-in.auth-container.main-container{
  position: absolute;
    left: 50%;
    z-index: 12;
    top:20%;
    max-height:450px;
    min-height:0px;
    color:white !important;

}
#okta-sign-in.auth-container.main-container{
  color:white !important;
}
#okta-sign-in.auth-container .link:link, #okta-sign-in.auth-container .link:visited, #okta-sign-in.auth-container .link:hover, #okta-sign-in.auth-container .link:active{
  color:white !important;
}

#okta-sign-in.auth-container h2, #okta-sign-in.auth-container h3{
  color:white !important;
}


.data-bubble-disclaimer text{
  text-align: left;
  text-anchor: start !important;
}
