.KPI-filter-depl-name{
  text-transform: capitalize;

}

.KPI-filter-depl-circle{
  height:20px;
  width:20px;
  border-radius:20px;
  background: white;
  display:inline-block;
  position:relative;
  margin-right: 5px;
  top:4px;
  border: 1px solid rgb(130,130,130);
}

.KPI-filter-depl{
  cursor: pointer;
  &:hover{
    color:white;
    .KPI-filter-depl-circle{
      border: 1px solid white;
      top:4px
    }
  }
}

.circle-off{
  background:rgba(0,0,0,0) !important;
}
