@import "../../containers/variables";

#sankey-interference {
  color: $white;

  .node rect {
    cursor: move;
    fill-opacity: 0.9;
    shape-rendering: crispEdges;
  }

  .title {
    pointer-events: none;
    fill: $white;
    /* text-shadow: 0 1px 0 #fff; */
  }

  .link {
    fill: none;
    stroke: $white;
    stroke-opacity: 0.3;
    transition: stroke-opacity 0.25s;
    &:hover{
      stroke-opacity: 0.7;
    }
  }

  p {
    margin: 0;
    padding: 0;
  }
  sup {
    font-size: 9px;
  }

  #controls {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 99;
  }
  #status {
    color: $white;
    margin-bottom: 5px;
  }
  .button {
    background: rgba(255, 255, 255, 0.5);
    padding: 5px 20px;
    border-radius: 20px;
    cursor: pointer;
    text-align: center;
  }
}
