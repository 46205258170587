@import "../containers/variables";

#controlsDrawer {
  position: absolute;
  width: 100%;
  z-index: 1;
  top: 0;
  height: 50px;

  #controls {
    padding: 20px;
    transform: translateY(-100%);
    opacity: 0;
    transition: all 0.3s ease-in;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  #controlsIndicator {
    opacity: 1;
    transition: all 0.3s ease-in;
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translate(-50%, 0%);
    width: 100px;
    height: 20px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 20px;
    font-size: 10px;
    color: $white;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  #sliderWrap {
    margin: 10px;
  }
  .inputs-parent {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .control {
    border: 1px solid rgba(255, 255, 255, 0.3);
    color: #fff;
    font-size: 14px;
    padding: 12px 20px;
    position: relative;
    text-align: center;
    z-index: 1;
    background: $grey_22;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .control:first-child {
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
  }
  .control:last-child {
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
  }
  .control.active {
    background: rgba(255, 255, 255, 0.3);
  }
  .control * {
    cursor: pointer;
  }
  select {
    background-color: $grey_22;
    color: $white;
    border: none;
    outline: none;

    option {
      background: transparent;
    }
  }

  /* SWITCH CHECKBOX */
  .switch {
    position: relative;
    display: block;
    width: 52px;
    height: 26px;
    margin-top: 8px;

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 52px;
      background-color: $grey_cc;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      &:before {
        position: absolute;
        content: "";
        height: 18px;
        width: 18px;
        left: 4px;
        bottom: 4px;
        background-color: $white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }
    }

    input {
      &:checked + .slider {
        background-color: #2abb42;
      }
      &:focus + .slider {
        box-shadow: 0 0 1px #2abb42;
      }
      &:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
      }
    }

    /* Rounded sliders */
    .slider.round {
      border-radius: 26px;
      &:before {
        border-radius: 50%;
      }
    }
  }

  .switch-label {
    display: block;
    // padding: 0px 5px;
    font-size: 12px;
    color: $white;
  }
}

#controlsDrawer:hover {
  #controls {
    transform: translateY(0%);
    opacity: 1;
  }
  #controlsIndicator {
    transform: translate(-50%, -100%);
    opacity: 0;
  }
}
