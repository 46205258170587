.slider-container{

  .slider-value{
    display:inline-block;
    vertical-align: top;
    }

    input{
      display:inline-block;
      cursor:pointer;
      width: calc(100% - 50px);
      height: 3px;
      position:relative;
      bottom:3px;
    }
}
