.metric-correlations {
  #titleText {
    font-size: 24px;
    position: absolute;
    top: 45px;
    left: 50%;
    transform: translateX(-50%);
  }
  #subtitleText {
    font-size: 18px;
    position: absolute;
    top: 75px;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
  }
  // #titleHeader {
  //   padding-top: 2px;
  //   padding-left: 20px;
  // }
  //
  // #title {
  //   position: absolute;
  //   left: 50%;
  //   margin: 5px;
  //   transform: translateX(-50%);
  // }
  text{
    fill: #fff;
  }

  .datumSelectorWrap {
    &.non-pointer-events {
      pointer-events: none;
    }
  }

  .deploymentButtonWrap {
    opacity: 1.5;
    transition: all 0.3s ease-out;
    &:hover {
      opacity: 0.8;
    }
    &.collapsed {
      pointer-events: none;
      transform: translate(0, 0) !important;
      opacity: 0;
    }
    &.active {
      pointer-events: all;
      opacity: 1;
    }
    text {
      alignment-baseline: middle;
      text-anchor: middle;
      pointer-events: none;
      font-size: 10px;
    }
  }

  .dimensionButton {
    transition: all 0.25s;
    opacity: 0.3;
    cursor: pointer;
    &:hover {
      opacity: 0.5;
    }
    &.active {
      opacity: 1;
      cursor: default;
    }
  }

  $labelOffset: 20px;
  $baseFontSize: 16px;
  $highlightMultiplier: 1.5;

  .datumSelector{
    font-size: $baseFontSize * $highlightMultiplier;
    font-weight: 100;
    text-anchor: end;
    cursor: pointer;
    opacity: .25;
    transition: all .5s;
    transform: translate(-$labelOffset, 0);
    &.active{
      opacity: 1;
    }
  }
  .datumSelectorConnector{
    fill: none;
  }
  .mainMetricHeader{
    font-size: $baseFontSize * $highlightMultiplier;
    font-weight: 500;
    text-anchor: middle;
    transform: translate(0, -$labelOffset * $highlightMultiplier);
  }
  .mainMetricValue{
    font-size: $baseFontSize*2 * $highlightMultiplier;
    font-weight: 100;
    text-anchor: middle;
    transform: translate(0, $labelOffset * $highlightMultiplier);
  }
  .secondaryMetricHeader{
    font-size: $baseFontSize;
    font-weight: 500;
    transform: translate($labelOffset, -$labelOffset);
  }
  .secondaryMetricValue{
    font-size: $baseFontSize*2;
    font-weight: 100;
    transform: translate($labelOffset, $labelOffset);
  }
}
