.KPI-chart-filter-cont{
   color:rgb(175,175,175);
   // border: 1px solid rgb(175,175,175);
   position: absolute;
   width:200px;
   right:10px;
   background:rgb(35,35,35);
   opacity: 1;
   z-index:2000;
   padding:5px;
   border-radius: 10px;
}


.hidden-filter{
  display:none;
}
