@import "../../containers/variables";

#optimization-interference {
  color: $white;
  font-size: 10px;
  // canvas {
  //   /* border:1px solid #d3d3d3; */
  //   position: absolute;
  //   z-index: 0;
  // }
  /* slider */
  #sliderWrap {
    /* width: calc(100vw - 40px;) */
    padding: 0px 30px;
    height: 10px;
    position: relative;
  }
  .slider-container {
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 20px;
  }
  .slider {
    background-color: rgba(255, 255, 255, 0.5);
    /* border: 1px solid rgba(255,255,255,.8); */
    border-radius: 20px;
    position: absolute;
    border: 1px solid #aab;
    background: #bce;
    height: 100%;
    width: 58px;
    top: 0px;
    cursor: move;
    border: none;
    /* margin: -0.5px; */
    .handle {
      background-color: rgba(255, 255, 255, 0.8);
      border-radius: 9px;
      position: absolute;
      height: 9px;
      width: 9px;
      border: 1px solid #aab;
      background: #9ac;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
    }
    .WW {
      left: 2px;
      cursor: w-resize;
    }
    .EE {
      right: 2px;
      cursor: e-resize;
    }
  }

  .range-label {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 25px;
    &.low {
      left: 0px;
      text-align: right;
    }
    &.high {
      right: 0px;
    }
  }
}
