#launcher {
  h1 {
    text-align: center;
    font-weight: lighter;
    color: rgba(255, 255, 255, 0.7);
  }
  #logo {
    margin: 40px;
  }

  a {
    display: block;
    /* border: 1px solid #444; */
    border-radius: 10px;
    padding: 20px;
    margin: 20px;
    transition: all 0.25s;
    text-decoration: none;
    text-align: center;
    font-weight: lighter;
    text-align: center;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 200px;
    line-height: 24px;
  }
  a:link,
  a:visited {
    color: rgba(255, 255, 255, 0.7);
    transition: all 0.25s;
    background-color: rgba(255, 255, 255, 0.05);
  }
  a:hover,
  a:active {
    color: rgba(255, 255, 255, 1);
    /* border: 1px solid #fff; */
    background-color: rgba(255, 255, 255, 0.1);
    transform: scale(1.1);

    .thumbnail {
      box-shadow: 0px 0px 20px rgba(100, 100, 255, 0.5);
    }
  }
  .thumbnail {
    width: 196px;
    height: 196px;
    text-align: center;
    /* border: 1px solid #fff; */
    overflow: hidden;
    display: inline-block;
    border-radius: 100px;
    margin-bottom: 10px;
    background-image: url(/img/plume-logo-white.png);
    background-size: cover;
    background-position: center center;
    position: relative;
    // box-shadow: 0px 0px 20px rgba(100, 100, 255, 0.5);

    div {
      width: 204px;
      height: 204px;
      margin-left: -2px;
      margin-top: -2px;
      text-align: center;
      overflow: hidden;
      display: inline-block;
      border-radius: 100px;
      background-size: cover;
      background-position: center center;
    }
  }
}
#launcher > div {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
