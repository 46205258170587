/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 100;
  src: local('Raleway Thin'), local('Raleway-Thin'), url(https://fonts.gstatic.com/s/raleway/v12/1Ptsg8zYS_SKggPNwE44Q4FqL_KWxWMT.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 100;
  src: local('Raleway Thin'), local('Raleway-Thin'), url(https://fonts.gstatic.com/s/raleway/v12/1Ptsg8zYS_SKggPNwE44TYFqL_KWxQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'wigrum-light';
  font-style: normal;
  font-weight: 100;
  src: local('wigrum-light'), local('wigrum-light'), url(https://www.dropbox.com/sh/ndboz4pfijnjv44/AAC3hP7-dOkRrmgbIRS6oXbMa/wigrumweb-italic.woff?dl=0) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 200;
  src: local('Raleway ExtraLight'), local('Raleway-ExtraLight'), url(https://fonts.gstatic.com/s/raleway/v12/1Ptrg8zYS_SKggPNwOIpWqhPANqczVsq4A.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 200;
  src: local('Raleway ExtraLight'), local('Raleway-ExtraLight'), url(https://fonts.gstatic.com/s/raleway/v12/1Ptrg8zYS_SKggPNwOIpWqZPANqczVs.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 300;
  src: local('Raleway Light'), local('Raleway-Light'), url(https://fonts.gstatic.com/s/raleway/v12/1Ptrg8zYS_SKggPNwIYqWqhPANqczVsq4A.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 300;
  src: local('Raleway Light'), local('Raleway-Light'), url(https://fonts.gstatic.com/s/raleway/v12/1Ptrg8zYS_SKggPNwIYqWqZPANqczVs.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: local('Raleway'), local('Raleway-Regular'), url(https://fonts.gstatic.com/s/raleway/v12/1Ptug8zYS_SKggPNyCMIT4ttDfCmxA.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: local('Raleway'), local('Raleway-Regular'), url(https://fonts.gstatic.com/s/raleway/v12/1Ptug8zYS_SKggPNyC0IT4ttDfA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  src: local('Raleway Medium'), local('Raleway-Medium'), url(https://fonts.gstatic.com/s/raleway/v12/1Ptrg8zYS_SKggPNwN4rWqhPANqczVsq4A.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  src: local('Raleway Medium'), local('Raleway-Medium'), url(https://fonts.gstatic.com/s/raleway/v12/1Ptrg8zYS_SKggPNwN4rWqZPANqczVs.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  src: local('Raleway SemiBold'), local('Raleway-SemiBold'), url(https://fonts.gstatic.com/s/raleway/v12/1Ptrg8zYS_SKggPNwPIsWqhPANqczVsq4A.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  src: local('Raleway SemiBold'), local('Raleway-SemiBold'), url(https://fonts.gstatic.com/s/raleway/v12/1Ptrg8zYS_SKggPNwPIsWqZPANqczVs.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  src: local('Raleway Bold'), local('Raleway-Bold'), url(https://fonts.gstatic.com/s/raleway/v12/1Ptrg8zYS_SKggPNwJYtWqhPANqczVsq4A.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  src: local('Raleway Bold'), local('Raleway-Bold'), url(https://fonts.gstatic.com/s/raleway/v12/1Ptrg8zYS_SKggPNwJYtWqZPANqczVs.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 800;
  src: local('Raleway ExtraBold'), local('Raleway-ExtraBold'), url(https://fonts.gstatic.com/s/raleway/v12/1Ptrg8zYS_SKggPNwIouWqhPANqczVsq4A.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 800;
  src: local('Raleway ExtraBold'), local('Raleway-ExtraBold'), url(https://fonts.gstatic.com/s/raleway/v12/1Ptrg8zYS_SKggPNwIouWqZPANqczVs.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 900;
  src: local('Raleway Black'), local('Raleway-Black'), url(https://fonts.gstatic.com/s/raleway/v12/1Ptrg8zYS_SKggPNwK4vWqhPANqczVsq4A.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 900;
  src: local('Raleway Black'), local('Raleway-Black'), url(https://fonts.gstatic.com/s/raleway/v12/1Ptrg8zYS_SKggPNwK4vWqZPANqczVs.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

body {
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: "Raleway", sans-serif;
  /*-webkit-font-smoothing: antialiased;*/
  /*-moz-osx-font-smoothing: grayscale;*/


}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.time-slider-arrows{
   cursor:pointer;
   pointer-events: all;
   font-size:20px;
}

#left-menu-button{
  padding-left:30px;
}
#right-menu-button{
  padding-right:20px;
}

@charset "UTF-8";
/**
 * GLOBAL
 */
svg path, svg rect, svg circle {
  shape-rendering: geometricPrecision;
}

/**
 * PAGES
 */
/* GREY COLORS */
/* END OF GREY COLORS */
#animated-switch-div {
  position: absolute;
  right: 0;
  top: 40px;
  bottom: 0;
  width: 100%;
}

.login__wrapper {
  position: absolute;
  top: 0px;
}

/* RESPONSIVE SVG */
.svg-container {
  display: inline-block;
  position: relative;
  width: 100%;
  padding-bottom: 100%; /* aspect ratio */
  vertical-align: top;
  overflow: hidden;
}

.svg-content-responsive {
  display: inline-block;
  position: absolute;
  top: 10px;
  left: 0;
}

/* SLIDER START ========================================= */
#slider,
#slider3,
#rangeSlider {
  z-index: 2;
  color: white;
}
#slider .domain,
#slider3 .domain,
#rangeSlider .domain {
  stroke-width: 0;
}

#rangeSlider {
  flex-grow: 3;
  flex-basis: 75%;
}

#slider svg line.track {
  stroke-opacity: 0.4;
  stroke: black;
  stroke-width: 10px;
}
#slider svg line.track-inset {
  stroke: rgba(255, 255, 255, 0.5);
  stroke-width: 8px;
}
#slider svg line.track-overlay {
  stroke: #ccc;
  stroke-width: 40px;
  stroke-opacity: 0;
  cursor: pointer;
}
#slider svg .handle {
  fill: white;
  stroke: black;
  opacity: 1;
}
#slider svg line.track-overlay, #slider svg line.track, #slider svg line.track-inset {
  stroke-linecap: round;
}

#slider3 svg line.track,
#rangeSlider svg line.track {
  stroke-opacity: 0.4;
  stroke-width: 10px;
}
#slider3 svg line.track-inset,
#rangeSlider svg line.track-inset {
  stroke: rgba(255, 255, 255, 0.1);
  stroke-width: 9px;
}
#slider3 svg line.track-overlay,
#rangeSlider svg line.track-overlay {
  stroke: #333;
  stroke-width: 40px;
  stroke-opacity: 0;
  cursor: pointer;
}
#slider3 svg .handle,
#rangeSlider svg .handle {
  fill: white;
  opacity: 1;
}
#slider3 svg line.track-overlay, #slider3 svg line.track, #slider3 svg line.track-inset,
#rangeSlider svg line.track-overlay,
#rangeSlider svg line.track,
#rangeSlider svg line.track-inset {
  stroke-linecap: round;
}

.slider {
  position: relative;
  display: inline-block;
  top: 10px;
  z-index: 9999;
}
.slider.test {
  right: 150px;
}
.slider.count {
  right: 300px;
}
.slider input {
  cursor: pointer;
}
.slider > g.tick {
  display: none;
}
.slider .ticks {
  color: #fff;
}

/* ========================================= SLIDER END */
/* INFORMATION POPUP START ========================================= */
#info-popup {
  position: absolute;
  z-index: 2;
  width: 15%;
  min-height: 25px;
  color: white;
  text-align: right;
  background-repeat: no-repeat;
  background-position: 50% 0px;
}
#info-popup::after {
  height: 25px;
  width: 25px;
  background-image: url(/static/media/info.cfe90d37.svg);
  display: inline-block;
  content: " ";
  background-size: contain;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  opacity: 0.7;
  transition: all 0.25s;
}
#info-popup:hover::after {
  opacity: 1;
  height: 30px;
  width: 30px;
}
#info-popup .info-content {
  z-index: 1;
  position: relative;
  font-size: 16px;
  text-align: left;
  max-height: 0;
  line-height: 24px;
  transition: all 0.5s ease-in-out;
  opacity: 0;
  overflow: hidden;
  border-radius: 10px;
  background-image: linear-gradient(hsla(1, 0%, 0%, 0.7), hsla(1, 0%, 0%, 0.3));
}
#info-popup .info-content h4 {
  font-size: 24px;
  font-weight: 200;
  text-align: center;
  margin: 5px 0 0 0;
  padding: 10px;
  border-bottom: 1px solid hsla(1, 0%, 70%, 0.5);
}
#info-popup .info-content .infoContent {
  padding: 10px;
  margin: 0;
}
#info-popup .info-content .updateInfo {
  border-top: 1px solid hsla(1, 0%, 70%, 0.5);
  padding: 10px;
  opacity: 0.5;
}
#info-popup .info-content:hover {
  display: block;
}

#info-popup:hover .info-content,
.info-content:hover .info-content {
  opacity: 1;
  max-height: 1000px;
}

/* ========================================= INFO POPUP END */
/* NAVIGATION TAB START ========================================= */
#navigation-tab {
  background-color: hsl(1, 0%, 10%);
  background-image: url(/static/media/plume-logo-white.600ff421.svg);
  background-repeat: no-repeat;
  background-position: 50px 7.5px;
  background-size: 30px;
  box-shadow: 0px 0px 10px hsla(1, 0%, 0%, 0.3);
  width: 100%;
  padding: 0;
  margin: 0;
  align-items: center;
  top: 0;
  height: 40px;
  display: flex;
  position: fixed;
  right: 0px;
  left: 0px;
  z-index: 9999;
}
#navigation-tab > div:first-child {
  display: flex;
  color: white;
  flex: 1 1;
  padding-left: 100px;
  align-items: baseline;
}
#navigation-tab > div:last-child {
  display: flex;
  flex: 1 1;
  justify-content: flex-end;
  padding-right: 10px;
  position: relative;
}

.navSpan {
  cursor: pointer;
  color: #888da1;
  line-height: 2.67;
  transition: color 0.25s;
  z-index: 5000;
}
@media screen and (max-width: 992px) {
  .navSpan {
    display: none;
  }
}
.navSpan.active {
  color: #f0bc5e;
  cursor: default;
  margin-top: 4.5px;
}
.navSpan:hover:not(.active) {
  color: hsla(1, 0%, 80%, 0.9);
}

.chevron {
  margin: 0 10px;
  color: #888da1;
}
@media screen and (max-width: 992px) {
  .chevron {
    display: none;
  }
}

#userActions-popup {
  position: absolute;
  z-index: 999;
  min-height: 25px;
  top: -12px;
  color: white;
  text-align: left;
  justify-content: center;
  align-items: flex-end;
  display: inline-flex;
  flex-direction: column;
  width: 100%;
}
#userActions-popup #userActions-hover {
  width: 40%;
  position: relative;
}
#userActions-popup #toggle-menu-div {
  cursor: pointer;
}
#userActions-popup div {
  justify-content: flex-end;
  align-items: center;
  display: flex;
  white-space: nowrap;
}
#userActions-popup #toggle-menu-picture {
  display: inline-block;
  width: 28px;
  height: 28px;
  border-radius: 14px;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
  margin-left: 8px;
}
@media screen and (max-width: 992px) {
  #userActions-popup #toggle-menu-picture {
    display: none;
  }
}
#userActions-popup .userActions-content {
  position: absolute;
  top: 0px;
  margin-top: 35px;
  text-align: left;
}
#userActions-popup .userActions-content ul {
  list-style-type: none;
  padding: 5px;
  border-radius: 4px;
  background-color: #0c233a;
}
#userActions-popup .userActions-content ul li {
  display: flex;
  margin: 2.5px 0px;
  padding: 0 5px;
  text-align: center;
  border-radius: 4px;
  background-color: #0b2641;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  height: 40px;
}
#userActions-popup .userActions-content ul li button {
  background: transparent;
  border: none;
  outline: none;
  color: #d8e2eb;
  font-weight: 500;
  line-height: 2.46;
  cursor: pointer;
}
#userActions-popup .userActions-content ul li:first-child,
#userActions-popup .userActions-content ul li:last-child {
  margin: 0;
}
#userActions-popup .userActions-content ul li:last-child > button {
  color: #be5555;
}
#userActions-popup .userActions-content ul li:hover button {
  font-size: 14px;
}

#switch-client-select {
  border: none;
  outline: none;
  background: transparent;
  color: #fff;
  margin: 8px 0;
  cursor: pointer;
}
#switch-client-select option {
  padding: 8px;
  margin: 10px;
  background-color: #0b2641;
}

.offline__sign {
  color: #fff;
  position: absolute;
  top: 40px;
  height: 40px;
  background: rgba(255, 0, 0, 0.6);
  width: 100%;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  display: flex;
}

/* ========================================= NAVIGATION TAB END */
/* NOTIFICATION-POPUP START ========================================= */
.notification-popup {
  position: absolute;
  top: 5%;
  right: 5%;
  min-width: 200px;
  cursor: pointer;
  border-radius: 2px;
  font-size: 13px;
  margin: 10px 0px 0px;
  padding: 10px 20px 10px 10px;
  display: block;
  box-sizing: border-box;
  opacity: 1;
  transition: all 0.5s ease-in-out 0s;
  transform: translate3d(0px, 0px, 0px);
  will-change: transform, opacity;
  z-index: 9999;
}
.notification-popup h4 {
  font-size: 14px;
  margin: 0px 0px 5px;
  padding: 0px;
  font-weight: bold;
}
.notification-popup div {
  margin: 0px;
  padding: 0px;
}
.notification-popup span {
  font-family: Arial;
  font-size: 17px;
  position: absolute;
  top: 3px;
  right: 5px;
  line-height: 15px;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  font-weight: bold;
  text-align: center;
}

.success {
  border-top: 2px solid rgb(94, 164, 0);
  background-color: rgb(240, 245, 234);
  color: rgb(75, 88, 58);
  box-shadow: rgba(107, 107, 107, 0.9) 0px 0px 1px;
}
.success h4 {
  color: rgb(94, 164, 0);
}
.success span {
  background-color: rgb(176, 202, 146);
  color: rgb(240, 245, 234);
}

.error {
  border-top: 2px solid rgb(236, 61, 61);
  background-color: rgb(244, 233, 233);
  color: rgb(65, 47, 47);
  box-shadow: rgba(236, 61, 61, 0.9) 0px 0px 1px;
}
.error h4 {
  color: rgb(236, 61, 61);
}
.error span {
  background-color: rgb(228, 190, 190);
  color: rgb(244, 233, 233);
}

/* ========================================= NOTIFICATION-POPUP END */
/* LOADING SPINNER START ========================================= */
#spinner {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  transition: background-color 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);
  z-index: 10;
  background-color: rgba(0, 0, 0, 0);
}
#spinner.visible {
  background-color: rgba(0, 0, 0, 0.5);
}
#spinner g {
  transition: opacity 0.75s cubic-bezier(0.39, 0.575, 0.565, 1);
}
#spinner circle {
  stroke: red;
  overflow: visible;
  fill: none;
}
#spinner path {
  fill: white;
}

/* ========================================= LOADING SPINNER END */
/* MISCELANOUS */
.main-container {
  position: fixed;
  width: 100vw;
  min-height: calc(100vh - 40px);
  overflow: hidden;
  margin-top: 0px;
  background: linear-gradient(hsl(1, 0%, 0%), hsl(1, 0%, 20%));
  position: relative;
}

.flex-parent {
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
}

.flex-column,
.flex-parent {
  display: flex;
  flex-direction: column;
}

.flex {
  display: flex;
  flex: 1 1;
}
input:-webkit-autofill {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}

.timeseriesSwitch {
  position: absolute;
  top: 10px;
  right: 30px;
  width: 150px !important;
  z-index: 10;
}
.timeseriesSwitch input {
  width: 75px !important;
}

#errorWrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: red;
  font-size: larger;
}

/* HEADER TIME MENU */
#caretDrop {
  visibility: hidden;
  width: 33%;
  height: 40px;
  position: fixed;
  top: 0px;
  z-index: 10000;
  left: 50%;
  transform: translateX(-50%);
  color: rgba(255, 255, 255, 0.7);
  cursor: default;
  display: inline-flex;
}
#caretDrop.hidden {
  visibility: hidden;
}

#caretDrop:hover {
  color: white;
  cursor: pointer;
}

#menuTitle {
  position: absolute;
  width: 100%;
  top: 0px;
  text-align: center;
}

#menuGrain {
  top: 10px;
  text-align: right;
  position: absolute;
  width: 45%;
  font-size: 80%;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.5);
}

#menuRange {
  top: 10px;
  text-align: left;
  position: absolute;
  left: 55%;
  width: 45%;
  font-size: 80%;
  color: rgba(255, 255, 255, 0.5);
}

#menuCaret {
  top: 10px;
  position: absolute;
  text-align: center;
  display: inline-block;
  left: 45%;
  width: 10%;
  color: rgba(255, 255, 255, 0.7);
  height: 15px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAdCAYAAADoxT9SAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAQhJREFUeNrslmsNg0AQhDkHSEACDoqD4qCVgAQcIKESoAqKA3BAHSDhOpcsYdMAPejxSvZLJveDR2aPYcDzBEEQBMEBWusIukP+SfyGUDp0INU9LyiBgoOZj6EH1HRGfw3CMRdl5ontYDyglOQj3mYNwmlpR1aLYBcZqLLws3iQb5xEcCgytrgaZFEEbSKz5yCTEZwbmX8GiWiHWu2eZoV7Vrx+1VhmsVwgsx6pggvoCZVKqTc/oGxaBIvJ/Q0KNzZuzJbGPIwXUyeqme3i01O60nBrVHBNu17AfL3llzZz8A7kVAz7x5haKbFspYZaLT76D53PvhMtbxl65077Zx14giAIAuMjwAB7OQeg+lpvcgAAAABJRU5ErkJggg==);
  background-repeat: no-repeat;
  background-size: 40%;
  background-position: center center;
  opacity: 0.5;
  transition: all 0.25s;
}
#caretDrop:hover #menuCaret {
  opacity: 1;
  background-size: 60%;
}

#headerMenu {
  height: 70px;
  overflow: visible;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  text-align: left;
  color: white;
  padding: 10px 20px 10px 20px;
  background-image: linear-gradient(180deg, hsla(1, 5%, 20%, 0.8), hsla(1, 0%, 20%, 0.8));
  border-radius: 0 0 10px 10px;
  z-index: 10;
  transition: all 0.3s;
  box-shadow: 0 2px 5px hsla(1, 0%, 10%, 0.3);
  opacity: 1;
}
#headerMenu.hidden {
  visibility: hidden;
  transform: translate(-50%, -100%);
}
#headerMenu.open {
  background-image: linear-gradient(180deg, hsl(1, 5%, 20%), hsl(1, 0%, 10%));
  box-shadow: 0 2px 10px hsla(1, 0%, 10%, 0.7);
}
#headerMenu.open .range {
  max-height: 1000px;
  opacity: 1;
  margin: 5px 5px;
}
#headerMenu.open .range:first-of-type {
  margin-top: 0;
}
#headerMenu .range.active {
  min-height: 20px;
  max-height: 1000px;
  opacity: 1;
  color: white;
}
#headerMenu .range:hover .label {
  transform: scale(1.1);
}
#headerMenu .range.disabled {
  color: gray;
}
#headerMenu .range.disabled .label {
  cursor: default !important;
}
#headerMenu .range .label {
  transition: transform 0.25s;
  cursor: pointer;
  white-space: nowrap;
}
#headerMenu .range .nav {
  display: none;
  position: absolute;
  top: 0;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  background-color: hsla(1, 0%, 70%, 0);
  cursor: default;
  transition: background-color 0.25s ease;
  overflow: hidden;
}
#headerMenu .range .nav.prev {
  left: 0px;
}
#headerMenu .range .nav.next {
  right: 30px;
}
#headerMenu .range .nav.reset {
  right: 0px;
  text-align: center;
}
#headerMenu .range .nav::before {
  position: absolute;
  color: hsl(1, 0%, 90%);
  top: 10px;
  transform: translate(-50%, -50%);
  opacity: 0.3;
  transition: opacity 0.25s ease;
  font-size: 66%;
}
#headerMenu .range .nav.active::before {
  opacity: 1;
}
#headerMenu .range .nav.prev::before {
  content: "\25C0";
  left: 9px;
}
#headerMenu .range .nav.next::before {
  content: "\25B6";
  left: 11px;
}
#headerMenu .range .nav.reset::before {
  content: "\21E5";
  font-size: 130%;
  top: 10px;
  left: 6px;
}
#headerMenu .range.active .nav {
  background-color: hsla(1, 0%, 50%, 0.2);
  display: inline-block;
}
#headerMenu .range .nav.active {
  background-color: hsl(1, 0%, 50%);
  pointer-events: all;
  cursor: pointer;
}

#rangeArea {
  display: none;
  flex-basis: 25%;
  flex-grow: 1;
  background-color: #202020;
  border-radius: 1rem;
  padding: 0.25rem 0;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
}
#rangeArea.hidden {
  display: none;
}

#headerMenu .range .grain {
  width: 33%;
  padding: 0 0.5rem;
  font-weight: 500;
  text-align: right;
  overflow: hidden;
  margin-left: 16.5%;
  margin-right: 2rem;
}

#headerMenu .range .timeframe {
  width: 33%;
  padding: 0 0;
  font-weight: 100;
  text-align: left;
  white-space: nowrap;
}

#headerMenu .range {
  width: auto;
  display: flex;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  margin: 0px 5px;
  padding: 0 75px 0 25px;
  position: relative;
  text-align: right;
}

#headerMenu:hover #rangeSlider,
#rangeSlider:hover #rangeSlider {
  display: block;
}

.button-off {
  pointer-events: none;
}

#okta-sign-in.auth-container.main-container {
  position: absolute;
  left: 50%;
  z-index: 12;
  top: 20%;
  max-height: 450px;
  min-height: 0px;
  color: white !important;
}

#okta-sign-in.auth-container.main-container {
  color: white !important;
}

#okta-sign-in.auth-container .link:link, #okta-sign-in.auth-container .link:visited, #okta-sign-in.auth-container .link:hover, #okta-sign-in.auth-container .link:active {
  color: white !important;
}

#okta-sign-in.auth-container h2, #okta-sign-in.auth-container h3 {
  color: white !important;
}

.data-bubble-disclaimer text {
  text-align: left;
  text-anchor: start !important;
}

/* GREY COLORS */
/* END OF GREY COLORS */
#balloon-map path.feature {
  fill: none;
  stroke: hsl(1, 80%, 20%) !important;
  stroke-linejoin: round;
}
#balloon-map .cartogram {
  z-index: 0 !important;
}
#balloon-map .cartogram .feature {
  stroke: darkgrey;
  transition: fill-opacity 0.7s;
  cursor: pointer;
}
#balloon-map .cartogram .feature:hover {
  fill-opacity: 0.6;
  transition: fill-opacity 0.1s;
}
#balloon-map .cartogram-tooltip {
  display: none;
  position: absolute;
  max-width: 320px;
  margin-top: 20px;
  margin-left: 8px;
  padding: 5px;
  border-radius: 3px;
  font: 11px sans-serif;
  color: #eee;
  background: rgba(0, 0, 0, 0.65);
  pointer-events: none;
}

/* GREY COLORS */
/* END OF GREY COLORS */
#choropleth-nodes {
  /* .countryPath:hover ~ .countryMetadata{
    opacity: 1;
  } */
}
#choropleth-nodes .countryPath {
  /* fill: none; */
  stroke: hsl(1, 80%, 20%);
  stroke-linejoin: round;
}
#choropleth-nodes .countryMetadata {
  pointer-events: none;
  text-anchor: middle;
  fill: hsl(1, 90%, 90%);
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 24px;
}
#choropleth-nodes .countryMetadata.activated {
  opacity: 1;
}
#choropleth-nodes .countryFlag {
  opacity: 0.5;
}
#choropleth-nodes .legendThreshold {
  font-size: 12px;
  font-family: sans-serif;
  text-transform: capitalize;
}
#choropleth-nodes .caption {
  fill: white;
  text-anchor: start;
  font-weight: bold;
}
#choropleth-nodes .label {
  fill: white;
}

/* GREY COLORS */
/* END OF GREY COLORS */
#choropleth-zoom {
  /* .countryPath:hover ~ .countryMetadata{
    opacity: 1;
  } */
}
#choropleth-zoom .countryPath {
  /* fill: none; */
  stroke-linejoin: round;
  opacity: 0.7;
}
#choropleth-zoom .countryPathOutline {
  fill: none;
  stroke: hsl(1, 50%, 20%);
}
#choropleth-zoom .countryMetadataLabel {
  transition: all 0.3s;
}
#choropleth-zoom .countryMetadata {
  pointer-events: none;
  text-anchor: middle;
  alignment-baseline: middle;
  fill: hsl(1, 90%, 90%);
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 24px;
}
#choropleth-zoom .countryMetadata.showAnyway {
  opacity: 1;
}
#choropleth-zoom .countryMetadata.showAnyway .countryMetadataFields {
  opacity: 0;
}
#choropleth-zoom .countryMetadata.showAnyway .countryMetadataLabel {
  transform: translateY(-10);
  font-size: 18px;
}
#choropleth-zoom .countryMetadata text {
  text-anchor: middle;
}
#choropleth-zoom .countryMetadata .countryMetadataFields {
  transition: all 0.3s;
  opacity: 0.7;
  font-size: 14px;
}
#choropleth-zoom .countryMetadata.activated {
  opacity: 1;
}
#choropleth-zoom .countryMetadata.activated .countryMetadataFields {
  opacity: 0.7;
}
#choropleth-zoom .countryMetadata.activated .countryMetadataLabel {
  transform: translateY(0);
  font-size: inherit;
}
#choropleth-zoom .countryFlag {
  opacity: 0.5;
}
#choropleth-zoom .countryFlag.activated {
  transform: translate(5px, -10px);
  opacity: 1;
}
#choropleth-zoom .countryBar.activated {
  transform: translateY(-20px);
}
#choropleth-zoom .legendThreshold {
  font-size: 12px;
  font-family: sans-serif;
  text-transform: capitalize;
}
#choropleth-zoom .caption {
  fill: white;
  text-anchor: start;
  font-weight: bold;
}
#choropleth-zoom .label {
  fill: white;
  min-width: 150px;
}
#choropleth-zoom .legendText {
  fill: white;
  color: white;
}
#choropleth-zoom svg {
  z-index: 1;
}
#choropleth-zoom svg.spinner {
  z-index: 1000;
}
#choropleth-zoom .toggleButton {
  transition: all 0.25s;
  opacity: 0.3;
  cursor: pointer;
}
#choropleth-zoom .toggleButton:hover {
  opacity: 0.5;
}
#choropleth-zoom .toggleButton.active {
  opacity: 1;
  cursor: default;
}
#choropleth-zoom .toggleButtonImg {
  transition: all 0.25s;
  opacity: 0.3;
  pointer-events: none;
}
#choropleth-zoom .toggleButtonImg.active {
  opacity: 1;
  cursor: default;
}
#choropleth-zoom .dimensionButton {
  transition: all 0.25s;
  opacity: 0.3;
  cursor: pointer;
}
#choropleth-zoom .dimensionButton:hover {
  opacity: 0.5;
}
#choropleth-zoom .dimensionButton.active {
  opacity: 1;
  cursor: default;
}
#choropleth-zoom .dimensionButtonImg {
  transition: all 0.25s;
  opacity: 0.3;
  pointer-events: none;
}
#choropleth-zoom .dimensionButtonImg.active {
  opacity: 1;
  cursor: default;
}
#choropleth-zoom .deploymentButtonWrap {
  opacity: 0.5;
  transition: all 0.3s ease-out;
}
#choropleth-zoom .deploymentButtonWrap:hover {
  opacity: 0.8;
}
#choropleth-zoom .deploymentButtonWrap.collapsed {
  pointer-events: none;
  transform: translate(0, 0) !important;
  opacity: 0;
}
#choropleth-zoom .deploymentButtonWrap.active {
  pointer-events: all;
  opacity: 1;
}
#choropleth-zoom .deploymentButtonWrap text {
  alignment-baseline: middle;
  text-anchor: middle;
  pointer-events: none;
  font-size: 10px;
}
#choropleth-zoom #title-text {
  font-size: 24px;
  font-weight: 100;
  text-anchor: middle;
}
#choropleth-zoom div.tooltip {
  position: absolute;
  text-align: center;
  padding: 20px 30px;
  background: hsla(1, 100%, 90%, 0.8);
  border: 0px;
  pointer-events: none;
  z-index: 1;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.4);
  width: 300px;
}
#choropleth-zoom div.tooltip .tooltipEnv {
  font-size: 24px;
  font-weight: 500;
  text-transform: capitalize;
}
#choropleth-zoom div.tooltip .tooltipLabel {
  font-size: 16px;
  margin: 10px 0px;
}
#choropleth-zoom div.tooltip .tooltipVal {
  font-size: 18px;
  font-weight: 500;
  text-align: left;
}
#choropleth-zoom div.tooltip img {
  position: absolute;
  width: 60px;
  height: 30px;
  bottom: 25px;
  right: 30px;
}

/* GREY COLORS */
/* END OF GREY COLORS */
.composite-module {
  font-family: "Raleway", sans-serif;
}
.composite-module #titleText {
  font-size: 24px;
}
.composite-module #legend {
  position: absolute;
  top: 100vh;
  left: 0px;
  padding: 20px;
  transform: translateY(-100%);
  font-size: 14px;
  line-height: 32px;
}
.composite-module .legend {
  text-anchor: end;
}
.composite-module .legend text {
  transition: all 0.25s;
  opacity: 0.7;
  text-transform: capitalize;
  fill: rgb(255, 255, 255);
}
.composite-module .legend text.activated {
  opacity: 1;
  font-size: 120%;
  transform: translateX(-10px);
}
.composite-module .legendRow {
  cursor: pointer;
}
.composite-module .legend-rect {
  transform: opacity 0.25s;
}
.composite-module .legend-rect.dimmed {
  opacity: 0.25;
}
.composite-module #timestamp {
  color: #999;
}
.composite-module .tickLeft {
  color: white;
  font-weight: 300;
  font-size: 16px;
  opacity: 0.7;
}
.composite-module .tickLeft line {
  stroke-dasharray: 1, 4;
  stroke-width: 1;
  stroke-linecap: "round";
  stroke: rgba(255, 255, 255, 0.7);
}
.composite-module .tickHighlight {
  font-weight: 600;
  opacity: 1 !important;
}
.composite-module .tickBottom {
  color: white;
  font-weight: 300;
  font-size: 14px;
}
.composite-module .tickBottom text {
  color: white;
  opacity: 0.7;
}
.composite-module .tickBottom .tick:nth-child(even) line {
  transform: scaleY(4);
}
.composite-module .tickBottom .tick:nth-child(even) text {
  transform: translateY(20px) !important;
}
.composite-module svg {
  shape-rendering: crispEdges;
  margin-top: 10px;
  margin-left: 25px;
}
.composite-module .axis path,
.composite-module .axis line {
  fill: none;
  stroke: lightgray;
}
.composite-module path.domain {
  stroke: none;
}
.composite-module #chartTypeSelector {
  visibility: hidden;
}
.composite-module .navButton {
  padding: 0 10px;
  margin: 5px;
  line-height: 20px;
  height: 20px;
  border-radius: 30px;
  background: #999;
  box-sizing: border-box;
  border: none;
  color: white;
  z-index: 100;
  outline: none;
  display: inline-block;
}
.composite-module .navButton:hover, .composite-module .navButton:active {
  cursor: pointer;
  background: #ccc;
}
.composite-module .navButton:disabled {
  cursor: default;
  background: #333;
}
.composite-module .displayRange {
  color: rgba(255, 255, 255, 0.7);
  font-size: 80%;
}
.composite-module #titleHeader {
  padding-top: 2px;
  padding-left: 20px;
}
.composite-module #title {
  position: absolute;
  top: 0px;
  left: 0px;
  text-align: center;
  width: 100vw;
  pointer-events: none;
}
.composite-module .bar {
  transition: all 0.25s;
  stroke-width: 1;
  opacity: 1 !important;
}
.composite-module .bar.active {
  stroke-width: 1px;
  stroke: lightgrey;
}
.composite-module .bar.inactive {
  opacity: 0.3 !important;
}
.composite-module div.tooltip {
  position: absolute;
  text-align: center;
  padding: 20px 30px;
  background: hsla(1, 100%, 90%, 0.8);
  border: 0px;
  pointer-events: none;
  transform: translate(-50%, -150%);
  z-index: 1;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.4);
}
.composite-module div.tooltip .tooltipEnv {
  font-size: 21px;
  font-weight: 500;
  text-transform: capitalize;
}
.composite-module div.tooltip .tooltipLabel {
  font-size: 16px;
}
.composite-module div.tooltip .tooltipVal {
  font-size: 28px;
}

.radioBg {
  width: 120px;
  height: 20px;
  background-color: #333;
  display: inline-block;
  margin: 5px;
  border-radius: 30px;
}
.radioBg:hover {
  background-color: #666;
  cursor: pointer;
}

.axisSelector {
  display: flex;
  height: 20px;
}
.axisSelector .input {
  border: none;
  border-bottom: 1.5px solid #6665ad;
  font-size: 14px;
  display: block;
  width: 89%;
  float: left;
  height: 20px;
  outline: 0;
  background-color: transparent;
  color: rgba(255, 255, 255, 0.7);
}
.axisSelector #yMaxAxisSelector {
  display: flex;
  flex-direction: row;
  width: 20%;
}
.axisSelector #yMaxAxisSelector .input-div {
  width: 13%;
  margin-top: 6px;
  margin-left: 20px;
}
.axisSelector #yMaxAxisSelector label {
  color: white;
  font-size: 14px;
  margin-top: 15px;
}
.axisSelector .resetButton {
  top: 10px;
  left: 20px;
  pointer-events: all;
}
.axisSelector .resetButton:hover {
  background-color: #666;
  cursor: pointer;
}
.axisSelector #xRangeAxisSelector {
  display: flex;
}
.axisSelector #xRangeAxisSelector label {
  color: white;
  font-size: 14px;
  margin-top: 15px;
}
.axisSelector #xRangeAxisSelector #toDate {
  position: relative;
  left: 14px;
}
.axisSelector #xRangeAxisSelector .input-div {
  top: 10px;
  position: relative;
  left: 10px;
}
.axisSelector #xRangeAxisSelector #resetButton {
  top: 10px;
  position: relative;
  left: 45px;
}
.axisSelector #xRangeAxisSelector div:nth-child(4) {
  left: 30px !important;
}

.radioBt {
  width: 60px;
  height: 20px;
  background: #999;
  color: white;
  border: none;
  padding: 0px;
  margin: 0px;
  border-radius: 30px;
  pointer-events: none;
  position: relative;
  top: -1px;
}

.radioDisable {
  padding: 0px;
  width: 60px;
  height: 20px;
  background: inherit;
  color: #999;
  border: none;
  margin: 0px;
  border-radius: 30px;
  pointer-events: none;
  position: relative;
  top: -1px;
}

.metric-correlations #titleText {
  font-size: 24px;
  position: absolute;
  top: 45px;
  left: 50%;
  transform: translateX(-50%);
}
.metric-correlations #subtitleText {
  font-size: 18px;
  position: absolute;
  top: 75px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
}
.metric-correlations text {
  fill: #fff;
}
.metric-correlations .datumSelectorWrap.non-pointer-events {
  pointer-events: none;
}
.metric-correlations .deploymentButtonWrap {
  opacity: 1.5;
  transition: all 0.3s ease-out;
}
.metric-correlations .deploymentButtonWrap:hover {
  opacity: 0.8;
}
.metric-correlations .deploymentButtonWrap.collapsed {
  pointer-events: none;
  transform: translate(0, 0) !important;
  opacity: 0;
}
.metric-correlations .deploymentButtonWrap.active {
  pointer-events: all;
  opacity: 1;
}
.metric-correlations .deploymentButtonWrap text {
  alignment-baseline: middle;
  text-anchor: middle;
  pointer-events: none;
  font-size: 10px;
}
.metric-correlations .dimensionButton {
  transition: all 0.25s;
  opacity: 0.3;
  cursor: pointer;
}
.metric-correlations .dimensionButton:hover {
  opacity: 0.5;
}
.metric-correlations .dimensionButton.active {
  opacity: 1;
  cursor: default;
}
.metric-correlations .datumSelector {
  font-size: 24px;
  font-weight: 100;
  text-anchor: end;
  cursor: pointer;
  opacity: 0.25;
  transition: all 0.5s;
  transform: translate(-20px, 0);
}
.metric-correlations .datumSelector.active {
  opacity: 1;
}
.metric-correlations .datumSelectorConnector {
  fill: none;
}
.metric-correlations .mainMetricHeader {
  font-size: 24px;
  font-weight: 500;
  text-anchor: middle;
  transform: translate(0, -30px);
}
.metric-correlations .mainMetricValue {
  font-size: 48px;
  font-weight: 100;
  text-anchor: middle;
  transform: translate(0, 30px);
}
.metric-correlations .secondaryMetricHeader {
  font-size: 16px;
  font-weight: 500;
  transform: translate(20px, -20px);
}
.metric-correlations .secondaryMetricValue {
  font-size: 32px;
  font-weight: 100;
  transform: translate(20px, 20px);
}

/* GREY COLORS */
/* END OF GREY COLORS */
#devices-bubbles {
  /* controls */
}
#devices-bubbles text {
  text-anchor: middle;
  fill: white;
  alignment-baseline: middle;
  transition: all 0.5s;
  pointer-events: none;
  font-size: 12px;
}
#devices-bubbles text.activated {
  opacity: 1 !important;
}
#devices-bubbles text.activated.label {
  font-size: 24px;
}
#devices-bubbles text.activated.count {
  font-size: 18px;
  /* opacity: .7; */
}
#devices-bubbles circle {
  transition: opacity 0.5s;
  opacity: 0.6;
}
#devices-bubbles circle.exiting {
  fill: #b26745;
}
#devices-bubbles .circleActivated > circle {
  opacity: 0.3;
}
#devices-bubbles .circleActivated > circle.activated {
  opacity: 1;
}
#devices-bubbles #stats {
  position: absolute;
  top: 20px;
  right: 20px;
  color: white;
  text-align: right;
}
#devices-bubbles #stats > .stat {
  font-size: 24px;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 20px;
}
#devices-bubbles #stats > .stat > div {
  font-size: 64px;
  color: rgb(255, 255, 255);
}
#devices-bubbles #controls {
  position: absolute;
  top: 20px;
  left: 20px;
  color: white;
  z-index: 2;
}
#devices-bubbles button {
  padding: 0px 10px;
  background: hsla(1, 40%, 40%, 0.9);
  /* border: 1px solid rgba(255,255,255,.5); */
  color: white;
  border: none;
  border-radius: 20px;
  margin: 0px;
  outline: none;
  transition: opacity 0.25s;
  cursor: pointer;
}
#devices-bubbles button.filterReset {
  opacity: 0;
  margin-left: 5px;
  pointer: cursor;
}
#devices-bubbles button.filterReset.visible {
  opacity: 1;
}
#devices-bubbles button.filterReset.disabled {
  display: none;
}
#devices-bubbles button.deployment {
  text-transform: capitalize;
  opacity: 0.5;
}
#devices-bubbles button.deployment.activated {
  opacity: 1;
}
#devices-bubbles line.track {
  stroke: none;
}
#devices-bubbles line.track-inset {
  stroke: rgba(255, 255, 255, 0.3);
}
#devices-bubbles .parameter-value path {
  stroke: none;
}
#devices-bubbles #filterControls {
  position: absolute;
  z-index: 3;
  top: 20px;
  left: 20px;
}
#devices-bubbles #filterControls .filterRow {
  display: flex;
  flex-wrap: nowrap;
  color: white;
  background: hsla(1, 30%, 90%, 0.1);
  margin: 0 0 5px 0;
  border-radius: 20px;
  padding: 5px 10px;
  display: flex;
  transition: all 0.25s;
}
#devices-bubbles #filterControls .filterRow.rowActive {
  background: hsla(1, 40%, 40%, 0.9);
  margin-left: -10px;
  margin-right: -10px;
}
#devices-bubbles #filterControls .filterRow.rowActive .filterLabel {
  font-weight: bold;
}
#devices-bubbles #filterControls .filterLabel {
  flex-grow: 1;
  padding-right: 10px;
  text-transform: capitalize;
}
#devices-bubbles #filterControls .filterSelect {
  flex-grow: 2;
  transition: opacity 0.5s;
  max-width: 200px;
}
#devices-bubbles #filterControls .filterSelect.disabled {
  display: none;
}
#devices-bubbles .search {
  width: 100%;
  border-radius: 5px;
}
#devices-bubbles .filterRow .disabled {
  display: none;
}
#devices-bubbles .filterRow .visible {
  opacity: 1;
}
#devices-bubbles #search-button {
  width: 20%;
}
#devices-bubbles #search-input {
  margin-right: 10px;
}

/* GREY COLORS */
/* END OF GREY COLORS */
.login__page {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
  background: #222;
  height: 100vh;
  position: relative;
  z-index: 10;
  overflow: hidden;
}
.login__page::after {
  content: "";
  height: 100vh;
  width: calc(50vw + 40px);
  background-color: rgba(0, 0, 0, 0.8);
  pointer-events: none;
  position: absolute;
  top: 0;
  left: calc(50% - 40px);
  display: block;
  z-index: 10;
}
.login__page #background {
  position: relative;
  z-index: -1;
}
.login__page canvas {
  width: 100%;
  height: 100vh;
}
.login__page canvas,
.login__page svg {
  position: relative;
}
.login__page .form__login {
  position: absolute;
  left: 50%;
  z-index: 12;
  pointer-events: none;
}
.login__page .form__login img {
  width: 30%;
}
.login__page .form__login h1 {
  font-size: 60px;
  color: white;
  line-height: 1.18;
  margin: 4% 0;
}
.login__page .form__login .login__input {
  pointer-events: all;
  width: 50%;
  margin: 2% 0;
  color: rgba(255, 255, 255, 0.6);
}
.login__page .form__login .login__input input:disabled,
.login__page .form__login .login__input button:disabled {
  cursor: no-drop;
}
.login__page .form__login .login__input label {
  font-size: 12px;
  margin-bottom: 10px;
}
.login__page .form__login .login__input input {
  display: block;
  border: none;
  border-bottom: 1.5px solid #6665ad;
  font-size: 14px;
  display: block;
  width: 125%;
  height: 40px;
  outline: 0;
  background-color: transparent;
  color: rgba(255, 255, 255, 0.7);
  z-index: 1;
}
.login__page .form__login input,
.login__page .form__login button {
  pointer-events: all;
}
.login__page .form__login .forgotPassword {
  pointer-events: all;
  display: block;
  line-height: 1.2;
  background: transparent;
  outline: 0;
  border: none;
  color: white;
  text-decoration: underline;
  font-size: 14px;
  padding: 0;
  margin: 10px 0px;
  cursor: pointer;
  z-index: 9999;
}
.login__page .form__login .forgotPassword:hover {
  color: #6665ad;
}
.login__page .form__login button[type=submit] {
  pointer-events: all;
  font-size: 14px;
  color: white;
  line-height: 1.2;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  width: 120px;
  height: 50px;
  background-color: #1fadbf;
  cursor: pointer;
  border: none;
  border-radius: 25px;
  outline: 0;
  transition: all 0.4s;
  background-image: url(/static/media/arrowRight.0e472b46.svg);
  background-repeat: no-repeat;
  background-position: 80% 45%;
  background-size: 13px;
  text-align: left;
  padding-left: 30px;
}
.login__page .form__login button[type=submit]:hover {
  letter-spacing: 0.7px;
  background-color: #1eedf7;
}
.login__page .form__login .alert--danger {
  margin: 15px 0px;
  color: rgb(238, 59, 59);
}

/* GREY COLORS */
/* END OF GREY COLORS */
#map-channel {
  /* .land-boundary {
    stroke-width: 1px;
  } */
  /* .county-boundary {
    stroke: #ddd;
  } */
}
#map-channel .stage {
  /* height: 100vh !important;
  width: 100vw !important; */
  /* overflow: hidden; */
}
#map-channel .overlay {
  fill: none;
  pointer-events: all;
}
#map-channel path {
  fill: #222;
  /* fill: rgb(0,0,128); */
  stroke: #444;
  /* stroke: rgb(0,0,50); */
  stroke-width: 0.5px;
}
#map-channel .location {
  /* stroke-width: .5px; */
  /* stroke: rgba(255,255,255,.3); */
  /* fill: none; */
  fill: rgba(255, 255, 255, 0.3);
}
#map-channel .badOpt {
  stroke-width: 0.5px;
  /* stroke: #333; */
  fill: rgba(255, 100, 100, 0.2);
  stroke: rgba(255, 100, 100, 0.7);
  /* animation: pulse-me 3s linear infinite; */
}
#map-channel .goodOpt {
  stroke-width: 0.5px;
  /* stroke: #333; */
  fill: rgba(100, 255, 100, 0.2);
  stroke: rgba(100, 255, 100, 0.7);
  /* animation: pulse-me 3s linear infinite; */
}
#map-channel .onlineLocation {
  /* stroke-width: .5px; */
  /* stroke: rgba(255,255,255,.3); */
  /* fill: none; */
  fill: rgba(255, 255, 255, 0.1);
  stroke: rgba(255, 255, 255, 0.5);
}
#map-channel .offlineLocation {
  /* stroke-width: .5px; */
  /* stroke: #333; */
  fill: rgb(255, 0, 1);
  stroke: rgba(255, 0, 0, 0.5);
}
#map-channel .fastLoopCC {
  stroke-width: 0.5px;
  /* stroke: #333; */
  fill: rgba(100, 100, 255, 0.1);
  stroke: rgba(100, 100, 255, 0.5);
  /* animation: pulse-me 3s linear infinite; */
}
#map-channel .scheduledCC {
  stroke-width: 0.5px;
  /* stroke: #333; */
  fill: rgba(100, 255, 100, 0.1);
  stroke: rgba(100, 255, 100, 0.5);
  /* animation: pulse-me 3s linear infinite; */
}
#map-channel #slider3 {
  position: absolute;
  top: 28px;
  left: 50%;
  transform: translateX(-50%);
  /* margin: 0px auto 0px auto; */
  background: transparent;
  border-radius: 10px;
  border: none;
  color: white;
}
#map-channel #slider3 svg {
  width: 900px;
}
@keyframes pulse-me {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    opacity: 0.1;
  }
  70% {
    opacity: 0.09;
  }
  100% {
    transform: scale(5);
    opacity: 0;
  }
}
#map-channel #togglePlayButton {
  position: absolute;
  top: 30px;
  left: -50px;
  padding: 0 20px;
  line-height: 20px;
  height: 20px;
  border-radius: 30px;
  background: #999;
  box-sizing: border-box;
  border: none;
  color: white;
  outline: none;
  z-index: 2;
}
#map-channel #togglePlayButton:hover, #map-channel #togglePlayButton:active {
  cursor: pointer;
  background: #ccc;
}
#map-channel .pauseGap {
  display: inline-block;
  width: 1px;
}
#map-channel #legend {
  position: absolute;
  top: 100%;
  left: 0px;
  padding: 20px;
  transform: translateY(-100%);
  color: #666;
  font-size: 14px;
  line-height: 32px;
}
#map-channel .legendRow svg {
  display: inline-block;
  height: 16px;
  width: 16px;
  position: relative;
  top: 3px;
}
#map-channel #timestamp {
  color: #999;
}

/* GREY COLORS */
/* END OF GREY COLORS */
body.invert {
  background: rgb(255, 255, 255);
}

#dataviz-menu * {
  box-sizing: border-box;
}
#dataviz-menu .svgStage {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vh;
}
#dataviz-menu #menuV1 {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  overflow: hidden;
  padding: 0 40px;
  /* flex child properties */
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  /* end flex child properties */
  /* flex parent properties */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  align-content: stretch;
  /* end flex parent properties */
}
#dataviz-menu #menuV1 .backgroundRed {
  background-image: linear-gradient(150deg, rgba(61, 44, 41, 0.1), rgba(122, 109, 82, 0.3));
  border-color: rgb(217, 68, 38);
}
#dataviz-menu #menuV1 .backgroundYellow {
  background-image: linear-gradient(150deg, rgba(61, 60, 41, 0.1), rgba(105, 122, 82, 0.3));
  border-color: rgb(217, 202, 38);
}
#dataviz-menu #menuV1 .backgroundGreenLight {
  background-image: linear-gradient(150deg, rgba(48, 61, 41, 0.1), rgba(82, 122, 88, 0.3));
  border-color: rgb(98, 217, 38);
}
#dataviz-menu #menuV1 .backgroundGreen {
  background-image: linear-gradient(150deg, rgba(41, 61, 49, 0.1), rgba(82, 122, 119, 0.3));
  border-color: rgb(38, 217, 113);
}
#dataviz-menu #menuV1 .backgroundBlueLight {
  background-image: linear-gradient(150deg, rgba(41, 58, 61, 0.1), rgba(82, 95, 122, 0.3));
  border-color: rgb(38, 187, 217);
}
#dataviz-menu #menuV1 .backgroundBlue {
  background-image: linear-gradient(150deg, rgba(41, 43, 61, 0.1), rgba(99, 82, 122, 0.3));
  border-color: rgb(38, 53, 217);
}
#dataviz-menu #menuV1 .backgroundOrange {
  background-image: linear-gradient(150deg, rgba(54, 41, 10, 0.1), rgba(115, 65, 10, 0.25));
  border-color: rgb(255, 185, 45);
}
#dataviz-menu #menuV1 .backgroundPurple {
  background-image: linear-gradient(150deg, rgba(54, 41, 61, 0.1), rgba(122, 82, 116, 0.3));
  border-color: rgb(157, 38, 217);
}
#dataviz-menu #menuV1 .titleRed {
  color: rgb(255, 85, 51);
}
#dataviz-menu #menuV1 .titleYellow {
  color: rgb(255, 238, 51);
}
#dataviz-menu #menuV1 .titleGreenLight {
  color: rgb(119, 255, 51);
}
#dataviz-menu #menuV1 .titleGreen {
  color: rgb(51, 255, 136);
}
#dataviz-menu #menuV1 .titleBlueLight {
  color: rgb(51, 221, 255);
}
#dataviz-menu #menuV1 .titleBlue {
  color: rgb(51, 68, 255);
}
#dataviz-menu #menuV1 .titleOrange {
  color: rgb(255, 185, 45);
}
#dataviz-menu #menuV1 .titlePurple {
  color: rgb(187, 51, 255);
}
#dataviz-menu #menuV1 .buttonRed {
  background-color: rgba(204, 76, 51, 0.9);
}
#dataviz-menu #menuV1 .buttonYellow {
  background-color: rgba(204, 191, 51, 0.9);
}
#dataviz-menu #menuV1 .buttonGreenLight {
  background-color: rgba(102, 204, 51, 0.9);
}
#dataviz-menu #menuV1 .buttonGreen {
  background-color: rgba(51, 204, 115, 0.9);
}
#dataviz-menu #menuV1 .buttonBlueLight {
  background-color: rgba(51, 179, 204, 0.9);
}
#dataviz-menu #menuV1 .buttonOrange {
  background-color: rgba(255, 185, 45, 0.9);
}
#dataviz-menu #menuV1 .buttonBlue {
  background-color: rgba(51, 64, 204, 0.9);
}
#dataviz-menu #menuV1 .buttonPurple {
  background-color: rgba(153, 51, 204, 0.9);
}
#dataviz-menu #menuV1 .subtitleRed {
  color: rgb(191, 85, 64);
}
#dataviz-menu #menuV1 .subtitleYellow {
  color: rgb(191, 181, 64);
}
#dataviz-menu #menuV1 .subtitleGreenLight {
  color: rgb(106, 191, 64);
}
#dataviz-menu #menuV1 .subtitleGreen {
  color: rgb(64, 191, 117);
}
#dataviz-menu #menuV1 .subtitleBlueLight {
  color: rgb(64, 170, 191);
}
#dataviz-menu #menuV1 .subtitleBlue {
  color: rgb(64, 74, 191);
}
#dataviz-menu #menuV1 .subtitleOrange {
  color: rgb(255, 185, 45);
}
#dataviz-menu #menuV1 .subtitlePurple {
  color: rgb(149, 64, 191);
}
#dataviz-menu #menuV1 .breakdownBackgroundRed {
  background: linear-gradient(150deg, rgba(191, 85, 64, 0.9), rgba(115, 51, 38, 0.9));
}
#dataviz-menu #menuV1 .breakdownBackgroundYellow {
  background: linear-gradient(150deg, rgba(191, 181, 64, 0.9), rgba(115, 108, 38, 0.9));
}
#dataviz-menu #menuV1 .breakdownBackgroundGreenLight {
  background: linear-gradient(150deg, rgba(106, 191, 64, 0.9), rgba(64, 115, 38, 0.9));
}
#dataviz-menu #menuV1 .breakdownBackgroundGreen {
  background: linear-gradient(150deg, rgba(64, 191, 117, 0.9), rgba(38, 115, 70, 0.9));
}
#dataviz-menu #menuV1 .breakdownBackgroundBlueLight {
  background: linear-gradient(150deg, rgba(64, 170, 191, 0.9), rgba(38, 102, 115, 0.9));
}
#dataviz-menu #menuV1 .breakdownBackgroundBlue {
  background: linear-gradient(150deg, rgba(64, 74, 191, 0.9), rgba(38, 45, 115, 0.9));
}
#dataviz-menu #menuV1 .breakdownBackgroundPurple {
  background: linear-gradient(150deg, rgba(149, 64, 191, 0.9), rgba(89, 38, 115, 0.9));
}
#dataviz-menu #menuV1 .breakdownBackgroundOrange {
  background-image: linear-gradient(150deg, rgba(191, 165, 45, 0.9), rgba(115, 65, 10, 0.9));
}
#dataviz-menu #menuV1 > div:nth-of-type(1),
#dataviz-menu #menuV1 > div:last-of-type {
  flex-basis: 100%;
  flex-grow: 1;
  flex-shrink: 1;
}
#dataviz-menu #menuV1 > div:nth-last-child(2) {
  flex-basis: 100%;
  flex-grow: 1;
  flex-shrink: 1;
}
#dataviz-menu #menuV1 > div:nth-of-type(1) .stat {
  font-size: 72px;
}
#dataviz-menu .statWrapMult {
  width: 100%;
  display: inline-flex;
}
#dataviz-menu .statDelta {
  font-size: 16px;
  font-weight: 400;
}
#dataviz-menu .statAltDelta {
  font-size: 16px;
  font-weight: 400;
}
#dataviz-menu .statAltName {
  font-size: 16px;
  font-weight: 400;
  color: #999;
}
#dataviz-menu .statDelta.positive {
  color: rgba(0, 255, 0, 0.7);
}
#dataviz-menu .statDelta.negative {
  color: rgba(255, 255, 255, 0.8);
}
#dataviz-menu .statAltDelta.positive {
  color: rgba(0, 255, 0, 0.7);
}
#dataviz-menu .statAltDelta.negative {
  color: rgba(255, 255, 255, 0.8);
}
#dataviz-menu .tooltip {
  opacity: 0;
  white-space: nowrap;
  position: relative;
  width: auto;
  z-index: 99999;
  font-size: 11px;
  line-height: 14px;
  height: 16px;
  vertical-align: middle;
  bottom: 100%;
  background: rgba(64, 64, 64, 0.9);
  border-radius: 7px;
  pointer-events: none;
  text-transform: capitalize;
  transition: all 0.5s;
}
#dataviz-menu .tooltip::after {
  display: block;
  content: "\25BC";
  box-sizing: border-box;
  font-size: 10px;
  width: 100%;
  line-height: 1;
  position: absolute;
  text-align: center;
  bottom: -10px;
  left: 0;
  color: rgba(64, 64, 64, 0.9);
}
#dataviz-menu .tooltip.show {
  top: calc(-100% + 6px);
  opacity: 1;
}
#dataviz-menu .tier1 {
  color: #999;
  font-weight: 100;
  font-size: 32px;
  margin: 10px;
  white-space: nowrap;
  word-wrap: normal;
  /* flex child properties */
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 1px;
  /* end flex child properties */
  /* flex parent properties */
  display: flex;
  flex-direction: column;
  /* end flex parent properties */
}
#dataviz-menu .tier1:hover .groupName {
  text-shadow: 0px 0px 5px currentColor;
}
#dataviz-menu .tier1:hover .tier2wrap {
  box-shadow: 0px 0px 30px currentColor;
}
#dataviz-menu .tier1 .groupName {
  transition: all 0.5s;
  cursor: default;
}
#dataviz-menu .invert .tier1 {
  font-weight: 300;
}
#dataviz-menu .invert .stat {
  color: rgb(0, 0, 0);
  font-weight: 200;
}
#dataviz-menu .invert .tier2wrap {
  background: none;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
#dataviz-menu .invert .breakdown {
  background: hsla(196, 80%, 90%, 0.9);
}
#dataviz-menu .invert .breakdown::before {
  color: hsla(196, 80%, 90%, 0.9);
}
#dataviz-menu .invert .breakdown::after {
  color: hsla(196, 80%, 90%, 0.9);
}
#dataviz-menu .tier2wrap {
  /* flex parent properties */
  align-content: stretch;
  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  /* end flex parent properties */
  /* flex child properties */
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 100%;
  /* end flex child properties */
  white-space: normal;
  word-wrap: break-word;
  margin-top: 10px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
  padding: 10px 0px;
  border-width: 2px 0 1px 0;
  border-style: solid;
  transition: all 0.5s;
  box-shadow: none;
}
#dataviz-menu .breakdownStatName {
  text-transform: capitalize;
}
#dataviz-menu #menuV1 > div:nth-of-type(1n + 2) > div {
  flex-direction: column;
  align-content: stretch;
  align-items: stretch;
}
#dataviz-menu #menuV1 > div:nth-last-child(2) > div {
  flex-direction: row;
}
#dataviz-menu #menuV1 > div:nth-last-child(2) > div .statDiv {
  height: auto;
}
#dataviz-menu #menuV1 > div:last-of-type > div {
  flex-direction: row;
}
#dataviz-menu #menuV1 > div:last-of-type > div .statDiv {
  height: auto;
}
#dataviz-menu .tier2 {
  flex-grow: 1;
  flex-shrink: 1;
  font-size: 20px;
  color: #999;
  text-align: center;
  padding: 0px 10px;
  background: none;
  border: none;
  outline: none;
  position: relative;
  text-decoration: none;
  transition: all 0.5s;
  cursor: default;
  margin-bottom: 10px;
}
#dataviz-menu .tier2:hover .statName span {
  border-bottom: 1px solid currentColor;
  background-color: rgba(0, 0, 0, 0.2);
}
#dataviz-menu .tier2:hover .statName:hover span {
  background-color: rgba(0, 0, 0, 0.6);
}
#dataviz-menu .tier2 .dimmed {
  opacity: 0.3;
}
#dataviz-menu #menuV1 > div .tier2 {
  flex-basis: 100%;
}
#dataviz-menu #menuV1 > div:nth-of-type(1n + 2) .tier2 {
  flex-basis: auto;
}
#dataviz-menu .statDiv {
  transition: all 0.5s;
  position: relative;
}
#dataviz-menu .icons {
  justify-content: center;
  margin-top: 10px;
  position: relative;
}
#dataviz-menu .iconBt {
  cursor: pointer;
  position: relative;
  text-align: center;
  overflow: visible;
  color: white;
  font-size: 16px;
  font-weight: 400;
  vertical-align: middle;
  text-decoration: none;
  transition: all 0.5s;
  height: 24px;
  width: 24px;
  border-radius: 12px;
  z-index: 9;
  background-position: center center;
  background-size: contain;
  margin-left: 3px;
}
#dataviz-menu .iconBt:hover {
  color: rgb(255, 255, 255);
  height: 30px;
  width: 30px;
  border-radius: 15px;
  font-weight: 400;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}
#dataviz-menu .iconBt.icon-splitchord {
  background-image: url("/img/icon_splitchord.png");
}
#dataviz-menu .iconBt.icon-timerange {
  background-image: url("/img/icon_timerange.png");
}
#dataviz-menu .iconBt.icon-map {
  background-image: url("/img/icon_map.png");
}
#dataviz-menu .iconBt.icon-bubbles {
  background-image: url("/img/icon_bubbles.png");
}
#dataviz-menu .iconBt.icon-info {
  background-image: url("/img/icon_info.png");
}
#dataviz-menu .iconBt.icon-series {
  background-image: url("/img/icon_bars.png");
}
#dataviz-menu .iconBt.icon-churn {
  background-image: url("/img/icon-churn.png");
  background-repeat: no-repeat;
}
#dataviz-menu .iconBt.icon-area {
  background-image: url("/img/icon_area.png");
}
#dataviz-menu .iconBt.icon-undercontract {
  background-image: url("/img/icon_undercontract.png");
}
#dataviz-menu .iconBt.icon-relations {
  background-image: url("/img/icon_relations.png");
}
#dataviz-menu .iconBt.icon-copy {
  background-image: url("/img/icon_copy.png");
}
#dataviz-menu .iconBt.treemap {
  background-image: url("/img/treemap.png");
}
#dataviz-menu .iconBt:not(.active) {
  pointer-events: none;
}
#dataviz-menu .iconBt:hover .tooltip {
  opacity: 1;
}
#dataviz-menu .infoBt {
  cursor: pointer;
  position: absolute;
  left: 50%;
  top: 20px;
  text-align: center;
  opacity: 0;
  overflow: visible;
  color: white;
  font-size: 16px;
  font-weight: 400;
  vertical-align: middle;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.5s;
  height: 24px;
  width: 24px;
  border-radius: 12px;
  transform: translate(-50%, -50%);
  z-index: 9;
  background-position: center center;
  background-size: contain;
}
#dataviz-menu .infoBt:hover {
  color: rgb(255, 255, 255);
  height: 30px;
  width: 30px;
  border-radius: 15px;
  font-weight: 400;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}
#dataviz-menu .infoBt.icon-map {
  background-image: url("/img/icon_map.png");
}
#dataviz-menu .infoBt.icon-bubbles {
  background-image: url("/img/icon_bubbles.png");
}
#dataviz-menu .infoBt.icon-info {
  background-image: url("/img/icon_info.png");
}
#dataviz-menu .infoBt.icon-series {
  background-image: url("/img/icon_bars.png");
}
#dataviz-menu .infoBt.icon-area {
  background-image: url("/img/icon_area.png");
}
#dataviz-menu .infoBt.icon-undercontract {
  background-image: url("/img/icon_undercontract.png");
}
#dataviz-menu .infoBt.icon-relations {
  background-image: url("/img/icon_relations.png");
}
#dataviz-menu .infoBt:not(.active) {
  pointer-events: none;
}
#dataviz-menu .infoName {
  text-align: center;
  font-size: 20px;
  color: rgba(255, 255, 255, 0.8);
  padding: 12px 20px 10px;
  transition: all 0.5s;
  font-weight: 300;
}
#dataviz-menu .infoDiv {
  z-index: 10;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  padding: 1rem;
  font-size: 14px;
  text-align: left;
  border-radius: 10px;
  font-weight: 400;
  transition: all 0.5s;
}
#dataviz-menu .infoDiv .infoDefinition {
  font-weight: 300;
  margin: 10px 0 20px;
  color: rgba(255, 255, 255, 0.6);
  line-height: 140%;
}
#dataviz-menu .infoDiv .infoBt {
  float: left;
  text-align: center;
  opacity: 0;
  transition: all 0.25s;
  color: #ddd;
  font-size: 16px;
  font-weight: 200;
  text-decoration: underline;
  padding: 0.25rem;
  cursor: pointer;
  margin: 0px 10px 10px;
}
#dataviz-menu .infoDiv .hide {
  opacity: 0;
  pointer-events: none;
}
#dataviz-menu .infoDiv .display {
  display: inline;
  display: initial;
  transition: all 0.5s;
  pointer-events: none;
}
#dataviz-menu .infoCloseBt {
  text-align: center;
  transition: all 0.25s;
  min-height: 20px;
  max-height: 20px;
  min-width: 20px;
  max-width: 20px;
  overflow: visible;
  margin: 4px 0px 0px 4px;
  color: #ddd;
  font-size: 16px;
  font-weight: 400;
  display: inline-block;
  vertical-align: middle;
  border-radius: 100%;
  text-decoration: none;
  cursor: pointer;
}
#dataviz-menu .infoCloseBt:hover {
  color: rgb(255, 255, 255);
  transform: scale(1.33);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
}
#dataviz-menu .icon-info-close {
  text-align: center;
  position: absolute;
  top: 0px;
  right: 4px;
}
#dataviz-menu .icon-glossary {
  color: rgba(82, 31, 20, 0.9);
  text-align: center;
  border-radius: 10px;
  padding: 2px 10px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
  background-color: rgba(255, 255, 255, 0.3);
}
#dataviz-menu .icon-glossary:hover {
  cursor: pointer;
}
#dataviz-menu .tier2wrap .hide {
  opacity: 0;
  pointer-events: none;
}
#dataviz-menu .infoDiv .hide {
  opacity: 0;
  pointer-events: none;
}
#dataviz-menu .tier2wrap .nodisplay {
  display: none;
  transition: all 0.5s;
  pointer-events: none;
}
#dataviz-menu .infoDiv .display {
  display: inline;
  display: initial;
  transition: all 0.5s;
  pointer-events: none;
}
#dataviz-menu .infoCloseBt:hover {
  color: rgb(255, 255, 255);
  transform: scale(1.33);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
}
#dataviz-menu .statName {
  display: inline-block;
  transition: all 0.5s;
  font-weight: 300;
  clear: right;
  margin-top: 5px;
  border-top: 1px solid transparent;
}
#dataviz-menu .statName:not(.dimmed):hover {
  padding-top: 40px;
  margin-top: calc(5px - 40px) !important;
  color: white;
}
#dataviz-menu .statName.dimmed {
  background: none;
}
#dataviz-menu .statName span {
  border-bottom: 1px solid transparent;
  border-radius: 20px;
  padding: 0px 20px;
  transition: all 0.25s;
  display: inline-block;
  text-align: center;
}
#dataviz-menu .odometer.dimmed {
  opacity: 0.1;
}
#dataviz-menu .odometer * {
  text-align: center !important;
}
#dataviz-menu .odometer:hover {
  cursor: pointer;
}
#dataviz-menu .stat {
  color: rgb(255, 255, 255);
  font-size: 48px;
  display: inline-block;
  padding: 0 10px;
  box-sizing: border-box;
  width: 100%;
  position: relative;
  font-weight: 100;
}
#dataviz-menu .statAlt {
  color: rgb(255, 255, 255);
  top: -10px;
  font-size: 48px;
  display: inline-block;
  padding: 0 10px;
  box-sizing: border-box;
  width: 100%;
  position: relative;
  font-weight: 100;
  cursor: pointer;
}
#dataviz-menu .estimated {
  position: relative;
}
#dataviz-menu .estimated::before {
  opacity: 0.7;
  content: "*";
  font-size: 180%;
  position: absolute;
  top: -5px;
  left: 0;
  display: block;
}
#dataviz-menu .estimatedLegend {
  position: absolute;
  top: 15px;
  right: 40px;
  display: none;
  color: white;
  opacity: 0.7;
  padding-left: 15px;
  font-size: 120%;
}
#dataviz-menu .estimatedLegend.visible {
  display: inline-block;
}
#dataviz-menu .estimatedLegend .estimated {
  width: 10px;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}
#dataviz-menu .estimatedLegend .estimated::before {
  opacity: 1;
  top: 0;
  font-size: 210%;
}
#dataviz-menu .stat > .prefix {
  font-size: 24px;
  opacity: 0.8;
}
#dataviz-menu .stat > .suffix {
  font-size: 24px;
}
#dataviz-menu .statAlt > .prefixAlt,
#dataviz-menu .statAlt > .suffixAlt {
  font-size: 24px;
  opacity: 0.8;
}
#dataviz-menu .breakdown {
  background: hsla(1, 40%, 30%, 0.9);
  font-size: 16px;
  width: 100%;
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 5px;
  z-index: 10;
  display: block;
  opacity: 0;
  transition: opacity 0.25s;
}
#dataviz-menu .breakdown .clippy:hover {
  cursor: pointer;
}
#dataviz-menu .invert .breakdown {
  background: hsla(1, 80%, 90%, 0.9);
}
#dataviz-menu #menuV1 > div:last-of-type .breakdown {
  top: auto;
  bottom: 100%;
}
#dataviz-menu .stat:hover .breakdown.active {
  opacity: 1;
}
#dataviz-menu .breakdownStat {
  /* flex parent properties */
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: stretch;
  /* end flex parent properties */
  margin: 5px;
}
#dataviz-menu .arcLabel {
  fill: rgb(255, 255, 255);
}

#launcher h1 {
  text-align: center;
  font-weight: lighter;
  color: rgba(255, 255, 255, 0.7);
}
#launcher #logo {
  margin: 40px;
}
#launcher a {
  display: block;
  /* border: 1px solid #444; */
  border-radius: 10px;
  padding: 20px;
  margin: 20px;
  transition: all 0.25s;
  text-decoration: none;
  text-align: center;
  font-weight: lighter;
  text-align: center;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 200px;
  line-height: 24px;
}
#launcher a:link,
#launcher a:visited {
  color: rgba(255, 255, 255, 0.7);
  transition: all 0.25s;
  background-color: rgba(255, 255, 255, 0.05);
}
#launcher a:hover,
#launcher a:active {
  color: rgb(255, 255, 255);
  /* border: 1px solid #fff; */
  background-color: rgba(255, 255, 255, 0.1);
  transform: scale(1.1);
}
#launcher a:hover .thumbnail,
#launcher a:active .thumbnail {
  box-shadow: 0px 0px 20px rgba(100, 100, 255, 0.5);
}
#launcher .thumbnail {
  width: 196px;
  height: 196px;
  text-align: center;
  /* border: 1px solid #fff; */
  overflow: hidden;
  display: inline-block;
  border-radius: 100px;
  margin-bottom: 10px;
  background-image: url(/img/plume-logo-white.png);
  background-size: cover;
  background-position: center center;
  position: relative;
}
#launcher .thumbnail div {
  width: 204px;
  height: 204px;
  margin-left: -2px;
  margin-top: -2px;
  text-align: center;
  overflow: hidden;
  display: inline-block;
  border-radius: 100px;
  background-size: cover;
  background-position: center center;
}

#launcher > div {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

/* GREY COLORS */
/* END OF GREY COLORS */
#multiple-charts {
  /* LEGEND */
  /* VERTICAL LINE */
}
#multiple-charts #vis {
  padding-top: 15px;
}
#multiple-charts svg {
  display: block;
  margin: auto;
}
#multiple-charts .line {
  fill: none;
  stroke: black;
  stroke-width: 2px;
}
#multiple-charts .axis {
  shape-rendering: crispEdges;
}
#multiple-charts .axis text {
  font: 10px sans-serif;
  color: white;
}
#multiple-charts .x.axis line {
  stroke: #ddd;
  stroke-opacity: 0.6;
}
#multiple-charts .x.axis path {
  display: none;
}
#multiple-charts footer p {
  text-align: center;
}
#multiple-charts .legend {
  position: absolute;
  top: 0;
  right: 15px;
}
#multiple-charts .legend-entry {
  display: flex;
  align-items: center;
}
#multiple-charts .legend-entry div {
  height: 20px;
  width: 20px;
  display: inline-block;
  margin-right: 5px;
}
#multiple-charts .legend-entry p {
  color: white;
  display: inline;
  font-size: smaller;
}
#multiple-charts .vertical {
  position: absolute;
  z-index: 19;
  width: 1px;
  height: 95%;
  top: 10px;
  bottom: 30px;
  left: 0px;
  background: white;
}

/* GREY COLORS */
/* END OF GREY COLORS */
#optimization-interference {
  color: white;
  font-size: 10px;
  /* slider */
}
#optimization-interference #sliderWrap {
  /* width: calc(100vw - 40px;) */
  padding: 0px 30px;
  height: 10px;
  position: relative;
}
#optimization-interference .slider-container {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 20px;
}
#optimization-interference .slider {
  background-color: rgba(255, 255, 255, 0.5);
  /* border: 1px solid rgba(255,255,255,.8); */
  border-radius: 20px;
  position: absolute;
  border: 1px solid #aab;
  background: #bce;
  height: 100%;
  width: 58px;
  top: 0px;
  cursor: move;
  border: none;
  /* margin: -0.5px; */
}
#optimization-interference .slider .handle {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 9px;
  position: absolute;
  height: 9px;
  width: 9px;
  border: 1px solid #aab;
  background: #9ac;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
#optimization-interference .slider .WW {
  left: 2px;
  cursor: w-resize;
}
#optimization-interference .slider .EE {
  right: 2px;
  cursor: e-resize;
}
#optimization-interference .range-label {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 25px;
}
#optimization-interference .range-label.low {
  left: 0px;
  text-align: right;
}
#optimization-interference .range-label.high {
  right: 0px;
}

#polar-radar-ring .axis .domain {
  stroke: rgba(255, 255, 255, 0.3);
}
#polar-radar-ring .tick line {
  stroke: rgba(255, 255, 255, 0.3);
}
#polar-radar-ring .tick text {
  fill: rgba(255, 255, 255, 0.3);
}
#polar-radar-ring .tick .tickRing {
  stroke: rgba(255, 255, 255, 0.1);
  stroke-dasharray: 3;
  stroke-linecap: round;
}

.profile__page {
  height: 100%;
  display: flex;
  flex-direction: row;
  background: #222;
  z-index: 10;
}
.profile__page .profile-background {
  width: 100%;
  height: 100%;
  z-index: 11;
  display: block;
  background-color: black;
  position: absolute;
  right: 0;
  overflow: scroll;
}
.profile__page .profile-background .alert-danger {
  color: red;
}
.profile__page .profile-background .redir-todash-but {
  text-align: center;
  margin-left: 3rem;
  height: 30px;
  line-height: 20px;
  font-size: 20px;
  justify-content: center;
  border-radius: 28px;
  border: 1px solid;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
  font-family: Wigrum;
  color: white;
  background-color: #6269ff;
  border-color: rgb(98, 105, 255);
  padding: 8px 10px 25px 10px;
  box-shadow: -2px 2px 10px 0 rgba(98, 105, 255, 0.5);
}
.profile__page .profile-background .profile-form {
  pointer-events: none;
  display: flex;
  flex-direction: column;
  background: black;
  margin: 3rem;
}
.profile__page .profile-background .profile-form img {
  width: 30%;
}
.profile__page .profile-background .profile-form input {
  display: inline-block;
  border: none;
  border-bottom: 1.5px solid #6665ad;
  font-size: 14px;
  display: block;
  width: 90%;
  height: 40px;
  outline: 0;
  background-color: transparent;
  color: rgba(255, 255, 255, 0.7);
  z-index: 1;
}
.profile__page .profile-background .profile-form input:disabled,
.profile__page .profile-background .profile-form button:disabled {
  cursor: no-drop;
}
.profile__page .profile-background .profile-form .input-form {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.profile__page .profile-background .profile-form .input-form .input-row {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.profile__page .profile-background .profile-form .input-form .input-div {
  pointer-events: all;
  width: 50%;
  margin: 2% 0;
  color: rgba(255, 255, 255, 0.6);
  float: left;
}
.profile__page .profile-background .profile-form .input-form .input-div label {
  font-size: 12px;
  margin-bottom: 10px;
}
.profile__page .profile-background .profile-form .input-form .input-div input {
  width: 90%;
}
.profile__page .profile-background .profile-form .input-form .btn-profile {
  float: left;
  pointer-events: all;
  font-size: 14px;
  color: #fff;
  line-height: 1.2;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  width: 140px;
  height: 50px;
  background-color: #1fadbf;
  cursor: pointer;
  border: none;
  border-radius: 25px;
  outline: 0;
  transition: all 0.4s;
  background-image: url(/static/media/arrowRight.0e472b46.svg);
  background-repeat: no-repeat;
  background-position: 80% 45%;
  background-size: 13px;
  text-align: left;
  padding-left: 5%;
  margin-right: 5%;
  margin-bottom: 40px;
}
.profile__page .profile-background .profile-form .input-form .btn-profile:hover {
  letter-spacing: 0.7px;
  background-color: #1eedf7;
}
.profile__page .profile-background .profile-form h1 {
  font-size: 60px;
  color: #fff;
  line-height: 1.18;
  margin: 4% 0;
}
.profile__page .profile-background .profile-form .container-profile {
  display: flex;
  flex-direction: row;
  max-height: 300px;
}
.profile__page .profile-background .profile-form .container-profile .hidden {
  display: none;
}
.profile__page .profile-background .profile-form .container-profile .container-profile-image {
  margin-right: 10px;
}
.profile__page .profile-background .profile-form .container-profile .container-profile-image .profile-image {
  max-width: 100%;
  min-width: 300px;
  height: 100%;
}
.profile__page .profile-background .profile-form .container-profile .fsp-header {
  display: none;
}
.profile__page .profile-background .profile-form .container-profile .fsp-picker,
.profile__page .profile-background .profile-form .container-profile .fsp-drop-area,
.profile__page .profile-background .profile-form .container-profile .fsp-modal {
  background-color: transparent;
}
.profile__page .profile-background .profile-form .container-profile .fsp-modal {
  border: none;
}
.profile__page .profile-background .profile-form .container-profile .fsp-drop-area-container {
  padding: 30px 0;
}
.profile__page .profile-background .profile-form .container-profile .fsp-content--transformer {
  border: 1px solid;
}
.profile__page .profile-background .profile-form .file {
  pointer-events: all;
  width: 50%;
  margin: 2% 0;
  color: rgba(255, 255, 255, 0.6);
  float: left;
}
.profile__page .profile-background .profile-form .file input {
  width: 50%;
  float: left;
}
.profile__page .profile-background .profile-form .file img {
  width: 35%;
  margin: 0 15px;
}

/* GREY COLORS */
/* END OF GREY COLORS */
#sankey-interference {
  color: white;
}
#sankey-interference .node rect {
  cursor: move;
  fill-opacity: 0.9;
  shape-rendering: crispEdges;
}
#sankey-interference .title {
  pointer-events: none;
  fill: white;
  /* text-shadow: 0 1px 0 #fff; */
}
#sankey-interference .link {
  fill: none;
  stroke: white;
  stroke-opacity: 0.3;
  transition: stroke-opacity 0.25s;
}
#sankey-interference .link:hover {
  stroke-opacity: 0.7;
}
#sankey-interference p {
  margin: 0;
  padding: 0;
}
#sankey-interference sup {
  font-size: 9px;
}
#sankey-interference #controls {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 99;
}
#sankey-interference #status {
  color: white;
  margin-bottom: 5px;
}
#sankey-interference .button {
  background: rgba(255, 255, 255, 0.5);
  padding: 5px 20px;
  border-radius: 20px;
  cursor: pointer;
  text-align: center;
}

/* GREY COLORS */
/* END OF GREY COLORS */
#timeseries-area {
  color: #96999b;
  height: 94vh;
  overflow-x: hidden;
  margin-top: 0px;
  /* TEST STREAM STYLES */
  /* SLIDER */
  /* LEGEND */
  /* VERTICAL LINE */
}
#timeseries-area .axis path,
#timeseries-area .axis line {
  fill: none;
  stroke: #96999b;
  stroke-width: 1px;
  shape-rendering: crispEdges;
}
#timeseries-area .tick {
  fill: #96999b;
}
#timeseries-area g.x.axis > g.tick {
  cursor: pointer;
}
#timeseries-area #titleHeader {
  padding-top: 2px;
  padding-left: 20px;
}
#timeseries-area #title {
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
}
#timeseries-area #controlsIndicator {
  margin-top: 30px;
}
#timeseries-area #titleText {
  font-size: 24px;
}
#timeseries-area button {
  position: absolute;
  right: 50px;
  top: 10px;
  z-index: 3;
  cursor: pointer;
}
#timeseries-area .switch {
  position: relative;
  right: 0;
}
#timeseries-area .axis path,
#timeseries-area .axis line {
  fill: none;
  stroke: none;
  shape-rendering: crispEdges;
}
#timeseries-area .browser text {
  color: white;
  text-anchor: end;
}
#timeseries-area line.track {
  stroke: none;
}
#timeseries-area line.track-inset {
  stroke: rgba(255, 255, 255, 0.3);
}
#timeseries-area .parameter-value path {
  stroke: none;
}
#timeseries-area .legend {
  position: absolute;
  top: 0;
  right: 15px;
}
#timeseries-area .legend-entry {
  display: flex;
  align-items: center;
}
#timeseries-area .legend-entry div {
  height: 20px;
  width: 20px;
  display: inline-block;
  margin-right: 5px;
}
#timeseries-area .legend-entry p {
  color: white;
  display: inline;
  font-size: smaller;
}
#timeseries-area .timeseriesTooltip {
  position: absolute;
  z-index: 20;
  visibility: hidden;
  top: 20px;
  right: 55px;
  color: #fff;
}
#timeseries-area .vertical {
  position: absolute;
  z-index: 19;
  width: 1px;
  height: 95%;
  top: 10px;
  bottom: 30px;
  left: 0px;
  background: #fff;
  opacity: 0.5;
}

.invert {
  background: rgb(255, 255, 255);
  font-weight: 300;
}
.invert .xLabel,
.invert .xLabelNum {
  fill: rgb(0, 0, 0);
}
.invert .xLabelDot {
  fill: rgba(0, 0, 0, 0.7);
}
.invert .yLabel {
  fill: rgb(0, 0, 0);
}
.invert .totalCol {
  border: 1px solid hsla(1, 50%, 10%, 0.1);
}
.invert .connectingLineTotal {
  stroke: rgba(0, 0, 0, 0.4);
}
.invert .connectingLineVert {
  stroke: rgba(0, 0, 0, 0.2);
}
.invert circle.marketCap {
  stroke: rgba(0, 0, 0, 0.7);
}
.invert circle.marketCapProjected {
  stroke-dasharray: 2 6;
  stroke-linecap: round;
}

.totalCol {
  position: absolute;
  border: 1px solid hsla(1, 50%, 10%, 0.1);
  z-index: 0;
}

svg {
  position: relative;
  z-index: 1;
}

.xLabel {
  fill: rgb(255, 255, 255);
  text-anchor: middle;
  font-size: 21px;
  font-weight: 300;
}

.xLabelNum {
  fill: rgba(255, 255, 255, 0.7);
  text-anchor: middle;
  font-size: 16px;
  font-weight: 300;
}

.xLabelDot,
.totalDot {
  fill: rgba(255, 255, 255, 0.7);
}

.yLabel {
  fill: rgb(255, 255, 255);
  font-size: 24px;
  font-weight: 300;
  text-anchor: middle;
}

circle.marketCap {
  stroke: rgba(255, 255, 255, 0.7);
  stroke-width: 1;
  fill: none;
}
circle.marketCapProjected {
  stroke-dasharray: 2 6;
  stroke-linecap: round;
}
circle.activeHouseholds {
  fill: hsl(1, 100%, 70%);
}
circle.activeHouseholdsProjected {
  fill: url(#diagonalHatch);
}

.diagonalHatch {
  stroke: hsl(196, 100%, 70%);
  stroke-width: 1.5;
}

.connectingLineVert {
  stroke: rgba(255, 255, 255, 0.2);
  stroke-width: 1;
  stroke-dasharray: 2;
  stroke-linecap: round;
  fill: none;
}

.connectingLineTotal {
  stroke: rgba(255, 255, 255, 0.4);
  stroke-width: 1;
  stroke-linecap: round;
  fill: none;
}

.totalHeader {
  font-size: 20px;
  font-weight: 500;
}

.totalDenominator {
  font-size: 20px;
  font-weight: 500;
}

.metaTrigger {
  position: absolute;
  z-index: 10;
}
.metaTrigger:hover .metaTriggerTooltip {
  opacity: 1;
}

.metaTriggerTooltip {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 5px;
  padding: 2px 5px;
  opacity: 0;
  transition: opacity 0.5s;
}
.metaTriggerTooltip div {
  white-space: nowrap;
}
.metaTriggerTooltip span {
  font-weight: 600;
}

.total_container {
  overflow: hidden;
  position: fixed;
  height: calc(100% - 40px);
  display: block;
  right: 0;
  top: 40px;
  bottom: -10px;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.7);
}

#floater {
  height: 100%;
}

.main-container {
  overflow-x: scroll;
}

/**
 * COMPONENTS
 */
/* GREY COLORS */
/* END OF GREY COLORS */
#controlsDrawer {
  position: absolute;
  width: 100%;
  z-index: 1;
  top: 0;
  height: 50px;
  /* SWITCH CHECKBOX */
}
#controlsDrawer #controls {
  padding: 20px;
  transform: translateY(-100%);
  opacity: 0;
  transition: all 0.3s ease-in;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
#controlsDrawer #controlsIndicator {
  opacity: 1;
  transition: all 0.3s ease-in;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 100px;
  height: 20px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  font-size: 10px;
  color: white;
  justify-content: center;
  align-items: center;
  display: flex;
}
#controlsDrawer #sliderWrap {
  margin: 10px;
}
#controlsDrawer .inputs-parent {
  display: flex;
  justify-content: center;
  align-items: center;
}
#controlsDrawer .control {
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: #fff;
  font-size: 14px;
  padding: 12px 20px;
  position: relative;
  text-align: center;
  z-index: 1;
  background: #222;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
#controlsDrawer .control:first-child {
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
}
#controlsDrawer .control:last-child {
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
}
#controlsDrawer .control.active {
  background: rgba(255, 255, 255, 0.3);
}
#controlsDrawer .control * {
  cursor: pointer;
}
#controlsDrawer select {
  background-color: #222;
  color: white;
  border: none;
  outline: none;
}
#controlsDrawer select option {
  background: transparent;
}
#controlsDrawer .switch {
  position: relative;
  display: block;
  width: 52px;
  height: 26px;
  margin-top: 8px;
  /* Rounded sliders */
}
#controlsDrawer .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
#controlsDrawer .switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 52px;
  background-color: #ccc;
  transition: 0.4s;
}
#controlsDrawer .switch .slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}
#controlsDrawer .switch input:checked + .slider {
  background-color: #2abb42;
}
#controlsDrawer .switch input:focus + .slider {
  box-shadow: 0 0 1px #2abb42;
}
#controlsDrawer .switch input:checked + .slider:before {
  transform: translateX(26px);
}
#controlsDrawer .switch .slider.round {
  border-radius: 26px;
}
#controlsDrawer .switch .slider.round:before {
  border-radius: 50%;
}
#controlsDrawer .switch-label {
  display: block;
  font-size: 12px;
  color: white;
}

#controlsDrawer:hover #controls {
  transform: translateY(0%);
  opacity: 1;
}
#controlsDrawer:hover #controlsIndicator {
  transform: translate(-50%, -100%);
  opacity: 0;
}

/* GREY COLORS */
/* END OF GREY COLORS */
#titleText {
  color: white;
}

#scrollPane {
  margin: 20px;
  border-radius: 10px;
  padding: 10px;
  overflow-y: auto;
}

.itemTitle {
  margin: 0px 16px 0px;
  font-size: 32px;
  font-weight: 100;
  color: rgb(51, 201, 255);
}

.itemSubtitle {
  margin: 0px 16px 0px;
  font-weight: 300;
  color: #ddd;
  font-size: 24px;
}

.itemDescription {
  margin: 0px 16px 32px;
  color: #999;
}
.KPI-live-table-title-cont {
  padding-left: 50px;
  color: #FA1478;
  width: 100%;
  display: block;
  min-height: 50px;
  cursor: pointer;
}
.KPI-live-table-title-cont .KPI-live-table-title-title {
  line-height: 50px;
  font-size: 16px;
}
.KPI-live-table-title-cont .KPI-live-table-title-circle {
  height: 10px;
  width: 10px;
  border-radius: 25px;
  background-color: #FA1478;
  display: inline-block;
  margin-left: 15px;
}
.KPI-live-menu-cont {
  height: 160px;
}
@charset "UTF-8";
/* GREY COLORS */
/* END OF GREY COLORS */
.KPI-live-block-cont {
  color: white;
  border: 1px solid rgba(250, 20, 120, 0.2);
  background: rgba(255, 255, 255, 0.05);
  border-radius: 0px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  overflow: hidden;
  padding: 0 40px;
  /* flex child properties */
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  /* end flex child properties */
  /* flex parent properties */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  align-content: stretch;
  /* end flex parent properties */
  overflow: visible;
}
.KPI-live-block-cont .backgroundRed {
  background-image: linear-gradient(150deg, rgba(61, 44, 41, 0.1), rgba(122, 109, 82, 0.3));
  border-color: rgb(217, 68, 38);
}
.KPI-live-block-cont .backgroundYellow {
  background-image: linear-gradient(150deg, rgba(61, 60, 41, 0.1), rgba(105, 122, 82, 0.3));
  border-color: rgb(217, 202, 38);
}
.KPI-live-block-cont .backgroundGreenLight {
  background-image: linear-gradient(150deg, rgba(48, 61, 41, 0.1), rgba(82, 122, 88, 0.3));
  border-color: rgb(98, 217, 38);
}
.KPI-live-block-cont .backgroundGreen {
  background-image: linear-gradient(150deg, rgba(41, 61, 49, 0.1), rgba(82, 122, 119, 0.3));
  border-color: rgb(38, 217, 113);
}
.KPI-live-block-cont .backgroundBlueLight {
  background-image: linear-gradient(150deg, rgba(41, 58, 61, 0.1), rgba(82, 95, 122, 0.3));
  border-color: rgb(38, 187, 217);
}
.KPI-live-block-cont .backgroundBlue {
  background-image: linear-gradient(150deg, rgba(41, 43, 61, 0.1), rgba(99, 82, 122, 0.3));
  border-color: rgb(38, 53, 217);
}
.KPI-live-block-cont .backgroundOrange {
  background-image: linear-gradient(150deg, rgba(54, 41, 10, 0.1), rgba(115, 65, 10, 0.25));
  border-color: rgb(255, 185, 45);
}
.KPI-live-block-cont .backgroundPurple {
  background-image: linear-gradient(150deg, rgba(54, 41, 61, 0.1), rgba(122, 82, 116, 0.3));
  border-color: rgb(157, 38, 217);
}
.KPI-live-block-cont .titleRed {
  color: rgb(255, 85, 51);
}
.KPI-live-block-cont .titleYellow {
  color: rgb(255, 238, 51);
}
.KPI-live-block-cont .titleGreenLight {
  color: rgb(119, 255, 51);
}
.KPI-live-block-cont .titleGreen {
  color: rgb(51, 255, 136);
}
.KPI-live-block-cont .titleBlueLight {
  color: rgb(51, 221, 255);
}
.KPI-live-block-cont .titleBlue {
  color: rgb(51, 68, 255);
}
.KPI-live-block-cont .titleOrange {
  color: rgb(255, 185, 45);
}
.KPI-live-block-cont .titlePurple {
  color: rgb(187, 51, 255);
}
.KPI-live-block-cont .buttonRed {
  background-color: rgba(204, 76, 51, 0.9);
}
.KPI-live-block-cont .buttonYellow {
  background-color: rgba(204, 191, 51, 0.9);
}
.KPI-live-block-cont .buttonGreenLight {
  background-color: rgba(102, 204, 51, 0.9);
}
.KPI-live-block-cont .buttonGreen {
  background-color: rgba(51, 204, 115, 0.9);
}
.KPI-live-block-cont .buttonBlueLight {
  background-color: rgba(51, 179, 204, 0.9);
}
.KPI-live-block-cont .buttonOrange {
  background-color: rgba(255, 185, 45, 0.9);
}
.KPI-live-block-cont .buttonBlue {
  background-color: rgba(51, 64, 204, 0.9);
}
.KPI-live-block-cont .buttonPurple {
  background-color: rgba(153, 51, 204, 0.9);
}
.KPI-live-block-cont .subtitleRed {
  color: rgb(191, 85, 64);
}
.KPI-live-block-cont .subtitleYellow {
  color: rgb(191, 181, 64);
}
.KPI-live-block-cont .subtitleGreenLight {
  color: rgb(106, 191, 64);
}
.KPI-live-block-cont .subtitleGreen {
  color: rgb(64, 191, 117);
}
.KPI-live-block-cont .subtitleBlueLight {
  color: rgb(64, 170, 191);
}
.KPI-live-block-cont .subtitleBlue {
  color: rgb(64, 74, 191);
}
.KPI-live-block-cont .subtitleOrange {
  color: rgb(255, 185, 45);
}
.KPI-live-block-cont .subtitlePurple {
  color: rgb(149, 64, 191);
}
.KPI-live-block-cont .breakdownBackgroundRed {
  background: linear-gradient(150deg, rgba(191, 85, 64, 0.9), rgba(115, 51, 38, 0.9));
}
.KPI-live-block-cont .breakdownBackgroundYellow {
  background: linear-gradient(150deg, rgba(191, 181, 64, 0.9), rgba(115, 108, 38, 0.9));
}
.KPI-live-block-cont .breakdownBackgroundGreenLight {
  background: linear-gradient(150deg, rgba(106, 191, 64, 0.9), rgba(64, 115, 38, 0.9));
}
.KPI-live-block-cont .breakdownBackgroundGreen {
  background: linear-gradient(150deg, rgba(64, 191, 117, 0.9), rgba(38, 115, 70, 0.9));
}
.KPI-live-block-cont .breakdownBackgroundBlueLight {
  background: linear-gradient(150deg, rgba(64, 170, 191, 0.9), rgba(38, 102, 115, 0.9));
}
.KPI-live-block-cont .breakdownBackgroundBlue {
  background: linear-gradient(150deg, rgba(64, 74, 191, 0.9), rgba(38, 45, 115, 0.9));
}
.KPI-live-block-cont .breakdownBackgroundPurple {
  background: linear-gradient(150deg, rgba(149, 64, 191, 0.9), rgba(89, 38, 115, 0.9));
}
.KPI-live-block-cont .breakdownBackgroundOrange {
  background-image: linear-gradient(150deg, rgba(191, 165, 45, 0.9), rgba(115, 65, 10, 0.9));
}
.KPI-live-block-cont .statWrapMult {
  width: 100%;
  display: inline-flex;
}
.KPI-live-block-cont .statDelta {
  font-size: 16px;
  font-weight: 400;
}
.KPI-live-block-cont .statAltDelta {
  font-size: 16px;
  font-weight: 400;
}
.KPI-live-block-cont .statAltName {
  font-size: 16px;
  font-weight: 400;
  color: #999;
}
.KPI-live-block-cont .statDelta.positive {
  color: rgba(0, 255, 0, 0.7);
}
.KPI-live-block-cont .statDelta.negative {
  color: rgba(255, 255, 255, 0.8);
}
.KPI-live-block-cont .statAltDelta.positive {
  color: rgba(0, 255, 0, 0.7);
}
.KPI-live-block-cont .statAltDelta.negative {
  color: rgba(255, 255, 255, 0.8);
}
.KPI-live-block-cont .tooltip {
  opacity: 0;
  white-space: nowrap;
  position: relative;
  width: auto;
  z-index: 99999;
  font-size: 11px;
  line-height: 14px;
  height: 16px;
  vertical-align: middle;
  bottom: 100%;
  background: rgba(64, 64, 64, 0.9);
  border-radius: 7px;
  pointer-events: none;
  text-transform: capitalize;
  transition: all 0.5s;
}
.KPI-live-block-cont .tooltip::after {
  display: block;
  content: "\25BC";
  box-sizing: border-box;
  font-size: 10px;
  width: 100%;
  line-height: 1;
  position: absolute;
  text-align: center;
  bottom: -10px;
  left: 0;
  color: rgba(64, 64, 64, 0.9);
}
.KPI-live-block-cont .tooltip.show {
  top: calc(-100% + 6px);
  opacity: 1;
}
.KPI-live-block-cont .tier1 {
  color: #999;
  font-weight: 100;
  font-size: 32px;
  margin: 10px;
  white-space: nowrap;
  word-wrap: normal;
  /* flex child properties */
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 1px;
  /* end flex child properties */
  /* flex parent properties */
  display: flex;
  flex-direction: column;
  /* end flex parent properties */
}
.KPI-live-block-cont .tier1:hover .groupName {
  text-shadow: 0px 0px 5px currentColor;
}
.KPI-live-block-cont .tier1:hover .tier2wrap {
  box-shadow: 0px 0px 30px currentColor;
}
.KPI-live-block-cont .tier1 .groupName {
  transition: all 0.5s;
  cursor: default;
}
.KPI-live-block-cont .invert .tier1 {
  font-weight: 300;
}
.KPI-live-block-cont .invert .stat {
  color: rgb(0, 0, 0);
  font-weight: 200;
}
.KPI-live-block-cont .invert .tier2wrap {
  background: none;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.KPI-live-block-cont .invert .breakdown {
  background: hsla(196, 80%, 90%, 0.9);
}
.KPI-live-block-cont .invert .breakdown::before {
  color: hsla(196, 80%, 90%, 0.9);
}
.KPI-live-block-cont .invert .breakdown::after {
  color: hsla(196, 80%, 90%, 0.9);
}
.KPI-live-block-cont .tier2wrap {
  /* flex parent properties */
  align-content: stretch;
  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  /* end flex parent properties */
  /* flex child properties */
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 100%;
  /* end flex child properties */
  white-space: normal;
  word-wrap: break-word;
  margin-top: 10px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
  padding: 10px 0px;
  border-width: 2px 0 1px 0;
  border-style: solid;
  transition: all 0.5s;
  box-shadow: none;
}
.KPI-live-block-cont .breakdownStatName {
  text-transform: capitalize;
}
.KPI-live-block-cont .tier2 {
  flex-grow: 1;
  flex-shrink: 1;
  font-size: 20px;
  color: #999;
  text-align: center;
  padding: 0px 10px;
  background: none;
  border: none;
  outline: none;
  position: relative;
  text-decoration: none;
  transition: all 0.5s;
  cursor: default;
  margin-bottom: 10px;
}
.KPI-live-block-cont .tier2:hover .statName span {
  border-bottom: 1px solid currentColor;
  background-color: rgba(0, 0, 0, 0.2);
}
.KPI-live-block-cont .tier2:hover .statName:hover span {
  background-color: rgba(0, 0, 0, 0.6);
}
.KPI-live-block-cont .tier2 .dimmed {
  opacity: 0.3;
}
.KPI-live-block-cont .statDiv {
  transition: all 0.5s;
  position: relative;
}
.KPI-live-block-cont .icons {
  justify-content: center;
  margin-top: 10px;
}
.KPI-live-block-cont .iconBt {
  cursor: pointer;
  position: relative;
  text-align: center;
  overflow: visible;
  color: white;
  font-size: 16px;
  font-weight: 400;
  vertical-align: middle;
  text-decoration: none;
  transition: all 0.5s;
  height: 24px;
  width: 24px;
  border-radius: 12px;
  z-index: 9;
  background-position: center center;
  background-size: contain;
  margin-left: 3px;
}
.KPI-live-block-cont .iconBt:hover {
  color: rgb(255, 255, 255);
  height: 30px;
  width: 30px;
  border-radius: 15px;
  font-weight: 400;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}
.KPI-live-block-cont .iconBt.icon-splitchord {
  background-image: url("/img/icon_splitchord.png");
}
.KPI-live-block-cont .iconBt.icon-timerange {
  background-image: url("/img/icon_timerange.png");
}
.KPI-live-block-cont .iconBt.icon-map {
  background-image: url("/img/icon_map.png");
}
.KPI-live-block-cont .iconBt.icon-bubbles {
  background-image: url("/img/icon_bubbles.png");
}
.KPI-live-block-cont .iconBt.icon-info {
  background-image: url("/img/icon_info.png");
}
.KPI-live-block-cont .iconBt.icon-series {
  background-image: url("/img/icon_bars.png");
}
.KPI-live-block-cont .iconBt.icon-churn {
  background-image: url("/img/icon-churn.png");
  background-repeat: no-repeat;
}
.KPI-live-block-cont .iconBt.icon-area {
  background-image: url("/img/icon_area.png");
}
.KPI-live-block-cont .iconBt.icon-undercontract {
  background-image: url("/img/icon_undercontract.png");
}
.KPI-live-block-cont .iconBt.icon-relations {
  background-image: url("/img/icon_relations.png");
}
.KPI-live-block-cont .iconBt.icon-copy {
  background-image: url("/img/icon_copy.png");
}
.KPI-live-block-cont .iconBt.treemap {
  background-image: url("/img/treemap.png");
}
.KPI-live-block-cont .iconBt:not(.active) {
  pointer-events: none;
}
.KPI-live-block-cont .iconBt:hover .tooltip {
  opacity: 1;
}
.KPI-live-block-cont .infoBt {
  cursor: pointer;
  position: absolute;
  left: 50%;
  top: 20px;
  text-align: center;
  opacity: 0;
  overflow: visible;
  color: white;
  font-size: 16px;
  font-weight: 400;
  vertical-align: middle;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.5s;
  height: 24px;
  width: 24px;
  border-radius: 12px;
  transform: translate(-50%, -50%);
  z-index: 9;
  background-position: center center;
  background-size: contain;
}
.KPI-live-block-cont .infoBt:hover {
  color: rgb(255, 255, 255);
  height: 30px;
  width: 30px;
  border-radius: 15px;
  font-weight: 400;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}
.KPI-live-block-cont .infoBt.icon-map {
  background-image: url("/img/icon_map.png");
}
.KPI-live-block-cont .infoBt.icon-bubbles {
  background-image: url("/img/icon_bubbles.png");
}
.KPI-live-block-cont .infoBt.icon-info {
  background-image: url("/img/icon_info.png");
  z-index: 100000;
}
.KPI-live-block-cont .infoBt.icon-series {
  background-image: url("/img/icon_bars.png");
}
.KPI-live-block-cont .infoBt.icon-area {
  background-image: url("/img/icon_area.png");
}
.KPI-live-block-cont .infoBt.icon-undercontract {
  background-image: url("/img/icon_undercontract.png");
}
.KPI-live-block-cont .infoBt.icon-relations {
  background-image: url("/img/icon_relations.png");
}
.KPI-live-block-cont .infoBt:not(.active) {
  pointer-events: none;
}
.KPI-live-block-cont .infoName {
  text-align: center;
  font-size: 20px;
  color: rgba(255, 255, 255, 0.8);
  padding: 12px 20px 10px;
  transition: all 0.5s;
  font-weight: 300;
}
.KPI-live-block-cont .infoDiv {
  z-index: 10;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  padding: 1rem;
  font-size: 14px;
  text-align: left;
  border-radius: 10px;
  font-weight: 400;
  transition: all 0.5s;
}
.KPI-live-block-cont .infoDiv .infoDefinition {
  font-weight: 300;
  margin: 10px 0 20px;
  color: rgba(255, 255, 255, 0.6);
  line-height: 140%;
}
.KPI-live-block-cont .infoDiv .infoBt {
  float: left;
  text-align: center;
  opacity: 0;
  transition: all 0.25s;
  color: #ddd;
  font-size: 16px;
  font-weight: 200;
  text-decoration: underline;
  padding: 0.25rem;
  cursor: pointer;
  margin: 0px 10px 10px;
}
.KPI-live-block-cont .infoDiv .hide {
  opacity: 0;
  pointer-events: none;
}
.KPI-live-block-cont .infoDiv .display {
  display: inline;
  display: initial;
  transition: all 0.5s;
  pointer-events: none;
}
.KPI-live-block-cont .infoCloseBt {
  text-align: center;
  transition: all 0.25s;
  min-height: 20px;
  max-height: 20px;
  min-width: 20px;
  max-width: 20px;
  overflow: visible;
  margin: 4px 0px 0px 4px;
  color: #ddd;
  font-size: 16px;
  font-weight: 400;
  display: inline-block;
  vertical-align: middle;
  border-radius: 100%;
  text-decoration: none;
  cursor: pointer;
}
.KPI-live-block-cont .infoCloseBt:hover {
  color: rgb(255, 255, 255);
  transform: scale(1.33);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
}
.KPI-live-block-cont .icon-info-close {
  text-align: center;
  position: absolute;
  top: 0px;
  right: 4px;
}
.KPI-live-block-cont .icon-glossary {
  color: rgba(82, 31, 20, 0.9);
  text-align: center;
  border-radius: 10px;
  padding: 2px 10px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
  background-color: rgba(255, 255, 255, 0.3);
}
.KPI-live-block-cont .icon-glossary:hover {
  cursor: pointer;
}
.KPI-live-block-cont .tier2wrap .hide {
  opacity: 0;
  pointer-events: none;
}
.KPI-live-block-cont .infoDiv .hide {
  opacity: 0;
  pointer-events: none;
}
.KPI-live-block-cont .tier2wrap .nodisplay {
  display: none;
  transition: all 0.5s;
  pointer-events: none;
}
.KPI-live-block-cont .infoDiv .display {
  display: inline;
  display: initial;
  transition: all 0.5s;
  pointer-events: none;
}
.KPI-live-block-cont .infoCloseBt:hover {
  color: rgb(255, 255, 255);
  transform: scale(1.33);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
}
.KPI-live-block-cont .statName {
  display: inline-block;
  transition: all 0.5s;
  font-weight: 300;
  clear: right;
  margin-top: 5px;
  border-top: 1px solid transparent;
}
.KPI-live-block-cont .statName:not(.dimmed):hover {
  padding-top: 40px;
  margin-top: calc(5px - 40px) !important;
  color: white;
}
.KPI-live-block-cont .statName.dimmed {
  background: none;
}
.KPI-live-block-cont .statName span {
  border-bottom: 1px solid transparent;
  border-radius: 20px;
  padding: 0px 20px;
  transition: all 0.25s;
  display: inline-block;
  text-align: center;
}
.KPI-live-block-cont .odometer.dimmed {
  opacity: 0.1;
}
.KPI-live-block-cont .odometer * {
  text-align: center !important;
}
.KPI-live-block-cont .odometer:hover {
  cursor: pointer;
}
.KPI-live-block-cont .stat {
  color: rgb(255, 255, 255);
  font-size: 48px;
  display: inline-block;
  padding: 0 10px;
  box-sizing: border-box;
  width: 100%;
  position: relative;
  font-weight: 100;
}
.KPI-live-block-cont .statAlt {
  color: rgb(255, 255, 255);
  top: -10px;
  font-size: 48px;
  display: inline-block;
  padding: 0 10px;
  box-sizing: border-box;
  width: 100%;
  position: relative;
  font-weight: 100;
  cursor: pointer;
}
.KPI-live-block-cont .estimated {
  position: relative;
}
.KPI-live-block-cont .estimated::before {
  opacity: 0.7;
  content: "*";
  font-size: 180%;
  position: absolute;
  top: -5px;
  left: 0;
  display: block;
}
.KPI-live-block-cont .estimatedLegend {
  position: absolute;
  top: 15px;
  right: 40px;
  display: none;
  color: white;
  opacity: 0.7;
  padding-left: 15px;
  font-size: 120%;
}
.KPI-live-block-cont .estimatedLegend.visible {
  display: inline-block;
}
.KPI-live-block-cont .estimatedLegend .estimated {
  width: 10px;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}
.KPI-live-block-cont .estimatedLegend .estimated::before {
  opacity: 1;
  top: 0;
  font-size: 210%;
}
.KPI-live-block-cont .stat > .prefix {
  font-size: 24px;
  opacity: 0.8;
}
.KPI-live-block-cont .stat > .suffix {
  font-size: 24px;
}
.KPI-live-block-cont .statAlt > .prefixAlt,
.KPI-live-block-cont .statAlt > .suffixAlt {
  font-size: 24px;
  opacity: 0.8;
}
.KPI-live-block-cont .breakdown {
  background: hsla(1, 40%, 30%, 0.9);
  font-size: 16px;
  width: 100%;
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 5px;
  z-index: 1;
  display: block;
  opacity: 0;
  transition: opacity 0.25s;
  overflow: visible !important;
}
.KPI-live-block-cont .breakdown .clippy:hover {
  cursor: pointer;
}
.KPI-live-block-cont .invert .breakdown {
  background: hsla(1, 80%, 90%, 0.9);
}
.KPI-live-block-cont .breakdownStat {
  /* flex parent properties */
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: stretch;
  /* end flex parent properties */
  margin: 5px;
}

.hide {
  opacity: 0;
  pointer-events: none;
}
.filterLabel {
  cursor: pointer;
}

.filterRow {
  border: 1px solid rgba(98, 105, 255, 0.6);
}

.filterLabel {
  min-width: 100px;
}

.filterSelect {
  min-width: 200px !important;
}

.full-reset {
  height: 25px;
  border: 1px solid rgba(98, 105, 255, 0.6);
  box-shadow: 1px 1px 5px 1px rgba(98, 105, 255, 0.4);
}

.title-for-devices {
  font-size: 24px;
  color: rgb(217, 68, 38);
  position: absolute;
  top: 0px;
}
.tool-tip-attr {
  text-transform: capitalize;
}

.info-chart-div {
  cursor: pointer;
  position: relative;
  left: 120px;
  top: 15px;
  text-align: center;
  overflow: visible;
  color: white;
  font-size: 16px;
  font-weight: 400;
  vertical-align: middle;
  text-decoration: none;
  transition: all 0.5s;
  height: 20px;
  width: 20px;
  border-radius: 12px;
  transform: translate(-50%, -50%);
  z-index: 1009;
  background-position: center center;
  background-size: contain;
  background-color: rgb(98, 105, 255);
  background-image: url("/img/icon_info.png");
}

.tooltip-info {
  background-color: rgb(98, 105, 255);
  z-index: 1010;
  padding: 1rem;
  opacity: 0.8;
  font-size: 14px;
  text-align: left;
  border-radius: 10px;
  font-weight: 400;
  transition: all 0.5s;
  color: white;
}

.text-info-text {
  font-family: "Lucida Console", Monaco, monospace;
  font-weight: lighter;
}

.title-churn-cont {
  height: calc(100% - 40px);
  width: 100%;
}
.title-churn-cont .churn-title {
  color: rgb(51, 68, 255);
  width: 100%;
  text-align: center;
  font-size: 24px;
}

.full-churn-container {
  display: flex;
  height: 100%;
  width: 100%;
}
.full-churn-container .churn-title {
  font-size: 24px;
  text-align: center;
  width: 100%;
}
.full-churn-container .churn-charts-container {
  flex: 10 1;
}
.full-churn-container .churn-legend-cont {
  margin-left: -3%;
  flex: 2 1;
}

.y-axis-group {
  color: white;
}
.y-axis-group line {
  stroke-width: 1px;
  stroke: white;
  fill: white;
  opacity: 1;
}
.y-axis-group .domain {
  opacity: 0;
}

.upward-line-chart-line {
  pointer-events: none;
}
.upward-line-chart-line .line-path-upward {
  pointer-events: none;
}

.line-chart-line-g {
  pointer-events: none;
}
.line-chart-line-g .line-chart-line {
  pointer-events: none;
}

.bubble-tool-div {
  z-index: 5000;
  pointer-events: none;
}
.bubble-tool-div .tooltip {
  text-align: center;
  background-color: rgb(89, 87, 87);
  border: 1px solid white;
  padding: 10px;
  color: white;
  border-radius: 15px;
  opacity: 0.65;
}
.bubble-tool-div .tooltip .tooltip-title {
  text-transform: capitalize;
}
.bubble-tool-div .tooltip .tooltip-churn {
  padding-top: 5px;
  padding-bottom: 5px;
}
.bubble-tool-div .tooltip .tooltip-churn div {
  padding-bottom: 1px;
}
.bubble-tool-div .tooltip .tooltip-house {
  padding-bottom: 5px;
}

.x-axis-group {
  color: white;
  fill: white;
}
.x-axis-group line {
  stroke-width: 1px;
  stroke: white;
  fill: white;
  opacity: 1;
}
.x-axis-group .domain {
  opacity: 0;
  fill: none;
}

.x-axis-group .tick text {
  fill: white !important;
}

.x-axis-group .tick line {
  stroke: white !important;
  opacity: 1;
}

.churn-line-bubble-cont {
  width: 100%;
  height: 100%;
}

.circle-data-select {
  max-height: 30px;
  margin-left: 5px;
  max-width: calc(100% - 25px) !important;
  margin-top: 10px;
  background-color: #333333;
  color: white;
  border-radius: 15px;
  border: 1px solid #ffffff;
}

.churn-dash-main-cont {
  height: 95%;
  width: 95%;
}
.churn-dash-main-cont .area-chart-row-cont {
  background-color: rgb(30, 30, 30);
  padding-top: 12px;
  padding-left: 1%;
  padding-right: 1%;
  padding-bottom: 12px;
  margin: 12px 1% 12px 1%;
  border-radius: 10px;
  border: 2px solid white;
  height: 100%;
  width: 100%;
}
.churn-dash-main-cont .area-chart-row-cont .churn-line-bubble-cont {
  width: 100%;
  height: 100%;
}

.container-select-households {
  margin-right: 10px;
  color: white;
  cursor: pointer;
}
.container-select-households .select-households-item {
  margin: 5px;
  border: 1px solid white;
  border-radius: 15px;
  padding: 5px;
}
.filter-tool-container {
  opacity: 0.92;
  position: absolute;
  top: 70px;
  right: 130px;
  z-index: 6000;
  background-color: #c1bfbf;
  border: 1px solid white;
  border-radius: 10px;
  text-transform: uppercase;
  color: #4c4c4c;
  padding: 5px;
  padding-left: 15px;
}
.filter-tool-container .filter-title-environments {
  border-bottom: 1px solid black;
  color: black;
  padding-top: 10px;
  padding-bottom: 0px;
  margin-bottom: 2px;
}
.filter-tool-container .filter-selection-container {
  display: flex;
  max-width: 250px;
  min-width: 190px;
}
.filter-tool-container .filter-selection-container .filter-selection-column {
  flex: 1 1;
  display: flex;
  flex-direction: column;
}
.filter-tool-container .filter-show-none {
  margin-top: 10px;
  border-radius: 15px;
  padding: 5px;
  font-weight: bold;
  cursor: pointer;
}
.filter-tool-container .filter-show-none:focus {
  outline: 0;
}

.tool-hidden {
  display: none;
}

.filter-selection {
  line-height: 28px;
  cursor: pointer;
}
.filter-selection:hover {
  color: white;
}
.filter-selection:hover .filter-selection-input {
  border: 2px solid white;
  margin-right: 8px !important;
  vertical-align: middle;
  margin-left: -2px;
}

.filter-selection-input {
  vertical-align: middle;
  margin-right: 10px;
}

.hidden {
  display: none;
}

.filter-exit-icon {
  float: right;
  cursor: pointer;
  margin-top: 0px !important;
  margin-left: 0px !important;
  width: 25px;
  height: 25px;
}

.filter-icon-main {
  height: 35px;
  width: 35px;
}

.move-filter-icon {
  cursor: move;
  font-size: 10px;
}
.change-xy-container {
  margin-top: 10px;
}
.change-xy-container .change-xy-title {
  color: black;
  text-decoration: underline;
}
.change-xy-container .change-y-svg {
  margin: 0 !important;
  margin-left: -15px !important;
}
.change-xy-container .change-y-svg .tick line {
  opacity: 1;
}
.change-xy-container .change-y-svg path.domain {
  stroke: black;
}
.change-xy-container .selection {
  fill: white !important;
  fill-opacity: 1 !important;
}
.change-xy-container .overlay {
  fill: #c1bfbf !important;
  stroke-width: 1px;
  stroke: #676565;
  opacity: 0;
}
.change-xy-container .change-move-bars {
  text-align: left;
  font-weight: lighter;
  border: 1px solid #6f6f6f;
  padding: 3px;
  border-radius: 12px;
  opacity: 0.9;
  margin-top: 5px;
}
.change-xy-container .change-x-svg {
  margin: 0 !important;
}
.change-xy-container .change-x-svg .x-change-axis-g .tick line {
  opacity: 1;
}
.change-xy-container .max-min-y {
  vertical-align: top;
  font-weight: bold;
}
.change-xy-container .x-value {
  padding-top: 5px;
}

.x-bar-brush {
  pointer-events: none;
}

.y-bar-brush {
  pointer-events: none;
}

.y-axis-label-svg {
  margin-top: 0 !important;
  margin-left: 0 !important;
}

.x-axis-label-svg {
  margin-top: 0 !important;
  margin-left: 0 !important;
  font-weight: bold;
}

rect.selection {
  opacity: 0;
}

.y-change-axis-g .domain {
  opacity: 0;
}

.y-change-axis-g .tick line {
  opacity: 0 !important;
}

.x-change-axis-g .tick line {
  opacity: 0 !important;
}
.slider-container .slider-value {
  display: inline-block;
  vertical-align: top;
}
.slider-container input {
  display: inline-block;
  cursor: pointer;
  width: calc(100% - 50px);
  height: 3px;
  position: relative;
  bottom: 3px;
}
.title-cont-main {
  height: 100%;
  width: 100%;
}
.title-cont-main .title-treemap {
  color: #d94426;
  text-align: center;
  font-size: 24px;
}

.treemap-main-cont {
  display: flex;
  height: calc(100% - 30px);
  width: 100%;
  font-family: "wigrum-light";
  min-height: 800px;
}
.treemap-main-cont .left-controller-container {
  margin-top: 10px;
  flex: 3 1;
  display: flex;
  flex-direction: column;
  height: 95%;
}
.treemap-main-cont .left-controller-container .top-filler-cont {
  flex: 2 1;
}
.treemap-main-cont .left-controller-container .filters-controller-containers {
  flex: 1 1;
}
.treemap-main-cont .left-controller-container .donut-chart-container {
  width: 90%;
  height: 90%;
  mmin-width: 400px;
  min-height: 400px;
  flex: 4 1;
}
@media screen and (max-height: 850px) {
  .treemap-main-cont .left-controller-container .donut-chart-container {
    flex: 5 1;
  }
}
.treemap-main-cont .left-controller-container .donut-chart-container .main-donut-chart {
  text-align: center;
  margin-top: 5%;
}
.treemap-main-cont .treemap-cont {
  flex: 7 1;
  text-align: right;
  padding-right: 1%;
  font-size: 10px;
}
.treemap-main-cont .treemap-cont .treemap-element {
  width: 100%;
  height: 95%;
  background-color: rgb(69, 69, 69);
  box-shadow: -3px 3px 12px 0px #656262;
  margin-top: 10px;
}
.treemap-main-cont .treemap-cont .top-node-button {
  cursor: pointer;
  display: inline-block;
}
.treemap-main-cont .treemap-cont .back-node-arrow {
  display: inline-block;
  color: black;
  background-color: white;
  border-radius: 2px;
  font-size: 10px;
  margin-top: 1px;
  margin-left: 25px;
  border: 1px solid white;
  padding: 2px;
  cursor: pointer;
}
.treemap-main-cont .treemap-cont .back-node-arrow:active {
  background-color: #3636ff;
}
.treemap-main-cont .toggle-container {
  margin-top: 10px;
  width: 80%;
  margin-left: 5%;
  padding: 0px 5px 5px 5px;
}
.treemap-main-cont .toggle-container .react-toggle {
  margin-left: 0px !important;
}
.treemap-main-cont .toggle-container .deeper-toggle-cont {
  margin-top: 15px;
}
@media screen and (max-height: 850px) {
  .treemap-main-cont .toggle-container .deeper-toggle-cont {
    margin-top: 0px;
  }
}
.treemap-main-cont .toggle-container .toggle-title {
  padding-bottom: 5px;
}
.treemap-main-cont .treemap-slider-cont {
  margin-top: 50px;
  width: 90%;
  margin-left: 5%;
  padding: 5px 5px 5px 5px;
  padding-bottom: 15px;
  border-bottom: 1px solid white;
}
@media screen and (max-height: 850px) {
  .treemap-main-cont .treemap-slider-cont {
    margin-top: 0px;
    padding-top: 0px;
  }
}
.treemap-main-cont .treemap-slider-cont .slider-title--cont {
  padding-top: 10px;
  margin-bottom: 5px;
}
.treemap-main-cont .unknown-toggle-cont {
  display: inline-block;
  width: 45%;
}
.treemap-main-cont .deeper-toggle-cont {
  display: inline-block;
  width: 45%;
}
.treemap-main-cont .treemap-display {
  margin-left: 5%;
  padding: 10px;
  text-align: center;
  margin-right: 5%;
  text-transform: capitalize;
}
@media screen and (max-height: 850px) {
  .treemap-main-cont .treemap-display {
    font-size: 14px;
  }
}
.treemap-main-cont .treemap-display .display-level {
  padding: 5px;
  border: 1px solid white;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: #292828;
}
.treemap-main-cont .treemap-display .display-amount-cont {
  margin-top: 25px;
  border: 1px solid #292828;
  padding: 10px;
  text-align: center;
  background-color: white;
  color: black;
}
@media screen and (max-height: 850px) {
  .treemap-main-cont .treemap-display .display-amount-cont {
    margin-top: 0px;
    padding: 5px;
  }
}
.treemap-main-cont .donut-chart-text {
  pointer-events: none;
}

.donut-tooltip {
  pointer-events: none;
  text-align: center;
  background-color: white;
  color: black;
  z-index: 10001;
  opacity: 0.8;
  padding: 10px;
  border-radius: 15px;
}
.donut-tooltip .tool-tip-title {
  margin-bottom: 10px;
}

.slider-container input[type=range]::-moz-range-track {
  height: 3px;
  cursor: pointer;
  background: #aec7e8;
}
.KPIDash-SChart {
  color: White;
  font-family: "Raleway", sans-serif;
}
.KPIDash-SChart .KPI-series-yAxis {
  font-size: 20px;
  font-family: "Raleway", sans-serif;
}
.kpi-series-popup {
  display: block;
  position: absolute;
  width: 200px;
  height: 45px;
  background: rgba(255, 255, 255, 0.9);
  border: 2px solid rgba(150, 150, 150, 0.9);
  border-radius: 15px;
  z-index: 40000;
  font-size: 20px;
  line-height: 25px;
  padding: 10px;
  text-transform: capitalize;
}
.KPIDash-SChart {
  color: White;
  font-family: "Raleway", sans-serif;
}
.KPIDash-SChart .KPI-series-yAxis {
  font-size: 20px;
  font-family: "Raleway", sans-serif;
}
.KPIDash-chart-legend {
  color: rgb(180, 180, 180);
  text-transform: capitalize;
  line-height: 20px;
  padding: 10px;
  border-radius: 5px;
  text-align: right;
  margin-right: 15px;
  min-height: 214px;
}

.KPI-legend-item {
  height: 20px;
  width: 20px;
  display: inline-block;
  border-radius: 20px;
  position: relative;
  left: 5px;
  top: 3px;
}
.KPI-filters-filters-button {
  margin: 10px;
  height: 25px;
  color: rgb(130, 130, 130);
  border: 1px solid rgb(110, 110, 110);
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.KPI-filters-filters-button:hover {
  color: white;
  border: 1px solid white;
}
.KPI-filter-depl-name {
  text-transform: capitalize;
}

.KPI-filter-depl-circle {
  height: 20px;
  width: 20px;
  border-radius: 20px;
  background: white;
  display: inline-block;
  position: relative;
  margin-right: 5px;
  top: 4px;
  border: 1px solid rgb(130, 130, 130);
}

.KPI-filter-depl {
  cursor: pointer;
}
.KPI-filter-depl:hover {
  color: white;
}
.KPI-filter-depl:hover .KPI-filter-depl-circle {
  border: 1px solid white;
  top: 4px;
}

.circle-off {
  background: rgba(0, 0, 0, 0) !important;
}
.KPI-filter-deployments .KPI-filter-deployment-list-cont {
  display: flex;
  width: 100%;
}
.KPI-filter-deployments .KPI-filter-deployment-list-cont .KPI-filter-depl-list {
  flex: 1 1;
}
.KPI-filter-header {
  width: 100%;
  height: 15px;
}
.KPI-filter-header .KPI-filter-close {
  font-size: 30px;
  line-height: 10px;
  display: inline-block;
  float: right;
  cursor: pointer;
}
.KPI-chart-filter-cont {
  color: rgb(175, 175, 175);
  position: absolute;
  width: 200px;
  right: 10px;
  background: rgb(35, 35, 35);
  opacity: 1;
  z-index: 2000;
  padding: 5px;
  border-radius: 10px;
}

.hidden-filter {
  display: none;
}
.KPIDash-series-pg {
  width: 100%;
  height: calc(100vh - 40px);
  display: flex;
  flex-direction: column;
}
.KPIDash-series-pg .KPIDash-series-title {
  flex: 1 1;
  color: red;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}
.KPIDash-series-pg .KPIDash-series-chart-legend-cont {
  flex: 23 1;
  min-width: 300px;
  display: flex;
}
.KPIDash-series-pg .KPIDash-series-chart-legend-cont .KPIDash-SChart {
  flex: 11 1;
}
.KPIDash-series-pg .KPIDash-series-chart-legend-cont .KPIDash-legend-options-cont {
  flex: 1 1;
  margin-top: 40px;
  min-width: 130px;
}
.KPIDash-series-pg .KPI-dash-series-toggles .KPI-dash-series-toggle {
  height: 35px;
  color: rgb(175, 175, 175);
  line-height: 45px;
}
.KPIDash-series-pg .KPI-dash-series-toggles .KPI-dash-series-toggle span {
  position: relative;
  bottom: 3px;
}

.kpi-series-popup {
  display: block;
  position: absolute;
  width: 200px;
  height: 45px;
  background: rgba(255, 255, 255, 0.9);
  border: 2px solid rgba(150, 150, 150, 0.9);
  border-radius: 15px;
  z-index: 40000;
  font-size: 20px;
  line-height: 25px;
  padding: 10px;
  text-transform: capitalize;
}
.people-chord-conatainer {
  height: calc(100vh - 40px);
  width: 100%;
  max-height: 1350px;
}

.people-chord-div .people-chord-svg .people-arc {
  cursor: pointer;
}
.people-chord-div .people-chord-svg .ribbon-hidden {
  opacity: 0.01;
}
.people-chord-div .people-chord-svg .ribbon-visible { /* Safari, Chrome and Opera > 12.1 */ /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
  animation: fadein 0.5s;
  /* Firefox < 16 */
  /* Safari, Chrome and Opera > 12.1 */
  /* Internet Explorer */
  /* Opera < 12.1 */
  opacity: 0.55;
}
@keyframes fadein {
  from {
    opacity: 0.01;
  }
  to {
    opacity: 0.55;
  }
}
.people-chord-div .people-chord-svg .ribbon-hide { /* Safari, Chrome and Opera > 12.1 */ /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
  animation: fadeout 0.5s;
  /* Firefox < 16 */
  /* Safari, Chrome and Opera > 12.1 */
  /* Internet Explorer */
  /* Opera < 12.1 */
  opacity: 0.01;
}
@keyframes fadeout {
  from {
    opacity: 0.55;
  }
  to {
    opacity: 0.01;
  }
}
.people-chord-div .people-chord-svg .chord-title {
  font-size: 40px;
  color: white;
}
.people-chord-div .people-chord-svg .ribbon-line-paths {
  pointer-events: none;
}
.people-chord-div .people-chord-svg .label-group text {
  cursor: pointer;
}
.people-chord-div .people-chord-svg .label-group circle {
  pointer-events: none;
}
.people-chord-div .people-chord-svg .label-group path {
  pointer-events: none;
}
.people-chord-div #first-toggle-label {
  margin-left: 20%;
  font-weight: lighter;
}
.people-chord-div text {
  font-weight: lighter;
}
.people-chord-div #second-toggle-label {
  margin-left: 1%;
}
.people-chord-div text {
  font-weight: lighter;
}
.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
   vertical-align: sub;
    margin-left: 10px;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #4D4D4D;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
  background-color:#9da59e ;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #9da59e;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid #4D4D4D;
  border-radius: 50%;
  background-color: #FAFAFA;
  box-sizing: border-box;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: #9da59e;
}

.people-chord-tool {
  position: absolute;
  z-index: 1001;
  pointer-events: none;
  background-color: white;
  border-radius: 6px;
  opacity: 0.8;
}

.react-popup-component {
  background-color: white;
  max-width: 200px;
  text-align: center;
  opacity: 0.8;
}
.ratings-radial-main {
  display: flex;
}
.ratings-radial-main .radial-chart-container {
  text-align: center;
  display: flex;
  flex: 5 1;
}
.ratings-radial-main .right-legend-bullet-container {
  flex: 2 1;
  display: flex;
  flex-flow: column;
}
@media screen and (max-width: 1130px) {
  .ratings-radial-main .right-legend-bullet-container {
    display: none;
  }
}
.ratings-radial-main .right-legend-bullet-container .radial-legend-container {
  flex: 3 1;
}
.ratings-radial-main .right-legend-bullet-container .right-legend-filler {
  flex: 5 1;
  margin-top: 20px;
}
.ratings-radial-main .right-legend-bullet-container .right-legend-filler #word-cloud-title {
  color: white;
  margin-left: 15px;
  text-decoration: underline;
}
.ratings-radial-main .right-legend-bullet-container .bullet-chart-container {
  flex: 3 1;
}
.ratings-radial-main .right-legend-bullet-container .bullet-chart-container #bullet-chart-title {
  color: white;
  text-decoration: underline;
}
.ratings-radial-main .right-legend-bullet-container .flex-filler-right-radial {
  flex: 1 1;
}

.amazon-class {
  fill: #f08804;
}

.IOs-class {
  fill: rgb(0, 122, 255);
}

.android-class {
  fill: #A4C639;
}

.ANDROID-class {
  stroke: #A4C639;
}

.IOS-class {
  stroke: rgb(0, 122, 255);
}

.AMAZON-class {
  stroke: #f08804;
}

.axis-label-text {
  font-weight: 300;
}

.year-select-ratings {
  width: 10%;
  height: 30px;
  display: inline-block;
}
.year-select-ratings select {
  background-color: #333333;
  color: white;
}

.select-component-div {
  flex: 2 1;
  flex-flow: column;
  display: flex;
}
.select-component-div .select-component {
  width: 50%;
  min-height: 20px;
  flex: 1 1;
  margin-left: 35px;
  margin-top: 2px;
}
.select-component-div .select-component select {
  max-height: 30px;
  width: 100% !important;
  background-color: #333333;
  color: white;
  border-radius: 15px;
  border: 1px solid #ffffff;
}
.select-component-div .left-flex-filler {
  flex: 1 1;
}
.select-component-div .left-legend-visual {
  margin-top: 15px;
  margin-left: 25px;
  flex: 50 1;
}

.radial-ordinal-g {
  fill: white;
}

.radial-ordinal-lines {
  fill: white;
}

.show-radial-point { /* Safari, Chrome and Opera > 12.1 */ /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
  animation: fadein 0.5s;
  /* Firefox < 16 */
  /* Safari, Chrome and Opera > 12.1 */
  /* Internet Explorer */
  /* Opera < 12.1 */
  opacity: 1;
}
@keyframes fadein {
  from {
    opacity: 0.1;
  }
  to {
    opacity: 0.55;
  }
}

.hide-radial-point {
  opacity: 0.1;
  pointer-events: none;
}

.radial-ordinal-g .cell {
  cursor: pointer;
}

.radial-ordinal-lines .cell {
  cursor: pointer;
}

.radial-point {
  cursor: pointer;
}

.radial-tooltip-on {
  position: absolute;
}

.radial-tooltip-off {
  display: none;
}

.radial-plot-tooltip {
  background-color: white;
  min-width: 250px;
  opacity: 0.75;
  text-align: center;
  z-index: 1005;
  padding: 5px;
  border-radius: 7px;
  pointer-events: none;
}

.radial-dashboard-title {
  color: rgba(51, 64, 204, 0.9);
  text-align: center;
  font-size: 24px;
  margin-top: 5px;
  max-height: 24px;
  width: 100%;
  display: block;
}

.legend-rect-shadow {
  opacity: 0.65;
}
.legend-rect-shadow:hover {
  opacity: 1;
}

.x-axis-group .domain {
  opacity: 0;
}
.x-axis-group .tick text {
  fill: grey;
}
.x-axis-group .tick line {
  stroke: grey;
}

.legendCells .cell text {
  pointer-events: none;
}
.legendCells .cell circle {
  pointer-events: none;
}
.legendCells .cell rect.swatch {
  pointer-events: none;
}

.info-circle {
  background-image: url(/static/media/info.cfe90d37.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-color: lightgrey;
}

.center-hover-arrow {
  fill: white;
}
.center-hover-arrow:hover {
  cursor: pointer;
  fill: blue;
}
.center-hover-arrow:hover svg {
  fill: blue;
}
.radial-responsive-container {
  width: 100%;
  height: calc(100vh - 45px);
}
/* GREY COLORS */
/* END OF GREY COLORS */
/* Large devices (laptops/desktops, 992px and up) */
.menuFullContainer {
  color: "white";
  min-width: 446px;
  position: relative;
  right: 55px;
  display: flex;
}
.menuFullContainer .arrows-and-timeslider {
  display: inline-block;
}
.menuFullContainer .menu-arrow {
  display: inline-block;
  margin-top: 34px;
  height: 18px;
  width: 20px;
  font-size: 75%;
  padding-top: 2px;
  text-align: center;
  border-radius: 100%;
  cursor: pointer;
}
.menuFullContainer .menu-arrow:hover {
  background-color: rgba(106, 109, 119, 0.339);
}
.menuFullContainer .left-arrow-controller {
  margin-left: 25px;
}
.menuFullContainer .triple-left-arrow-controller {
  margin-left: 15px;
}
.menuFullContainer .right-arrow-controller {
  margin-right: 10px;
  margin-left: 0px;
}
.menuFullContainer .left-arrow-controller {
  margin-left: 10px;
  margin-right: 0px;
}
.menuFullContainer .triple-right-arrow-controller {
  margin-right: 15px;
}
.menuFullContainer .arrow-hidden {
  display: none;
}
.menuFullContainer .caretMenu {
  /* @media screen and (max-width: 992px) {
         pointer-events: none;
       }
  */
  display: inline-block;
  top: 0px;
  z-index: 10000;
  left: 50%;
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
}
.menuFullContainer .caretMenu:hover .menuCaret {
  opacity: 1;
  background-size: 100%;
}
.menuFullContainer .caretMenu .menuCaret {
  text-align: center;
  display: inline-block;
  position: relative;
  top: 5px;
  left: 5px;
  min-width: 25px;
  color: rgba(255, 255, 255, 0.7);
  height: 15px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAdCAYAAADoxT9SAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAQhJREFUeNrslmsNg0AQhDkHSEACDoqD4qCVgAQcIKESoAqKA3BAHSDhOpcsYdMAPejxSvZLJveDR2aPYcDzBEEQBMEBWusIukP+SfyGUDp0INU9LyiBgoOZj6EH1HRGfw3CMRdl5ontYDyglOQj3mYNwmlpR1aLYBcZqLLws3iQb5xEcCgytrgaZFEEbSKz5yCTEZwbmX8GiWiHWu2eZoV7Vrx+1VhmsVwgsx6pggvoCZVKqTc/oGxaBIvJ/Q0KNzZuzJbGPIwXUyeqme3i01O60nBrVHBNu17AfL3llzZz8A7kVAz7x5haKbFspYZaLT76D53PvhMtbxl65077Zx14giAIAuMjwAB7OQeg+lpvcgAAAABJRU5ErkJggg==);
  background-repeat: no-repeat;
  background-size: 75%;
  background-position: center center;
  opacity: 0.5;
  transition: all 0.25s;
}
@media screen and (max-width: 992px) {
  .menuFullContainer .caretMenu .menuCaret {
    display: none;
  }
}
.menuFullContainer .caretMenu .menuCaret:hover {
  opacity: 1;
  background-size: 100%;
  cursor: pointer;
}
.menuFullContainer .caretMenu .menuRange {
  margin-right: 30px;
  padding-left: 5px;
  padding-right: 5px;
  position: relative;
  top: 3px;
  text-align: left;
  display: inline-block;
  font-size: 90%;
  color: rgba(255, 255, 255, 0.5);
}
@media screen and (max-width: 992px) {
  .menuFullContainer .caretMenu .menuRange {
    display: none;
  }
}
.menuFullContainer .caretMenu .menuGrain {
  padding-left: 15px;
  text-align: right;
  top: 10px;
  left: 0px;
  display: inline-block;
  font-size: 90%;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.5);
  text-transform: capitalize;
}

.headerMenu {
  height: 52px;
  overflow: visible;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  text-align: left;
  color: white;
  padding: 10px 20px 10px 20px;
  background-image: linear-gradient(180deg, rgba(26, 26, 26, 0.8), rgba(47, 51, 51, 0.8));
  border-radius: 0 0 10px 10px;
  z-index: 10;
  transition: all 0.3s;
  box-shadow: 0 2px 5px rgba(26, 26, 26, 0.3);
  opacity: 1;
}
@media screen and (max-width: 992px) {
  .headerMenu {
    display: none;
  }
}
.headerMenu.hidden {
  opacity: 0;
  transform: translate(-50%, -100%);
}
.headerMenu.open {
  margin-top: 40px;
  box-shadow: 0 2px 10px hsla(196, 0%, 10%, 0.7);
}
.headerMenu.open .range {
  max-height: 1000px;
  opacity: 1;
  margin: 5px 5px;
}
.headerMenu.open .range:first-of-type {
  margin-top: 0;
}

#rangeArea .range .timeframe {
  width: 33%;
  padding: 0 0;
  font-weight: 100;
  text-align: left;
  white-space: nowrap;
}

#rangeArea .range {
  width: auto;
  display: flex;
  margin: 0px 5px;
  padding: 0 75px 0 25px;
  position: relative;
  text-align: right;
  cursor: pointer;
}

#rangeArea .range .grain {
  width: 33%;
  padding: 0 0.5rem;
  font-weight: 500;
  text-align: left;
  overflow: hidden;
  margin-right: 2rem;
}

#rangeArea {
  flex-basis: 25%;
  flex-grow: 1;
  background-color: #202020;
  border-radius: 1rem;
  padding: 0.25rem 0;
  margin-top: 28px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
}
#rangeArea.hidden {
  display: none;
}

#rangeArea svg line.track {
  stroke-opacity: 0.4;
  stroke-width: 10px;
  stroke-linecap: round;
  stroke: black;
}

.axis .tick text {
  text-anchor: middle;
  font-size: 10px;
  font-family: sans-serif;
}

#rangeArea {
  min-width: 337.078px;
}

.currentRange-container {
  webkit-flex-basis: 25%;
  flex-basis: 25%;
  flex-grow: 1;
  background-color: #202020;
  border-radius: 1rem;
  padding: 0.25rem 0;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
  display: flex;
  margin: 0px 5px;
  padding: 0 75px 0 25px;
  position: relative;
  text-align: right;
  cursor: pointer;
}
.currentRange-container .range {
  width: auto;
  display: flex;
  margin: 0px 5px;
  padding: 0 75px 0 25px;
  position: relative;
  text-align: right;
  cursor: pointer;
}
.currentRange-container .grain {
  width: 33%;
  padding: 0 0.5rem;
  font-weight: 500;
  text-align: right;
  overflow: hidden;
  margin-left: 16.5%;
  margin-right: 2rem;
}
.currentRange-container .timeframe {
  width: 33%;
  padding: 0 0;
  font-weight: 100;
  text-align: left;
  white-space: nowrap;
}
.currentRange-container .range.active {
  min-height: 20px;
  max-height: 1000px;
  opacity: 1;
  color: white;
}

.range.disabled {
  opacity: 0.25 !important;
  cursor: not-allowed !important;
}

.disabled {
  opacity: 0.25;
  pointer-events: none;
}

.display-left-arrow {
  opacity: 0.8;
  display: inline-block;
}

.display-right-arrow {
  display: inline-block;
  opacity: 0.8;
  cursor: pointer;
  z-index: 1001;
}

.top-container {
  min-width: 350px;
  position: relative;
  top: -15px;
  left: 30px;
}

.date-dot-container {
  min-width: 180px;
}
.date-dot-container .range-area-main-container {
  display: inline-block;
}
.date-dot-container .range-area-main-container #menu-range-area {
  min-width: 185px;
  margin-left: 0px;
}
.date-dot-container .dot-date-picker {
  pointer-events: all;
  position: absolute;
  top: 39px;
  left: 35px;
  height: 10px;
  width: 10px;
  background-color: rgba(227, 227, 227, 0.8);
  border-radius: 50%;
  display: inline-block;
  border: 1px solid rgb(163, 163, 163);
  z-index: 10000000;
}
.date-dot-container .dot-date-picker:hover {
  height: 14px;
  width: 14px;
  top: 37.5px;
  left: 33px;
  background-color: rgb(255, 255, 255);
}

.open-range #menu-range-area {
  border: 1px solid rgb(184, 182, 182);
  border-radius: 15px;
  top: 30px;
  left: 25px;
  background-color: rgba(26, 26, 26, 0.95);
}
.open-range #menu-range-area .range:hover {
  color: white;
}

#menu-range-area {
  position: absolute;
  top: 30.5px;
  left: 34px;
  font-family: "Raleway", sans-serif;
  font-size: 14.4px;
  font-weight: 400;
}
#menu-range-area .range {
  min-width: 126px;
  padding: 5px;
}
#menu-range-area .grain {
  display: inline-block;
  margin-right: 5px;
  margin-left: 15px;
}
#menu-range-area .timeframe {
  display: inline-block;
}

.dot-date-picker:hover .menuCaret {
  background-size: 75% !important;
  opacity: 0.5 !important;
}
