.filterLabel{
  cursor:pointer;
}
.filterRow{
  border: 1px solid rgba(98, 105, 255, 0.6);
}
.filterLabel{
  min-width:100px;

}
.filterSelect{
  min-width:200px !important;
}


  .full-reset{
    height:25px;
    border: 1px solid rgba(98, 105, 255, 0.6);
    box-shadow: 1px 1px 5px 1px rgba(98, 105, 255, 0.4);
  }

.title-for-devices{
  font-size: 24px;
  color:rgb(217, 68, 38);
  position: absolute;
  top: 0px;
}
