@import "../../containers/variables";
@import "../../styles/global/_Color.scss";

/* Large devices (laptops/desktops, 992px and up) */

.menuFullContainer{
   color: 'white';
   min-width:446px;

   position:relative;
   right:55px;
  display: flex;

.arrows-and-timeslider{
  display:inline-block;
}


 .menu-arrow{
   display:inline-block;
   margin-top: 34px;
   height: 18px;
   width:20px;
   font-size: 75%;
   padding-top:2px;
   text-align: center;
   border-radius: 100%;



   cursor:pointer;
   &:hover{
     background-color: rgba(106, 109, 119,0.339);


   }
 }

 .left-arrow-controller{
   margin-left: 25px;
 }

 .triple-left-arrow-controller{
   margin-left: 15px;
 }

.right-arrow-controller{
  margin-right: 10px;
  margin-left: 0px;
}

.left-arrow-controller{
  margin-left: 10px;
  margin-right: 0px;
}

 .triple-right-arrow-controller{
   margin-right: 15px;
 }

 .arrow-hidden{
   display:none;
 }

   .caretMenu{
       // width: 135px;
       // height: 35px;
    /* @media screen and (max-width: 992px) {
       pointer-events: none;
     }
*/
       display:inline-block;
       top: 0px;
       z-index: 10000;
       left: 50%;

       color: rgba(255, 255, 255, 0.7);
       cursor: pointer;
       &:hover .menuCaret{
         opacity: 1;
         background-size: 100%;

       }


  .menuCaret {
    text-align:center;
    display: inline-block;
    position: relative;
    top:5px;
    left:5px;


    @media screen and (max-width: 992px) {
      display: none;
    }

    min-width:25px;
    color: rgba(255, 255, 255, 0.7);
    height: 15px;
    background: url("../../static/images/menu_chevron.png");
    background-repeat: no-repeat;
    background-size: 75%;
    background-position: center center;
    opacity: .5;
    transition: all .25s;
    &:hover {
      opacity: 1;
      background-size: 100%;
      cursor: pointer;

    }
  }



 .menuRange{
   margin-right:30px;
   padding-left:5px;
   padding-right:5px;
   position: relative;
   top: 3px;
   text-align: left;
   display:inline-block;
   font-size: 90%;
   color: rgba(255, 255, 255, 0.5);
   @media screen and (max-width: 992px) {
     display: none;
   }
 }

 .menuGrain {

    padding-left:15px;

   text-align:right;
    top: 10px;
    left:0px;
    display:inline-block;
    font-size: 90%;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.5);
    text-transform: capitalize;
   }

 }
}


.headerMenu{
  height: 52px;

    overflow: visible;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    text-align: left;
    color: white;
    padding: 10px 20px 10px 20px;
    background-image: linear-gradient(180deg, rgba(26, 26, 26, 0.8), rgba(47, 51, 51, 0.8));
    border-radius: 0 0 10px 10px;
    z-index: 10;
    transition: all 0.3s;
    box-shadow: 0 2px 5px rgba(26, 26, 26, 0.3);
    opacity: 1;

  @media screen and (max-width: 992px) {
    display: none;
  }



  &.hidden {
      opacity: 0;
      transform: translate(-50%,-100%);
    }
    &.open {
      // width:990px;
      margin-top:40px;
      // background-color: hsla($globalHue, 0%, 20%, 1);
      // background-image: linear-gradient(
      //   180deg,
      //   hsla($globalHue, 5%, 20%, 1),
      //   hsla($globalHue, 0%, 10%, 1)
      // );
      box-shadow: 0 2px 10px hsla($globalHue, 0%, 10%, 0.7);
      .range {
        max-height: 1000px;
        opacity: 1;
        margin: 5px 5px;
        &:first-of-type {
          margin-top: 0;
        }
      }
    }
}


#rangeArea .range .timeframe {
  width: 33%;
  padding: 0 0;
  font-weight: 100;
  text-align: left;
  white-space: nowrap;

}

#rangeArea .range {
  width: auto;
  display: flex;
  // max-height: 0;
  // overflow: hidden;
  // opacity: 0;
  margin: 0px 5px;
  padding: 0 75px 0 25px;
  position: relative;
  text-align: right;
  cursor:pointer;
}

#rangeArea .range .grain {
  width: 33%;
  padding: 0 0.5rem;
  font-weight: 500;
  text-align: left;
  overflow: hidden;
  margin-right: 2rem;

}

#rangeArea {
  flex-basis: 25%;
  flex-grow: 1;
  background-color: #202020;
  border-radius: 1rem;
  padding:.25rem 0;
  margin-top:28px;
  &.hidden {
    display: none;
  }
  box-shadow: 5px 5px 5px rgba(0,0,0,.3);
}

#rangeArea svg line.track{
  stroke-opacity: 0.4;
  stroke-width: 10px;
  stroke-linecap: round;
  stroke:black;
}

.axis{
  .tick{
    text{
    text-anchor:middle;

    font-size: 10px;
    font-family: sans-serif;
    }
  }
}

#rangeArea{
  min-width: 337.078px;
}

 .currentRange-container{

   webkit-flex-basis: 25%;
   flex-basis: 25%;
   -webkit-flex-grow: 1;
   flex-grow: 1;
   background-color: #202020;
   border-radius: 1rem;
   padding: .25rem 0;
   box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
   display: flex;
   margin: 0px 5px;
   padding: 0 75px 0 25px;
   position: relative;
   text-align: right;
   cursor: pointer;


   .range{
      width: auto;
      display: -webkit-flex;
      display: flex;
      margin: 0px 5px;
      padding: 0 75px 0 25px;
      position: relative;
      text-align: right;
      cursor: pointer;
   }

    .grain{
      width: 33%;
    padding: 0 0.5rem;
    font-weight: 500;
    text-align: right;
    overflow: hidden;
    margin-left: 16.5%;
    margin-right: 2rem;
    }
    .timeframe{
      width: 33%;
      padding: 0 0;
      font-weight: 100;
      text-align: left;
      white-space: nowrap;
    }

    .range.active{
      min-height: 20px;
      max-height: 1000px;
      opacity: 1;
      color: white;
    }




  }


  .tick line{
  
  }

  .range.disabled{
    opacity:.25 !important;
    cursor:not-allowed !important;
  }

  .disabled{
    opacity:0.25;
    pointer-events: none;
  }

  .display-left-arrow{

    opacity:0.8;
    display:inline-block;
  }

  .display-right-arrow{
    display:inline-block;
    //margin-left:50px !important;
    opacity:0.8;
    cursor:pointer;
    z-index:1001;
  }

  .top-container{
    min-width:350px;
    position: relative;
    top: -15px;
    left:30px;


  }

  .date-dot-container{
    min-width:180px;

    .range-area-main-container{
      display:inline-block;

      #menu-range-area{
        min-width:185px;
        margin-left:0px;
        &:hover{

        }
      }
    }
    .dot-date-picker{

      pointer-events: all;
      position: absolute;
      top:39px;
      left:35px;
      height: 10px;
      width: 10px;
      background-color: rgba(227, 227, 227,0.8);
      border-radius: 50%;
      display: inline-block;
      border: 1px solid rgb(163, 163, 163);
      z-index: 10000000;
      &:hover{
        height:14px;
        width:14px;
        top:37.5px;
        left:33px;
        background-color:rgba(255, 255, 255,1);

     }
  }
}

.open-range #menu-range-area{
  border: 1px solid rgb(184, 182, 182);
  border-radius: 15px;
  top:30px;
  left:25px;
  background-color:rgba(26, 26, 26,.95);
     .range{
      &:hover{
        color:white;
      }
    }
}


  #menu-range-area{
    position: absolute;
    top:30.5px;
    left:34px;
    font-family: "Raleway", sans-serif;
    font-size: 14.4px;
    font-weight:400;
    .range{
      min-width:126px;
      padding:5px;


    }
    .grain{
      display:inline-block;
      margin-right:5px;
      margin-left: 15px;
    }

    .timeframe{
      display: inline-block;
    }
  }

  .dot-date-picker:hover .menuCaret {
    background-size: 75% !important;
    opacity: .5 !important;
  }
