@import "../../containers/variables";

#devices-bubbles {
  text {
    text-anchor: middle;
    fill: $white;
    alignment-baseline: middle;
    transition: all 0.5s;
    pointer-events: none;
    font-size: 12px;
    &.activated {
      opacity: 1 !important;
      &.label {
        font-size: 24px;
      }
      &.count {
        font-size: 18px;
        /* opacity: .7; */
      }
    }
  }
  circle {
    transition: opacity 0.5s;
    opacity: 0.6;
    &.exiting{
      fill: #b26745;
    }
  }
  .circleActivated > circle {
    opacity: 0.3;
    &.activated {
      opacity: 1;
    }
  }
  #stats {
    position: absolute;
    top: 20px;
    right: 20px;
    color: $white;
    text-align: right;
    > .stat {
      font-size: 24px;
      color: rgba(255, 255, 255, 0.5);
      margin-bottom: 20px;
      > div {
        font-size: 64px;
        color: rgba(255, 255, 255, 1);
      }
    }
  }

  /* controls */
  #controls {
    position: absolute;
    top: 20px;
    left: 20px;
    color: $white;
    z-index: 2;
  }
  button {
    padding: 0px 10px;
    background: hsla($globalHue, 40%, 40%, .9);
    /* border: 1px solid rgba(255,255,255,.5); */
    color: $white;
    border: none;
    border-radius: 20px;
    margin: 0px;
    outline: none;
    transition: opacity 0.25s;
    cursor: pointer;
    &.filterReset{
      opacity: 0;
      margin-left: 5px;
      pointer: cursor;
      &.visible{
        opacity: 1;
      }
      &.disabled{
        display: none;
      }
    }
    &.deployment {
      text-transform: capitalize;
      opacity: 0.5;
      &.activated {
        opacity: 1;
      }
    }
  }
  line {
    &.track {
      stroke: none;
    }
    &.track-inset {
      stroke: rgba(255, 255, 255, 0.3);
    }
  }
  .parameter-value path {
    stroke: none;
  }
  #filterControls{
    position: absolute;
    z-index: 3;
    top: 20px;
    left: 20px;
    .filterRow{
      display: flex;
      flex-wrap: nowrap;
      color: $white;
      background: hsla($globalHue, 30%, 90%, .1);
      margin: 0 0 5px 0;
      border-radius: 20px;
      padding: 5px 10px;
      display: flex;
      transition: all .25s;
      &.rowActive{
        background: hsla($globalHue, 40%, 40%, .9);
        margin-left: -10px;
        margin-right: -10px;
        select{
          // display: none;
        }
        .filterLabel{
          font-weight: bold;
        }
      }
    }
    .filterLabel{
      flex-grow: 1;
      padding-right: 10px;
      text-transform: capitalize;
    }
    .filterSelect{
      flex-grow: 2;
      transition: opacity .5s;
      max-width: 200px;
      &.disabled{
        display: none;
      }
    }
  }

  .search {
    width: 100%;
    border-radius: 5px;
  }

  .filterRow {
    .disabled{
      display: none;
    }
    .visible{
      opacity: 1;
    }
  }

  #search-button {
    width: 20%;
  }

  #search-input {
    margin-right: 10px;
  }
}
