@import "../../containers/variables";

#choropleth-nodes {
  .countryPath {
    /* fill: none; */
    stroke: hsla($globalHue, 80%, 20%, 1);
    stroke-linejoin: round;
  }
  /* .countryPath:hover ~ .countryMetadata{
    opacity: 1;
  } */
  .countryMetadata {
    pointer-events: none;
    text-anchor: middle;
    fill: hsla($globalHue, 90%, 90%, 1);
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 24px;
    &.activated {
      opacity: 1;
    }
  }
  .countryFlag {
    opacity: 0.5;
  }
  .legendThreshold {
    font-size: 12px;
    font-family: sans-serif;
    text-transform: capitalize;
  }
  .caption {
    fill: $white;
    text-anchor: start;
    font-weight: bold;
  }
  .label {
    fill: $white;
  }
}
