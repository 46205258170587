#polar-radar-ring {
  .axis{
    .domain{
      stroke: rgba(255,255,255,.3);
    }
  }
  .tick{
    line{
      stroke: rgba(255,255,255,.3);
    }
    text{
      fill: rgba(255,255,255,.3);
    }
    .tickRing{
      stroke: rgba(255,255,255,.1);
      stroke-dasharray: 3;
      stroke-linecap: round;
    }
  }

}
