.KPIDash-SChart{
  color:White;
  font-family: "Raleway", sans-serif;
  .KPI-series-yAxis{
    font-size: 20px;
    font-family: "Raleway", sans-serif;
  }
  .KPI-series-xAxis{
    
  }
}
