.profile {
  &__page {
    height: 100%;
    display: flex;
    flex-direction: row;
    background: $grey_22;
    z-index: 10;

    .profile-background {
     // width: calc(50vw + 40px);
      width: 100%;
      height: 100%;
      z-index: 11;
      display: block;
      background-color: black;
      position: absolute;
      right: 0;
      overflow: scroll;

      .alert-danger {
        color: red;
      }

      .redir-todash-but{
        text-align: center;
        margin-left:3rem;
        height:30px;
        line-height: 20px;
        font-size:20px;
        justify-content: center;

      border-radius: 28px;
      border: 1px solid;
      font-weight: 500;
      cursor: pointer;
      text-decoration: none;
      font-family: Wigrum;
      color: white;
    background-color: #6269ff;
    border-color: rgb(98, 105, 255);
    padding: 8px 10px 25px 10px;
    box-shadow: -2px 2px 10px 0 rgba(98, 105, 255, 0.5)
      }

      .profile-form {
        pointer-events: none;
        $buttonHighlight: #999;
        display: flex;
        flex-direction: column;
        background: black;
        margin: 3rem;

        img {
          width: 30%;
        }

        input {
          display: inline-block;
          border: none;
          border-bottom: 1.5px solid #6665ad;
          font-size: 14px;
          display: block;
          width: 90%;
          height: 40px;
          outline: 0;
          background-color: transparent;
          color: rgba(255, 255, 255, 0.7);
          z-index: 1;
        }

        input:disabled,
        button:disabled {
          cursor: no-drop;
        }

        .input-form {
          display: flex;
          flex-direction: column;
          width: 100%;

          .input-row {
            display: flex;
            flex-direction: row;
            width: 100%;
          }

          .input-div {
            pointer-events: all;
            width: 50%;
            margin: 2% 0;
            color: rgba(255, 255, 255, 0.6);
            float: left;

            label {
              font-size: 12px;
              margin-bottom: 10px;
            }
            input {
              width: 90%;
            }
          }

          .btn-profile {
            float: left;
            pointer-events: all;
            font-size: 14px;
            color: #fff;
            line-height: 1.2;
            text-transform: uppercase;
            justify-content: center;
            align-items: center;
            margin-top: 15px;
            width: 140px;
            height: 50px;
            background-color: #1fadbf;
            cursor: pointer;
            border: none;
            border-radius: 25px;
            outline: 0;
            transition: all 0.4s;

            background-image: url("../static/images/arrowRight.svg");
            background-repeat: no-repeat;
            background-position: 80% 45%;
            background-size: 13px;
            text-align: left;
            padding-left: 5%;
            margin-right: 5%;
            margin-bottom: 40px;
          }

          .btn-profile:hover {
            letter-spacing: 0.7px;
            background-color: #1eedf7;
          }
        }

        h1 {
          font-size: 60px;
          color: #fff;
          line-height: 1.18;
          margin: 4% 0;
        }

        .container-profile {
          display: flex;
          flex-direction: row;
          max-height: 300px;

          .hidden {
            display: none;
          }

          .container-profile-image {
            margin-right: 10px;

            .profile-image {
              max-width: 100%;
              min-width: 300px;
              height: 100%;
            }
          }

          .fsp-header {
            display: none;
          }

          .fsp-picker,
          .fsp-drop-area,
          .fsp-modal {
            background-color: transparent;
          }

          .fsp-modal {
            border: none;
          }

          .fsp-drop-area-container {
            padding: 30px 0;
          }

          .fsp-content--transformer {
            border: 1px solid;
          }
        }

        .file {
          pointer-events: all;
          width: 50%;
          margin: 2% 0;
          color: rgba(255, 255, 255, 0.6);
          float: left;

          input {
            width: 50%;
            float: left;
          }
          img {
            width: 35%;
            margin: 0 15px;
          }
        }
      }
    }
  }
}
