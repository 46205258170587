.people-chord-tool{
  position:absolute;
  z-index: 1001;
  pointer-events:none;
  background-color:white;
  border-radius:6px;
  opacity:0.8;
}


.react-popup-component{
  background-color: white;
  max-width: 200px;
  text-align: center;
  opacity:0.8;
}
