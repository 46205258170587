.change-xy-container{
  margin-top: 10px;

 .change-xy-title{
   color:black;
   text-decoration: underline;
  }

  .change-y-svg{
    margin:0 !important;
    margin-left:-15px !important;

    .tick line{
      opacity:1;
    }
    path.domain{
      stroke:black;
    }
  }
  .selection {
    fill:white !important;
    fill-opacity:1 !important;
  }
  .overlay{
    fill:#c1bfbf !important;
    stroke-width: 1px;
    stroke: #676565;
    opacity:0;
  }

  .change-move-bars{
    text-align: left;
    font-weight: lighter;
    border: 1px solid #6f6f6f;
    padding: 3px;
    border-radius: 12px;
    opacity:0.9;
    margin-top: 5px;
  }

.change-x-svg{
   margin:0 !important;
  .x-change-axis-g{
    .tick line{
      opacity:1;
    }
  }

}
.max-min-y{
  vertical-align:top;


  font-weight: bold;
}
.x-value{
   padding-top:5px;
}
}


.x-bar-brush{
  pointer-events: none;
}

.y-bar-brush{
  pointer-events: none;
}

.y-axis-label-svg{
  margin-top:0 !important;
  margin-left:0 !important;
}
.x-axis-label-svg{
  margin-top:0 !important;
  margin-left:0 !important;
  font-weight: bold;
}

rect.selection {
  opacity:0;
}

.y-change-axis-g .domain{
  opacity:0;
}

.y-change-axis-g .tick line{
  opacity:0 !important;
}

.x-change-axis-g .tick line{
  opacity:0 !important;
}
