.KPIDash-series-pg{
  width: 100%;
  height:calc(100vh - 40px);

  display:flex;
  flex-direction: column;
  .KPIDash-series-title{
    flex:1;
    color:red;
    text-align:center;
    display:flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
  }
  .KPIDash-series-chart-legend-cont{
    flex:23;
    min-width: 300px;

    display:flex;
    .KPIDash-SChart{
      flex:11;

    }
    .KPIDash-legend-options-cont{
       flex:1;
       margin-top:40px;
       min-width: 130px;
    }
  }
  .KPI-dash-series-toggles{
    .KPI-dash-series-toggle{
      height:35px;
      color:rgb(175,175,175);
      line-height: 45px;
      span{
        position: relative;
        bottom:3px;
      }

    }
  }
}

.kpi-series-popup{
  display:block;
  position: absolute;
  width:200px;
  height:45px;
  background: rgba(255,255,255,0.9);
  border: 2px solid rgba(150,150,150,0.9);
  border-radius: 15px;
  z-index: 40000;
  font-size: 20px;
  line-height: 25px;
  padding:10px;
  text-transform: capitalize;
}
