.KPI-filter-header{
  width:100%;
  height:15px;

  .KPI-filter-close{
    font-size: 30px;
    line-height: 10px;
    display:inline-block;
    float:right;
    cursor: pointer;
  }
}
