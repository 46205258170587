@import "../containers/variables";

body.invert {
  background: rgba(255, 255, 255, 1);
}

#dataviz-menu {
  * {
    box-sizing: border-box;
  }
  .svgStage {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vh;
  }
  #menuV1 {
    user-select: none;
    overflow: hidden;
    padding: 0 40px;
    /* flex child properties */
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    /* end flex child properties */

    /* flex parent properties */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    align-content: stretch;
    /* end flex parent properties */

    .backgroundRed {
      background-image: linear-gradient(150deg, rgba(61, 44, 41, 0.1), rgba(122, 109, 82, 0.3));
      border-color: rgb(217, 68, 38);
    }

    .backgroundYellow {
      background-image: linear-gradient(150deg, rgba(61, 60, 41, 0.1), rgba(105, 122, 82, 0.3));
      border-color: rgb(217, 202, 38);
    }

    .backgroundGreenLight {
      background-image: linear-gradient(150deg, rgba(48, 61, 41, 0.1), rgba(82, 122, 88, 0.3));
      border-color: rgb(98, 217, 38);
    }

    .backgroundGreen {
      background-image: linear-gradient(150deg, rgba(41, 61, 49, 0.1), rgba(82, 122, 119, 0.3));
      border-color: rgb(38, 217, 113);
    }

    .backgroundBlueLight {
      background-image: linear-gradient(150deg, rgba(41, 58, 61, 0.1), rgba(82, 95, 122, 0.3));
      border-color: rgb(38, 187, 217);
    }

    .backgroundBlue {
      background-image: linear-gradient(150deg, rgba(41, 43, 61, 0.1), rgba(99, 82, 122, 0.3));
      border-color: rgb(38, 53, 217);
    }
    .backgroundOrange{
      background-image: linear-gradient(150deg, rgba(54, 41, 10, 0.1), rgba(115, 65, 10, 0.25));
      border-color: rgb(255, 185, 45);
    }
    .backgroundPurple {
      background-image: linear-gradient(150deg, rgba(54, 41, 61, 0.1), rgba(122, 82, 116, 0.3));
      border-color: rgb(157, 38, 217);
    }

    .titleRed {
      color: rgb(255, 85, 51);
    }

    .titleYellow {
      color: rgb(255, 238, 51);
    }

    .titleGreenLight {
      color: rgb(119, 255, 51);
    }

    .titleGreen {
      color: rgb(51, 255, 136);
    }

    .titleBlueLight {
      color: rgb(51, 221, 255);
    }

    .titleBlue {
      color: rgb(51, 68, 255);
    }

    .titleOrange {
      color: rgb(255, 185, 45);
    }
    .titlePurple {
      color: rgb(187, 51, 255);
    }

    .buttonRed {
      background-color: rgba(204, 76, 51, 0.9);
    }

    .buttonYellow {
      background-color: rgba(204, 191, 51, 0.9);
    }

    .buttonGreenLight {
      background-color: rgba(102, 204, 51, 0.9);
    }

    .buttonGreen {
      background-color: rgba(51, 204, 115, 0.9);
    }

    .buttonBlueLight {
      background-color: rgba(51, 179, 204, 0.9);
    }

    .buttonOrange {
      background-color: rgba(255, 185, 45, 0.9);
    }

    .buttonBlue {
      background-color: rgba(51, 64, 204, 0.9);
    }

    .buttonPurple {
      background-color: rgba(153, 51, 204, 0.9);
    }

    .subtitleRed {
      color: rgb(191, 85, 64);
    }

    .subtitleYellow {
      color: rgb(191, 181, 64)
    }

    .subtitleGreenLight {
      color: rgb(106, 191, 64);
    }

    .subtitleGreen {
      color: rgb(64, 191, 117);
    }

    .subtitleBlueLight {
      color: rgb(64, 170, 191);
    }

    .subtitleBlue {
      color: rgb(64, 74, 191);
    }

    .subtitleOrange {
      color: rgb(255, 185, 45);
    }

    .subtitlePurple {
      color: rgb(149, 64, 191);
    }

    .breakdownBackgroundRed {
      background: linear-gradient(150deg, rgba(191, 85, 64, 0.9), rgba(115, 51, 38, 0.9));
    }

    .breakdownBackgroundYellow {
      background: linear-gradient(150deg, rgba(191, 181, 64, 0.9), rgba(115, 108, 38, 0.9));
    }

    .breakdownBackgroundGreenLight {
      background: linear-gradient(150deg, rgba(106, 191, 64, 0.9), rgba(64, 115, 38, 0.9));
    }

    .breakdownBackgroundGreen {
      background: linear-gradient(150deg, rgba(64, 191, 117, 0.9), rgba(38, 115, 70, 0.9));
    }

    .breakdownBackgroundBlueLight {
      background: linear-gradient(150deg, rgba(64, 170, 191, 0.9), rgba(38, 102, 115, 0.9));
    }

    .breakdownBackgroundBlue {
      background: linear-gradient(150deg, rgba(64, 74, 191, 0.9), rgba(38, 45, 115, 0.9));
    }

    .breakdownBackgroundPurple {
      background: linear-gradient(150deg, rgba(149, 64, 191, 0.9), rgba(89, 38, 115, 0.9));
    }
    .breakdownBackgroundOrange {
      background-image: linear-gradient(150deg,rgba(191, 165, 45, 0.9), rgba(115, 65, 10, 0.9));

    }
  }
  #menuV1 > div:nth-of-type(1),
  #menuV1 > div:last-of-type {
    flex-basis: 100%;
    flex-grow: 1;
    flex-shrink: 1;
  }

  #menuV1 > div:nth-last-child(2){
    flex-basis: 100%;
    flex-grow: 1;
    flex-shrink: 1;
  }

  #menuV1 > div:nth-of-type(1){
    .stat{
      font-size: 72px;
    }
  }


  .statWrapMult {
    width: 100%;
    display: inline-flex;
  }

  .statDelta {
    font-size: 16px;
    font-weight: 400;
  }

  .statAltDelta {
    font-size: 16px;
    font-weight: 400;
  }

  .statAltName {
    font-size: 16px;
    font-weight: 400;
    color: #999;
  }

  .statDelta.positive {
    color: rgba(0, 255, 0, 0.7);
  }
  .statDelta.negative {
    color: rgba(255, 255, 255, 0.8);
  }

  .statAltDelta.positive {
    color: rgba(0, 255, 0, 0.7);
  }
  .statAltDelta.negative {
    color: rgba(255, 255, 255, 0.8);
  }


  .tooltip{
      $tt_bg_opacity: .9;
      $tt_bgcolor: rgba(64,64,64,$tt_bg_opacity);
      $tt_height: 14px;
      opacity: 0;
      white-space: nowrap;
      position: relative;
      width: auto;
      z-index: 99999;
      font-size: 11px;
      line-height: $tt_height;
      height: $tt_height + 2px;
      vertical-align: middle;
      bottom: 100%;
      background: $tt_bgcolor;
      border-radius: $tt_height/2;
      pointer-events: none;
      text-transform: capitalize;
      transition: all .5s;
      &::after{
        display: block;
        content: "\25BC";
        box-sizing: border-box;
        font-size: 10px;
        width: 100%;
        line-height: 1;
        position: absolute;
        text-align: center;
        bottom: -10px;
        left: 0;
        color: $tt_bgcolor;
      }
      &.show {
        top: calc(-100% + 6px);
        opacity: 1;
      }
  }

  .tier1 {
    color: #999;
    font-weight: 100;
    font-size: 32px;
    margin: 10px;
    white-space: nowrap;
    word-wrap: normal;
    /* flex child properties */
    // align-self: flex-start;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 1px;
    /* end flex child properties */
    /* flex parent properties */
    display: flex;
    flex-direction: column;
    /* end flex parent properties */
    &:hover{
      .groupName{
        text-shadow: 0px 0px 5px currentColor;
      }
      .tier2wrap{
        box-shadow: 0px 0px 30px currentColor;
      }
    }
    .groupName{
      transition: all .5s;
      cursor: default;
    }
  }
  .invert {
    .tier1 {
      font-weight: 300;
    }
    .stat {
      color: rgba(0, 0, 0, 1);
      font-weight: 200;
    }
    .tier2wrap {
      background: none;
      border: 1px solid rgba(0, 0, 0, 0.2);
    }
    .breakdown {
      background: hsla(196, 80%, 90%, 0.9);
      &::before {
        color: hsla(196, 80%, 90%, 0.9);
      }
      &::after {
        color: hsla(196, 80%, 90%, 0.9);
      }
    }
  }
  .tier2wrap {
    /* flex parent properties */
    align-content: stretch;
    align-items: stretch;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    /* end flex parent properties */
    /* flex child properties */
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 100%;
    /* end flex child properties */
    white-space: normal;
    word-wrap: break-word;
    margin-top: 10px;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.2);
    padding: 10px 0px;
    border-width:2px 0 1px 0 ;
    border-style: solid;
    transition: all .5s;
    box-shadow: none;
  }
  .breakdownStatName {
    text-transform: capitalize;
  }
  #menuV1 > div:nth-of-type(1n + 2) > div {
    flex-direction: column;
    align-content: stretch;
    align-items: stretch;
  }
  #menuV1 > div:nth-last-child(2) > div {
    flex-direction: row;
    .statDiv {
      height:auto;
    }
  }
  #menuV1 > div:last-of-type > div {
    flex-direction: row;
    .statDiv {
      height:auto;
    }
  }

  .tier2 {
    flex-grow: 1;
    flex-shrink: 1;
    font-size: 20px;
    color: #999;
    text-align: center;
    padding: 0px 10px;
    background: none;
    border: none;
    outline: none;
    position: relative;
    text-decoration: none;
    transition: all 0.5s;
    cursor: default;
    margin-bottom: 10px;
    &:hover{
      .statName{
        span{
          border-bottom: 1px solid currentColor;
          background-color: rgba(0,0,0,.2)
        }
        &:hover span{
          background-color: rgba(0,0,0,.6)
        }
      }

    }

    .dimmed {
      opacity: 0.3;
    }
  }
  #menuV1 > div .tier2 {
    flex-basis: 100%;
  }
  #menuV1 > div:nth-of-type(1n + 2) .tier2 {
    flex-basis: auto;
  }

  .statDiv {
    transition: all 0.5s;
    position: relative;
  }

  .icons {
    justify-content: center;
    margin-top: 10px;
   // z-index: 10000;
    position: relative;
  }

  .iconBt {
    cursor: pointer;
    position: relative;
    text-align: center;
    overflow: visible;
    color: $white;
    font-size: 16px;
    font-weight: 400;
    vertical-align: middle;
    text-decoration: none;
    transition: all 0.5s;
    $radius: 12px;
    height: $radius * 2;
    width: $radius * 2;
    border-radius: $radius;
    z-index: 9;
    background-position: center center;
    background-size: contain;
    margin-left: 3px;
    &:hover {
      color: rgba(255, 255, 255, 1);
      height: $radius * 2.5;
      width: $radius * 2.5;
      border-radius: $radius * 1.25;
      font-weight: 400;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, .3);
    }
    &.icon-splitchord {
      background-image: url("/img/icon_splitchord.png");
    }
    &.icon-timerange {
      background-image: url("/img/icon_timerange.png");
    }
    &.icon-map {
      background-image: url("/img/icon_map.png");
    }
    &.icon-bubbles {
      background-image: url("/img/icon_bubbles.png");
    }
    &.icon-info {
      background-image: url("/img/icon_info.png");
    }
    &.icon-series {
      background-image: url("/img/icon_bars.png");
    }
    &.icon-churn {
      background-image: url("/img/icon-churn.png");
      background-repeat:no-repeat;

    }
    &.icon-area{
      background-image: url('/img/icon_area.png');
    }
    &.icon-undercontract{
      background-image: url('/img/icon_undercontract.png');
    }
    &.icon-relations{
      background-image: url('/img/icon_relations.png');
    }
    &.icon-copy{
      background-image: url('/img/icon_copy.png');
    }
    &.treemap {
      background-image: url("/img/treemap.png");
    }
    &:not(.active){
      pointer-events: none;
    }
  }

  .iconBt:hover .tooltip {
    opacity: 1;
  }


  .infoBt {
    cursor: pointer;
    position: absolute;
    left:50%;
    top:20px;
    text-align: center;
    opacity: 0;
    overflow: visible;
    color: white;
    font-size: 16px;
    font-weight: 400;
    vertical-align: middle;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.5s;
    $radius: 12px;
    height: $radius*2;
    width: $radius*2;
    border-radius: $radius;
    transform: translate(-50%, -50%);
    z-index: 9;
    background-position: center center;
    background-size: contain;
    &:hover {
      color: rgba(255, 255, 255, 1);
      height: $radius*2.5;
      width: $radius*2.5;
      border-radius: $radius*1.25;
      font-weight: 400;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, .3);
    }
    &.icon-map{
      background-image: url('/img/icon_map.png');
    }
    &.icon-bubbles{
      background-image: url('/img/icon_bubbles.png');
    }
    &.icon-info{
      background-image: url('/img/icon_info.png');
    }
    &.icon-series{
      background-image: url('/img/icon_bars.png');
    }
    &.icon-area{
      background-image: url('/img/icon_area.png');
    }
    &.icon-undercontract{
      background-image: url('/img/icon_undercontract.png');
    }
    &.icon-relations{
      background-image: url('/img/icon_relations.png');
    }
    &:not(.active){
      pointer-events: none;
    }
  }


  .infoName {
    text-align: center;
    font-size: 20px;
    color: rgba(255, 255, 255, 0.8);
    padding: 12px 20px 10px;
    transition: all 0.5s;
    font-weight: 300;
  }

  .infoDiv {
    z-index: 10;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    padding: 1rem;
    font-size: 14px;
    text-align: left;
    border-radius: 10px;
    font-weight: 400;
    transition: all 0.5s;
    .infoDefinition {
      font-weight: 300;
      margin: 10px 0 20px;
      color: rgba(255, 255, 255, 0.6);
      line-height: 140%;
    }
    .infoBt {
      float: left;
      text-align: center;
      opacity: 0;
      transition: all 0.25s;
      color: #ddd;
      font-size: 16px;
      font-weight: 200;
      text-decoration: underline;
      padding: 0.25rem;
      cursor: pointer;
      margin: 0px 10px 10px;
    }
    .hide {
      opacity: 0;
      pointer-events: none;
    }
    .display {
      display: initial;
      transition: all 0.5s;
      pointer-events: none;
    }
  }

  .infoCloseBt {
    text-align: center;
    transition: all 0.25s;
    min-height: 20px;
    max-height: 20px;
    min-width: 20px;
    max-width: 20px;
    overflow: visible;
    margin: 4px 0px 0px 4px;
    color: #ddd;
    font-size: 16px;
    font-weight: 400;
    display: inline-block;
    vertical-align: middle;
    border-radius: 100%;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      color: rgba(255, 255, 255, 1);
      transform: scale(1.33);
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
    }
  }

  .icon-info-close {
    text-align: center;
    position: absolute;
    top: 0px;
    right: 4px;
  }

  .icon-glossary {
    color: rgba(82, 31, 20, 0.9);
    text-align: center;
    border-radius: 10px;
    padding: 2px 10px;
    box-shadow: 2px 2px 3px rgba(0,0,0,.3);
    background-color: rgba(255,255,255,.3)
  }

  .icon-glossary:hover {
    cursor: pointer;
  }

  .tier2wrap .hide {
    opacity: 0;
    pointer-events: none;
  }

  .infoDiv .hide {
    opacity: 0;
    pointer-events: none;
  }

  .tier2wrap .nodisplay {
    display: none;
    transition: all 0.5s;
    pointer-events: none;
  }

  .infoDiv .display {
    display: initial;
    transition: all 0.5s;
    pointer-events: none;
  }

  .infoCloseBt:hover {
    color: rgba(255, 255, 255, 1);
    transform: scale(1.33);
    text-shadow: 1px 1px 2px rgba(0,0,0,.4)
  }

  .statName {
    display: inline-block;
    transition: all 0.5s;
    font-weight: 300;
    clear: right;
    margin-top: 5px;
    border-top: 1px solid transparent;

    &:not(.dimmed):hover{
      $secondStory: 40px;
      padding-top: $secondStory;
      margin-top:calc(5px - #{$secondStory}) !important;
      color: white;
    }
    &.dimmed {
      background: none;
    }
    span{
      border-bottom: 1px solid transparent;
      border-radius: 20px;
      padding: 0px 20px;
      transition: all .25s;
      display: inline-block;
      text-align: center;
    }
  }
  .odometer.dimmed {
    opacity: 0.1;
  }
  .odometer * {
    text-align: center !important;
  }
  .odometer{
    &:hover {
      cursor: pointer;
    }
  }
  .stat {
    color: rgba(255, 255, 255, 1);
    font-size: 48px;
    display: inline-block;
    padding: 0 10px;
    box-sizing: border-box;
    width: 100%;
    position: relative;
    font-weight: 100;
  }

  .statAlt {
    color: rgba(255, 255, 255, 1);
    top: -10px;
    font-size: 48px;
    display: inline-block;
    padding: 0 10px;
    box-sizing: border-box;
    width: 100%;
    position: relative;
    font-weight: 100;
    cursor: pointer;
  }

  .estimated {
    position: relative;
    &::before {
      opacity: 0.7;
      content: "*";
      font-size: 180%;
      position: absolute;
      top: -5px;
      left: 0;
      display: block;
    }
  }
  .estimatedLegend {
    position: absolute;
    top: 15px;
    right: 40px;
    display: none;
    color: $white;
    opacity: 0.7;
    padding-left: 15px;
    font-size: 120%;
    &.visible {
      display: inline-block;
    }
    .estimated {
      width: 10px;
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      &::before {
        opacity: 1;
        top: 0;
        font-size: 210%;
      }
    }
  }
  .stat > .prefix {
    font-size: 24px;
    opacity: 0.8;
  }

  .stat > .suffix {
    font-size: 24px;
  }

  .statAlt > .prefixAlt,
  .statAlt > .suffixAlt {
    font-size: 24px;
    opacity: 0.8;
  }

  .breakdown {
    background: hsla($globalHue, 40%, 30%, 0.9);
    font-size: 16px;
    width: 100%;
    position: absolute;
    top: calc(100% + 10px);
    left: 0;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    z-index: 10;
    display: block;
    opacity: 0;
    transition: opacity 0.25s;
    .clippy{
      &:hover{
        cursor:pointer;
      }
    }
  }
  .invert .breakdown {
    background: hsla($globalHue, 80%, 90%, 0.9);
  }
  #menuV1 > div:last-of-type .breakdown {
    top: auto;
    bottom: 100%;
  }
  .stat:hover .breakdown.active {
    opacity: 1.0;
  }
  .breakdownStat {
    /* flex parent properties */
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;
    align-content: stretch;
    /* end flex parent properties */
    margin: 5px;

  }
  .arcLabel {
    fill: rgba(255, 255, 255, 1);
  }
}
