.KPI-live-table-title-cont{
  padding-left: 50px;
  color:#FA1478;
  width:100%;
  display: block;
  min-height:50px;
  cursor: pointer;


  .KPI-live-table-title-title{
    line-height: 50px;
    font-size:16px;
  }

  .KPI-live-table-title-circle{
    height:10px;
    width:10px;
    border-radius: 25px;
    background-color: #FA1478;
    display: inline-block;
    margin-left: 15px;
  }

}
