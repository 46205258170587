.KPIDash-chart-legend{
   color: rgb(180,180,180);
   text-transform: capitalize;
   line-height: 20px;
    padding:10px;

    border-radius: 5px;
    text-align: right;
    margin-right: 15px;
    min-height:214px;
}

.KPI-legend-item{
  height:20px;
  width:20px;
  display:inline-block;
  border-radius: 20px;
  position: relative;
  left:5px;
  top:3px;
}
