@import "../../containers/variables";

#timeseries-area {
  color: #96999b;
  height: 94vh;
  overflow-x: hidden;
  margin-top: 0px;

  .axis path,
  .axis line {
    fill: none;
    stroke: #96999b;
    stroke-width: 1px;
    shape-rendering: crispEdges;
  }

  .tick {
    fill: #96999b;
  }

  g.x.axis > g.tick {
    cursor: pointer;
  }

  #titleHeader {
    padding-top: 2px;
    padding-left: 20px;
  }

  #title {
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);

    #titleText{
      

    }
  }

  #controlsIndicator {
    margin-top: 30px;
  }

  #titleText {
    font-size: 24px;
  }

  button {
    position: absolute;
    right: 50px;
    top: 10px;
    z-index: 3;
    cursor: pointer;
  }
  .switch {
    position: relative;
    right: 0;
  }

  /* TEST STREAM STYLES */
  .axis path,
  .axis line {
    fill: none;
    stroke: none;
    shape-rendering: crispEdges;
  }

  .browser text {
    color: $white;
    text-anchor: end;
  }

  /* SLIDER */
  // #controls {
  //   position: absolute;
  //   top: 20px;
  //   left: 20px;
  //   color: white;
  //   z-index: 2;
  // }
  line {
    &.track {
      stroke: none;
    }
    &.track-inset {
      stroke: rgba(255, 255, 255, 0.3);
    }
  }
  .parameter-value path {
    stroke: none;
  }

  /* LEGEND */
  .legend {
    position: absolute;
    top: 0;
    right: 15px;
  }
  .legend-entry {
    display: flex;
    align-items: center;

    div {
      height: 20px;
      width: 20px;
      display: inline-block;
      margin-right: 5px;
    }
    p {
      color: $white;
      display: inline;
      font-size: smaller;
    }
  }

  .timeseriesTooltip {
    position: absolute;
    z-index: 20;
    visibility: hidden;
    top: 20px;
    right: 55px;
    color: #fff;
  }

  /* VERTICAL LINE */
  .vertical {
    position: absolute;
    z-index: 19;
    width: 1px;
    height: 95%;
    top: 10px;
    bottom: 30px;
    left: 0px;
    background: #fff;
    opacity: 0.5;
  }
}
