.invert {
  background: rgba(255, 255, 255, 1);
  font-weight: 300;
  .xLabel,
  .xLabelNum {
    fill: rgba(0, 0, 0, 1);
  }
  .xLabelDot {
    fill: rgba(0, 0, 0, 0.7);
  }
  .yLabel {
    fill: rgba(0, 0, 0, 1);
  }
  .totalCol {
    border: 1px solid hsla($globalHue, 50%, 10%, .1);
  }
  .connectingLineTotal {
    stroke: rgba(0, 0, 0, 0.4);
  }
  .connectingLineVert {
    stroke: rgba(0, 0, 0, 0.2);
  }
  circle {
    &.marketCap {
      stroke: rgba(0, 0, 0, 0.7);
    }
    &.marketCapProjected {
      stroke-dasharray: 2 6;
      stroke-linecap: round;
    }
  }
}

.totalCol {
  position: absolute;
  border: 1px solid hsla($globalHue, 50%, 10%, .1);
  z-index: 0;
}

svg{
  position: relative;
  z-index: 1;
}
.xLabel {
  fill: rgba(255, 255, 255, 1);
  text-anchor: middle;
  font-size: 21px;
  font-weight: 300;
}
.xLabelNum {
  fill: rgba(255, 255, 255, 0.7);
  text-anchor: middle;
  font-size: 16px;
  font-weight: 300;
}
.xLabelDot,
.totalDot {
  fill: rgba(255, 255, 255, 0.7);
}
.yLabel {
  fill: rgba(255, 255, 255, 1);
  font-size: 24px;
  font-weight: 300;
  text-anchor: middle;
}
circle {
  &.marketCap {
    stroke: rgba(255, 255, 255, 0.7);
    stroke-width: 1;
    fill: none;
  }
  &.marketCapProjected {
    stroke-dasharray: 2 6;
    stroke-linecap: round;
  }
  &.activeHouseholds {
    fill: hsla($globalHue, 100%, 70%, 1);
  }
  &.activeHouseholdsProjected {
    fill: url(#diagonalHatch);
  }
}

.diagonalHatch {
  stroke:hsla(196, 100%, 70%, 1);
  stroke-width:1.5;
}

.connectingLineVert {
  stroke: rgba(255, 255, 255, 0.2);
  stroke-width: 1;
  stroke-dasharray: 2;
  stroke-linecap: round;
  fill: none;
}
.connectingLineTotal {
  stroke: rgba(255, 255, 255, 0.4);
  stroke-width: 1;
  stroke-linecap: round;
  fill: none;
}
.totalHeader {
  font-size: 20px;
  font-weight: 500;
}
.totalDenominator {
  font-size: 20px;
  font-weight: 500;
}
.metaTrigger {
  position: absolute;
  &:hover .metaTriggerTooltip{
    opacity: 1;
  }
  z-index: 10;
}
.metaTriggerTooltip{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  background: rgba(0,0,0,.7);
  border-radius: 5px;
  padding: 2px 5px;
  // font-size: 12px;
  opacity: 0;
  transition: opacity .5s;
  div{
    white-space: nowrap;
  }
  span{
    font-weight: 600;
  }
}

.total_container {
  overflow: hidden;
  position: fixed;
  height: calc(100% - 40px);
  display: block;
  right: 0;
  top: 40px;
  bottom: -10px;
  z-index: 1000;
  background: rgba(0,0,0,.7);

  // filter: blur(10px);
}

#floater {
  height: 100%;
}

.main-container {
  overflow-x: scroll;
}
